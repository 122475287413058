import { TimeBlock } from "hooks/interventions/models/intervention";

export enum ServiceReportStep {
	/** Installer fills in all the details. https://projects.invisionapp.com/d/main?origin=v7#/console/21252844/448467382/preview */
	Initial = "intial",
	/** Signature is required by the customer. https://projects.invisionapp.com/d/main?origin=v7#/console/21252844/448467383/preview */
	Signature = "signature",
	/** Installer can provide extra comment for the internal team. https://projects.invisionapp.com/d/main?origin=v7#/console/21252844/448467384/preview */
	Close = "close",
	/** Service report has been completed and intervention is closed. */
	Closed = "closed",
}

export interface Mileage {
	start: number;
	end: number;
}

export interface RemainingWork {
	description: string;
	manPower: number;
}

export interface Articles {
	toOrder: string;
	retoured: string;
}

export interface Picture {
	id: string;
	name: string;
	url: string;
}

export interface ServiceReport {
	id: string;
	step: ServiceReportStep;
	customerEmail: string;
	workingHours: TimeBlock[];
	mileage: Mileage;
	executedWork: string;
	remainingWork: RemainingWork;
	articles: Articles;
	remarksForCustomer: string;
	remarkFromCustomer: string;
	remarkForInternalTeam: string;
	// images: string[];
	images: Picture[];
}
