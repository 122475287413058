import { DeepPartial, IOMap, ToInternal } from "@aptus/frontend-core";
import { FileDTO } from "models/schema";
import { ApolloError } from "@apollo/client";
import { Attachment } from "./models/attachment";
import { UploadAttachmentMutationFnDTO } from "./models/uploadAttachment";
import { UploadAttachmentMutation } from "./useAttachmentsUseCases";

interface Mapper {
	toAttachment: ToInternal<DeepPartial<FileDTO>, Attachment>;
	toUploadMutation: ToInternal<UploadAttachmentMutationFnDTO, UploadAttachmentMutation>
}

export class AttachmentMapper implements Mapper {
	public toAttachment: Mapper["toAttachment"] = (attachment) => ({
		id: attachment.id || "",
		name: attachment.fileName || "",
		url: attachment.url || "",
	});

	public toUploadMutation: Mapper["toUploadMutation"] = (mutation) => {
		const map: IOMap<UploadAttachmentMutationFnDTO, UploadAttachmentMutation> = {
			toInput: (input) => ({
				variables: {
					file: input,
				},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new ApolloError({ graphQLErrors: output.errors });
				}
				return output.data?.createOneFile ? this.toAttachment(output.data.createOneFile) : undefined;
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
