import { PageComponent } from "@aptus/frontend-core";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "components/link/link";
import { HTMLValidationSchema } from "utils/validate";
import { SetFinishWorkDayInput } from "hooks/serviceReports/models/serviceReportInput";
import dayjs from "dayjs";
import { useProfile, useRoutes, useTravelRoute } from "domains";
import { useGetProfile } from "hooks/profile/useGetProfile";
import i18next from "i18next";
import { useGetTravelRoutes } from "pages/mileagePage/models/getTravelRoutes";
import { FinishDayForm } from "./components/finishDayForm";

export const setMileageEndDay: HTMLValidationSchema<SetFinishWorkDayInput> = {
	mileage: { required: true, min: 0 },
};

export const FinishWorkDayPage: PageComponent = () => {
	const { routes } = useRoutes();

	const day = new Date();
	const profileResult = useProfile(useGetProfile());

	const { travelRoute } = useTravelRoute(useGetTravelRoutes({
		variables: {
			filter: {
				startingHour: {
					gte: dayjs(day).startOf("day"),
					lte: dayjs(day).endOf("day"),
				},
				employee: { containsSome: [profileResult.profile?.user?.id ?? ""] },
			},
			paging: {
				limit: 1,
				offset: 0,
			},
		},
	}));

	const { save } = useTravelRoute();

	return (
		<>
			<IconButton
				to={routes.interventions.index}
				component={Link}
				aria-label={i18next.t("goBack")}
				sx={{
					zIndex: 10, position: "absolute", left: "1rem", top: "1rem",
				}}
			>
				<ArrowBackIcon />
			</IconButton>

			<FinishDayForm
				schema={setMileageEndDay}
				saveTravelRoute={save}
				travelRoute={travelRoute}
			/>
		</>
	);
};
