import {
	ImageList, ImageListItem, ImageListItemBar, IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { UIComponent } from "@aptus/frontend-core";

interface Props {
	images: string[];
	deleteImage?: (index: number) => void;
}

export const Gallery: UIComponent<Props> = ({ images, deleteImage }) => (
	<ImageList variant="woven">
		{images.map((src, index) => (
			<ImageListItem key={src}>
				<img src={src} alt="alt" />
				{deleteImage ? (
					<ImageListItemBar
						position="top"
						sx={{ backgroundColor: "transparent", padding: "1rem" }}
						actionIcon={(
							<IconButton onClick={() => deleteImage(index)} sx={{ backgroundColor: "white" }}>
								<DeleteIcon />
							</IconButton>
						)}
					/>
				) : null}
			</ImageListItem>
		))}
	</ImageList>
);
