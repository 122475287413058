/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTravelRouteVariablesDTO = Types.Exact<{
	id: Types.Scalars['String'];
	data: Types.TravelRouteUpdateInputDTO;
}>;

export type UpdateTravelRouteDTO = any;

export const UpdateTravelRouteDocumentDTO = gql`
	mutation updateTravelRoute($id: String!, $data: TravelRouteUpdateInput!) {
		updateOneTravelRoute(id: $id, data: $data) {
			id
		}
	}
`;

export type UpdateTravelRouteMutationFnDTO = Apollo.MutationFunction<UpdateTravelRouteDTO, UpdateTravelRouteVariablesDTO>;

/**
 * __useUpdateTravelRoute__
 *
 * To run a mutation, you first call `useUpdateTravelRoute` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelRoute` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTravelRoute, { data, loading, error }] = useUpdateTravelRoute({
 *   variables: {
 *      id: // value for 'id'
 *      data: TravelRouteUpdateInputDTO
 *   },
 * });
 */
export function useUpdateTravelRoute(baseOptions?: Apollo.MutationHookOptions<UpdateTravelRouteDTO, UpdateTravelRouteVariablesDTO>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<UpdateTravelRouteDTO, UpdateTravelRouteVariablesDTO>(UpdateTravelRouteDocumentDTO, options);
}
export type UpdateTravelRouteHookResult = ReturnType<typeof useUpdateTravelRoute>;
export type UpdateTravelRouteMutationResultDTO = Apollo.MutationResult<UpdateTravelRouteDTO>;
export type UpdateTravelRouteMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateTravelRouteDTO, UpdateTravelRouteVariablesDTO>;

export interface PossibleTypesResultData {
	possibleTypes: {
	  [key: string]: string[]
	}
  }
const result: PossibleTypesResultData = {
	"possibleTypes": {}
};
export default result;