import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import dayjs from "dayjs";
import { event } from "event";
import jwtDecode from "jwt-decode";
import { createUploadLink } from "apollo-upload-client";
import { LocalStorageWrapper, CachePersistor } from "apollo3-cache-persist";

const cache = new InMemoryCache({
	typePolicies: {
		InterventionArticle: {
			keyFields: ["reference"],
			fields: {
				isAvailable: {
					read: (isAvailable = false) => isAvailable,
				},
			},
		},
		Intervention: {
			fields: {
				articles: {
					read: (articles = []) => articles,
				},
			},
		},
	},
});

/**
 * This function is a promise and therefor might not resolve before the initial queries are executed. According to the
 * docs this function should be awaited to mitigate this but this causes some top-level await issues. Not persisting the
 * initial queries should not be problem for this app though as we only use this persistance for the client-side
 * material list fields and the client-side time tracker. Neither will ever be used on initial render.
 */

/* Replaced with cache persistor instance so we can call persistor.purge() and other methods for more precise controls. */
export const persistor = new CachePersistor({
	cache,
	storage: new LocalStorageWrapper(window.localStorage),
});

const uploadLink = createUploadLink({
	uri: process.env.REACT_APP_API_URL || window?.appEnvironment?.API_URL,
	fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("accessToken");
	const SECOND = 1000;

	if (token) {
		const { exp } = jwtDecode<{ exp: number }>(token);
		const isExpired: boolean = dayjs(exp * SECOND).isBefore(dayjs());
		if (isExpired) event.emit("tokenExpired");
	}

	return {
		headers: {
			...headers,
			...token ? { authorization: `Bearer ${token}` } : {},
		},
	};
});

export const client = new ApolloClient({ cache, link: from([authLink, uploadLink]) });
