import { API, Mutation, UseCases } from "@aptus/frontend-core";
import { event } from "event";
import { Article, Intervention, TimeBlock } from "./models/intervention";
import { ToggleArticleAvailabilityInput } from "./models/interventionInput";

export type InterventionsAPI = API<Intervention[]>;

export type StartInterventionMutation = Mutation<Intervention["id"], TimeBlock[]>;
export type StopInterventionMutation = Mutation<Intervention["id"], TimeBlock[]>;
export type ToggleArticleAvailabilityMutation = Mutation<ToggleArticleAvailabilityInput, Article>;
export type ToggleAllArticlesAvailabilityMutation = Mutation<Intervention["id"], void>;

interface Props {
	api: InterventionsAPI;
	defaultIntervention: Intervention;
	startMutation: StartInterventionMutation;
	stopMutation: StopInterventionMutation;
	toggleArticleAvailabilityMutation: ToggleArticleAvailabilityMutation;
	toggleAllArticlesAvailabilityMutation: ToggleAllArticlesAvailabilityMutation;
}

interface Result {
	interventions: Intervention[];
	intervention: Intervention;
	isLoading: boolean;
	error?: Error;
	start: (id: Intervention["id"]) => Promise<TimeBlock[]>;
	pause: (id: Intervention["id"]) => Promise<TimeBlock[]>;
	stop: (id: Intervention["id"]) => Promise<TimeBlock[]>;
	toggleArticleAvailability: (input: ToggleArticleAvailabilityInput) => Promise<Article | undefined>;
	toggleAllArticlesAvailability: (id: Intervention["id"]) => Promise<void>;
}

export interface InterventionEvents {
	interventionStopped: (intervention: { id: Intervention["id"], workingHours: TimeBlock[] }) => void;
	interventionInfo: (id: string) => void;
	completeIntervention: (id: string) => void;
	endDay: () => void;
	interventions: () => void;
}

export const useInterventionsUseCases: UseCases<Props, Result> = ({
	api, defaultIntervention, startMutation, stopMutation, toggleArticleAvailabilityMutation, toggleAllArticlesAvailabilityMutation,
}) => {
	const start: Result["start"] = async (id) => {
		const workingHours = await startMutation(id);
		return workingHours || [];
	};

	const pause: Result["pause"] = async (id) => {
		const workingHours = await stopMutation(id);
		return workingHours || [];
	};

	const stop: Result["stop"] = async (id) => {
		const workingHours = await pause(id);
		event.emit("interventionInfo", id);
		return workingHours || [];
	};

	const toggleArticleAvailability: Result["toggleArticleAvailability"] = (input) => (
		toggleArticleAvailabilityMutation(input)
	);

	const toggleAllArticlesAvailability: Result["toggleAllArticlesAvailability"] = (id) => (
		toggleAllArticlesAvailabilityMutation(id)
	);

	return {
		...api,
		interventions: api.data,
		intervention: api.data?.[0] ?? defaultIntervention,
		start,
		pause,
		stop,
		toggleArticleAvailability,
		toggleAllArticlesAvailability,
	};
};
