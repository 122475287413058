import { API, Mutation, UseCases } from "@aptus/frontend-core";
import { useState } from "react";
import { event } from "event";
import { HTMLValidationSchema, isValid } from "utils/validate";
import i18next from "i18next";
import { Attachment } from "hooks/attachments/models/attachment";
import { dataURLToFile } from "utils/fileUtils";
// import { useEvent } from "utils/useEvent";
import { Picture, ServiceReport } from "./models/serviceReport";
import {
	CloseServiceReportInput, CreateServiceReportInput, SaveServiceReportInput, SetMileageInput, SignServiceReportInput, UpdateServiceReportInput,
} from "./models/serviceReportInput";

export type ServiceReportsAPI = API<ServiceReport[]>;

interface SignMutationInput extends Omit<SignServiceReportInput, "signature"> {
	signature?: Attachment;
}

interface UpdateServiceReportMutationInput extends Omit<UpdateServiceReportInput, "images"> {
	attachments: Attachment[];
}

export type CreateServiceReportMutation = Mutation<CreateServiceReportInput, ServiceReport>;
export type UpdateServiceReportMutation = Mutation<UpdateServiceReportMutationInput, ServiceReport>;
export type SignServiceReportMutation = Mutation<SignMutationInput, ServiceReport>;
export type CloseServiceReportMutation = Mutation<CloseServiceReportInput, ServiceReport>;

interface Props {
	api: ServiceReportsAPI;
	defaultServiceReport: ServiceReport;
	createMutation: CreateServiceReportMutation;
	updateMutation: UpdateServiceReportMutation;
	signMutation: SignServiceReportMutation;
	closeMutation: CloseServiceReportMutation;
	uploadAttachment: (file: File) => Promise<Attachment | undefined>;
	uploadManyAttachments: (files: FileList | File[]) => Promise<Attachment[] | undefined>;
}

interface Result {
	serviceReport: ServiceReport;
	isLoading: boolean;
	error?: Error;
	saveSchema: HTMLValidationSchema<SaveServiceReportInput>;
	signSchema: HTMLValidationSchema<SignServiceReportInput>;
	closeSchema: HTMLValidationSchema<CloseServiceReportInput>;
	setMileageSchema: HTMLValidationSchema<SetMileageInput>;
	save: (input: SaveServiceReportInput) => Promise<void>;
	sign: (input: SignServiceReportInput, isAutoSave?: boolean) => Promise<void>;
	close: (input: CloseServiceReportInput) => Promise<void>;
	setMileageAndStartDate: (mileage: SetMileageInput["mileage"], travelRouteId: SetMileageInput["travelRouteId"]) => void;
}

export interface ServiceReportEvents {
	serviceReportClosed: () => void;
	mileageAndStartDateSet: () => void;
	attachmentFailed: (reason: string) => void;
}

export const useServiceReportsUseCases: UseCases<Props, Result> = ({
	api, defaultServiceReport, createMutation, updateMutation, signMutation, closeMutation, uploadAttachment, uploadManyAttachments,
}) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(api.isLoading);
	const isUpdate = (input: SaveServiceReportInput): input is UpdateServiceReportInput => !!input.id;

	const saveSchema: Result["saveSchema"] = {
		interventionId: { required: true },
	};

	const signSchema: Result["signSchema"] = {
		id: { required: true },
		email: { required: true },
		signature: { required: true },
	};

	const closeSchema: Result["closeSchema"] = {
		id: { required: true },
	};

	const setMileageSchema: Result["setMileageSchema"] = {
		mileage: { required: true, min: 0 },
	};

	const createAttachments = async (images: FileList | File[] | Picture[]): Promise<Attachment[] | undefined> => {
		if (Array.isArray(images)) {
			return [...images].reduce(async (values: any, current: any) => {
				if (current.id) {
					return values.then((value: any) => {
						value.push({ id: current.id, name: "", url: "" });
						return value;
					});
				}
				const attachment = await uploadAttachment(current);
				return values.then((value: any) => {
					value.push(attachment);
					return value;
				});
			}, Promise.resolve([]));
		}
		return uploadManyAttachments(images ?? []);
	};

	const save: Result["save"] = async (input) => {
		try {
			setIsLoading(true);
			if (isUpdate(input)) {
				const attachments = await createAttachments(input.images);
				await updateMutation({ ...input, commentForCustomer: input.commentForCustomer ?? "", attachments: attachments ?? [] });
				event.emit("mutationSucceeded", i18next.t("domain.serviceReport.saved"));
				if (input.endMileage) localStorage.setItem("mileage", input.endMileage.toString());
			} else {
				await createMutation({ ...input, startMileage: Number(localStorage.getItem("mileage")) || 0 });
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const sign: Result["sign"] = async (input, isAutoSave) => {
		try {
			setIsLoading(true);
			if (isAutoSave) {
				await signMutation({ id: input.id, email: input.email, remarks: input.remarks });
				event.emit("mutationSucceeded", i18next.t("domain.serviceReport.saved"));
				return;
			}
			if (isValid(input, signSchema) && !isAutoSave) {
				const file = await dataURLToFile(input.signature, "signature.png");
				const signature = await uploadAttachment(file);
				if (signature) await signMutation({ ...input, signature });
				event.emit("mutationSucceeded", i18next.t("domain.serviceReport.signed"));
				return;
			}
			if (!isValid(input, signSchema) && !isAutoSave) {
				event.emit("mutationFailed", Error(i18next.t("domain.serviceReport.signatureError")));
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const close: Result["close"] = async (input) => {
		try {
			setIsLoading(true);
			if (isValid(input, closeSchema)) {
				await closeMutation(input);
				event.emit("mutationSucceeded", i18next.t("domain.serviceReport.saved"));
				event.emit("serviceReportClosed");
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const setMileageAndStartDate: Result["setMileageAndStartDate"] = (mileage, travelRouteId) => {
		localStorage.setItem("mileage", mileage.toString());
		localStorage.setItem("travelRouteId", travelRouteId);
		localStorage.setItem("startTime", new Date().toISOString());
		event.emit("mileageAndStartDateSet");
	};

	return {
		serviceReport: api.data[0] || defaultServiceReport,
		isLoading,
		error: api.error,
		saveSchema,
		signSchema,
		closeSchema,
		setMileageSchema,
		save,
		sign,
		close,
		setMileageAndStartDate,
	};
};
