/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateServiceReportVariablesDTO = Types.Exact<{
  interventionId: Types.Scalars['String'];
  licensePlate: Types.Scalars['String'];
  workingHours: Array<Types.TimeBlockInputDTO> | Types.TimeBlockInputDTO;
  startMileage: Types.Scalars['Int'];
}>;


export type CreateServiceReportDTO = { __typename?: 'Mutation', createOneServiceReport: { __typename?: 'ServiceReport', id: string } };


export const CreateServiceReportDocumentDTO = gql`
    mutation createServiceReport($interventionId: String!, $licensePlate: String!, $workingHours: [TimeBlockInput!]!, $startMileage: Int!) {
  createOneServiceReport(
    data: {intervention: $interventionId, vehicleIdentifier: $licensePlate, workingHours: $workingHours, startKm: $startMileage}
  ) {
    id
  }
}
    `;
export type CreateServiceReportMutationFnDTO = Apollo.MutationFunction<CreateServiceReportDTO, CreateServiceReportVariablesDTO>;

/**
 * __useCreateServiceReport__
 *
 * To run a mutation, you first call `useCreateServiceReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceReport, { data, loading, error }] = useCreateServiceReport({
 *   variables: {
 *      interventionId: // value for 'interventionId'
 *      licensePlate: // value for 'licensePlate'
 *      workingHours: // value for 'workingHours'
 *      startMileage: // value for 'startMileage'
 *   },
 * });
 */
export function useCreateServiceReport(baseOptions?: Apollo.MutationHookOptions<CreateServiceReportDTO, CreateServiceReportVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceReportDTO, CreateServiceReportVariablesDTO>(CreateServiceReportDocumentDTO, options);
      }
export type CreateServiceReportHookResult = ReturnType<typeof useCreateServiceReport>;
export type CreateServiceReportMutationResultDTO = Apollo.MutationResult<CreateServiceReportDTO>;
export type CreateServiceReportMutationOptionsDTO = Apollo.BaseMutationOptions<CreateServiceReportDTO, CreateServiceReportVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    