import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Button, TextField } from "@mui/material";
import { Footer } from "components/footer";
import i18next from "i18next";
import { FullScreenForm } from "style/fullScreenForm";
import { LicensePlateFormLayoutProps, LicensePlateFormProps, useLicensePlateForm } from "./useLicensePlateForm";

const Layout: UILayout<LicensePlateFormLayoutProps> = ({
	submit, register, errors, isValid,
}) => (
	<form onSubmit={submit} style={{ width: "100%", height: "100%" }}>
		<FullScreenForm>
			<main>
				<TextField
					{...register("licensePlate")}
					label={i18next.t("licensePlateForm.licensePlate")}
					error={!!errors.licensePlate}
					helperText={errors.licensePlate?.message}
				/>
			</main>
			<Footer>
				<Button
					type="submit"
					disabled={!isValid}
					fullWidth
				>
					{i18next.t("licensePlateForm.submit")}
				</Button>
			</Footer>
		</FullScreenForm>
	</form>
);

export const LicensePlateForm: UIComponent<LicensePlateFormProps> = (props) => (
	<Layout {...props} {...useLicensePlateForm(props)} />
);
