/* eslint-disable */
import * as Types from "../../../models/schema.d";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

const defaultOptions = {} as const;
export type GetTravelRoutesVariablesDTO = Types.Exact<{
	filter: Types.InputMaybe<Types.TravelRouteFilterDTO>;
	paging: Types.InputMaybe<Types.PagingDTO>;
}>;

export type GetTravelRoutesDTO = any;

export const GetTravelRoutesDocumentDTO = gql`
	query getTravelRoutes($filter: TravelRouteFilter, $paging: Paging) {
		travelRoutes(filter: $filter, paging: $paging) {
			id
			startingHour
			workingHour {
				start
				startKm
				end
				endKm
			}
		}
	}
`;

export function useGetTravelRoutes(baseOptions: Apollo.QueryHookOptions<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>(GetTravelRoutesDocumentDTO, options);
}

export type GetTravelRoutesHookResult = ReturnType<typeof useGetTravelRoutes>;
export type GetTravelRoutesQueryResultDTO = Apollo.QueryResult<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>;

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[],
	}
}
const result: PossibleTypesResultData = {
	"possibleTypes": {},
};

export default result;
