import { UILogic } from "@aptus/frontend-core";
import { TimeBlock } from "hooks/interventions/models/intervention";
import { SaveServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import {
	ChangeEvent,
	FormEvent,
} from "react";
import {
	Control, FieldErrors, FieldPath, useFieldArray, useForm, UseFormRegisterReturn,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";
import Resizer from "react-image-file-resizer";
import { event } from "event";
import { Picture } from "hooks/serviceReports/models/serviceReport";

type Input = Omit<SaveServiceReportInput, "id" | "interventionId">;

interface TimeBlockField extends TimeBlock {
	id: string;
}

interface Props {
	initialValues: Input;
	schema: HTMLValidationSchema<Input>;
	submit: (input: Input) => void;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<Input>) => UseFormRegisterReturn;
	deleteImage: (index: number) => void;
	control: Control<Input>;
	errors: FieldErrors<Input>;
	fields: TimeBlockField[];
	onAddImage: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	images: Picture[];
}

export type InitialServiceReportFormProps = Props;
export type InitialServiceReportFormLayoutProps = Result;

const resizeFile = (file: File) => new Promise((resolve) => {
	Resizer.imageFileResizer(
		file,
		1080,
		1080,
		"JPEG",
		100,
		0,
		(newFile) => { resolve(newFile); },
		"file",
	);
});

const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8 MB

const instanceOfPicture = (object: File | Picture): object is Picture => "id" in object;

export const useInitialServiceReportForm: UILogic<Props, Result> = ({ initialValues, schema, submit }) => {
	const {
		handleSubmit, register, formState, control, watch, getValues,
	} = useForm<Input>({ mode: "onChange", defaultValues: initialValues });
	const { fields: timeBlockFields } = useFieldArray<Input>({ control, name: "workingHours" });
	const { append, remove } = useFieldArray<Input>({ control, name: "images" });

	const toFileURL = (file: File | Picture): Picture => {
		if (instanceOfPicture(file)) {
			return file as Picture;
		}
		return ({ url: URL.createObjectURL(file as File), name: "", id: "" });
	};

	const deleteImage: Result["deleteImage"] = (index) => {
		remove(index);
		submit(getValues());
	};

	const onAddImage: Result["onAddImage"] = async (e) => {
		const files = Array.from(e.currentTarget.files ?? []);

		const values = await Promise.all(files.map(resizeFile)) as File[];

		const validImages = values.filter((v) => {
			if (v.size > MAX_FILE_SIZE) {
				event.emit("attachmentFailed", "attachmentTooBig");
				return false;
			}

			return true;
		});

		append(validImages);
	};

	const images = (watch("images") || []) as File[];

	return {
		submit: handleSubmit(submit),
		register: (field) => register(field, (toFormHookSchema(schema) as any)[field]),
		control,
		errors: formState.errors,
		fields: timeBlockFields as unknown as TimeBlockField[],
		images: images.map(toFileURL),
		deleteImage,
		onAddImage,
	};
};
