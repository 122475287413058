import { CloseServiceReportInput, SaveServiceReportInput, SignServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import { ServiceReport, ServiceReportStep } from "hooks/serviceReports/models/serviceReport";
import { HTMLValidationSchema } from "utils/validate";
import { UIComponent } from "@aptus/frontend-core";
import dayjs from "dayjs";
import { InitialServiceReportForm } from "./initialServiceReportForm/initialServiceReportForm";
import { SignServiceReportForm } from "./signServiceReportForm/signServiceReportForm";
import { CloseServiceReportForm } from "./closeServiceReportForm/closeServiceReportForm";

interface Props {
	step: ServiceReportStep;
	serviceReport: ServiceReport;
	saveSchema: HTMLValidationSchema<SaveServiceReportInput>;
	signSchema: HTMLValidationSchema<SignServiceReportInput>;
	closeSchema: HTMLValidationSchema<CloseServiceReportInput>;
	date: Date;
	save: (input: Omit<SaveServiceReportInput | SignServiceReportInput, "id" | "interventionId">) => Promise<void>;
	sign: (input: Omit<SignServiceReportInput, "id">, isAutoSave?: boolean) => Promise<void>;
	close: (input: Omit<CloseServiceReportInput, "id">) => Promise<void>;
}

export const ServiceReportForm: UIComponent<Props> = ({
	step, serviceReport, saveSchema, signSchema, closeSchema, date, save, sign, close,
}) => {
	switch (step) {
		case ServiceReportStep.Initial:
			return (
				<InitialServiceReportForm
					schema={saveSchema}
					submit={save}
					initialValues={{
						workingHours: serviceReport.workingHours,
						articlesRetoured: serviceReport.articles.retoured,
						articlesToOrder: serviceReport.articles.toOrder,
						endMileage: serviceReport.mileage.end,
						executedWork: serviceReport.executedWork,
						remainingWork: serviceReport.remainingWork.description,
						remainingWorkManPower: serviceReport.remainingWork.manPower,
						commentForCustomer: serviceReport.remarksForCustomer,
						images: serviceReport.images,
					}}
				/>
			);
		case ServiceReportStep.Signature:
			return (
				<SignServiceReportForm
					schema={signSchema}
					submit={(input, isAutoSave) => sign(input, isAutoSave)}
					initialValues={{
						email: serviceReport.customerEmail,
						remarks: serviceReport.remarkFromCustomer,
						signature: "",
					}}
				/>
			);
		case ServiceReportStep.Close:
		case ServiceReportStep.Closed:
			return (
				<CloseServiceReportForm
					schema={closeSchema}
					submit={close}
					initialValues={{
						comment: serviceReport.remarkForInternalTeam,
						/* These are needed to refetch the appropriate queries for automatic refetch */
						startOfDay: dayjs(date).startOf("day").toISOString(),
						endOfDay: dayjs(date).endOf("day").toISOString(),
					}}
				/>
			);
		default:
			return null;
	}
};
