import { UIComponent } from "@aptus/frontend-core";
import { Box, Stack } from "@mui/material";
import { Typography } from "components/typography";
import { Intervention } from "hooks/interventions/models/intervention";
import i18next from "i18next";
import { WithLoadingState } from "models/withLoadingState";

type Props = Pick<Intervention, "customer" | "interventionNumber" | "warehouse" | "reference">;

export const InterventionDetails: UIComponent<WithLoadingState<Props>> = ({
	isLoading, customer, interventionNumber, warehouse, reference,
}) => (
	<Box mx={2}>
		<Typography variant="h1" isLoading={isLoading}>{customer?.name}</Typography>
		<br />
		<Typography variant="h2">{i18next.t("interventionDetails.title")}</Typography>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{interventionNumber}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.interventionNumber")}</Typography>
		</Stack>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{warehouse}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.warehouse")}</Typography>
		</Stack>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{reference}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.reference")}</Typography>
		</Stack>
	</Box>
);
