import { PageComponent } from "@aptus/frontend-core";
import {
	Stack, Tab, Tabs, Typography, Button,
} from "@mui/material";
import {
	useAuthentication,
	useInterventions,
	useProfile,
	useRoutes,
} from "domains";
import { event } from "event";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import { SkeletonLoader } from "components/skeletonLoader";
import { useGetProfile } from "hooks/profile/useGetProfile";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Link } from "components/link/link";
import { useInterventionFilters } from "hooks/interventions/useInterventionFilters";
import { useGetInterventions } from "./models/getInterventions";
import { InterventionCard } from "./components/interventionCard/interventionCard";
import { InterventionsPageHeader } from "./components/interventionsPageHeader/interventionsPageHeader";
import { useGetNextIntervention } from "./models/getNextIntervention";
import { SearchBar } from "./components/searchBar";
import { SearchInterventionsVariablesDTO, useSearchInterventions } from "./models/searchInterventions";
import { DayPicker } from "./components/dayPicker/dayPicker";
import { TravelRoute } from "./components/travelRoute/travelRoute";
import { InterventionsList } from "./components/interventionsList/interventionsList";
import { InterventionsFooter } from "./components/footer";

interface Props {
	isCompleted?: boolean;
}

const setupFilter = (search: string, installer: string) => {
	const filter: SearchInterventionsVariablesDTO["filter"] = {
		OR: [
			{
				customerAddress: {
					OR: [
						{ name: { contains: search } },
						{ street: { contains: search } },
						{ postalcode: { contains: search } },
						{ city: { contains: search } },
					],
				},
			},
			{ dealerName: { contains: search } },
			{ orderNumber: { contains: search } },
		],
		travelRoute: {
			employee: { containsSome: [installer] },
		},
	};
	if (!Number.isNaN(parseInt(search, 10))) {
		(filter?.OR || []).push({ referenceInterventionId: { equals: parseInt(search, 10) } });
	}

	return filter;
};

export const InterventionsPage: PageComponent<Props> = ({ isCompleted }) => {
	const { profile, logout } = useAuthentication();
	const { routes, setQuery, getQuery } = useRoutes();

	const profileResult = useProfile(useGetProfile());

	const { day, goToNextDay, goToPreviousDay } = useInterventionFilters();

	const { intervention, isLoading: isNextInterventionLoading, interventions: nextInterventions } = useInterventions(useGetNextIntervention({
		variables: {
			startOfDay: dayjs(day).startOf("day"),
			endOfDay: dayjs(day).endOf("day"),
			installer: profileResult.profile?.user?.id ?? "",
		},
	}));
	const result = useGetInterventions({
		variables: {
			isCompleted: !!isCompleted,
			startOfDay: dayjs(day).startOf("day"),
			endOfDay: dayjs(day).endOf("day"),
			installer: profileResult.profile?.user?.id ?? "",
		},
	});

	const searchResult = useSearchInterventions({
		variables: {
			filter: setupFilter(getQuery("search"), profileResult.profile?.user?.id ?? ""),
		},
		skip: !getQuery("search"),
	});
	const { interventions, isLoading: isInterventionsLoading } = useInterventions(getQuery("search") ? searchResult : result);

	return (
		<Stack direction="column" spacing={2}>
			<Helmet>
				{getQuery("search") && <meta name="theme-color" content="white" />}
			</Helmet>
			<InterventionsPageHeader isVisible={!getQuery("search")}>
				<Stack direction="row" justifyContent="space-between">
					<DayPicker
						currentDay={day}
						goToNextDay={goToNextDay}
						goToPreviousDay={goToPreviousDay}
					/>
					<Button onClick={logout} variant="text">{i18next.t("logout")}</Button>
				</Stack>
				<TravelRoute
					isLoading={isNextInterventionLoading}
					isEmpty={nextInterventions.length === 0}
					address={intervention.customer.address}
					installers={intervention.installers}
					changeVehicleUrl={routes.licensePlate.scanner}
					interventionTime={intervention.startingHour}
					travelRouteId={intervention.travelRouteId}
					orderNumber={intervention.orderNumber}
					licensePlate={profile.licensePlate}
					sx={{ my: 1 }}
				/>
				<Stack direction="row" justifyContent="space-between">
					<Tabs value={isCompleted ? "closed" : "open"}>
						<Tab label={i18next.t("open")} value="open" to={routes.interventions.open} component={NavLink} />
						<Tab label={i18next.t("closed")} value="closed" to={routes.interventions.closed} component={NavLink} />
					</Tabs>
					<Link to={routes.materials.index} style={{ display: "flex", alignItems: "center" }}>
						<Stack direction="row" spacing={1}>
							<InventoryIcon />
							<Typography>{i18next.t("materiallist")}</Typography>
						</Stack>
					</Link>
				</Stack>
			</InterventionsPageHeader>

			<SearchBar
				search={getQuery("search")}
				onSearch={(search) => setQuery({ search })}
			/>

			<SkeletonLoader
				isLoading={isInterventionsLoading}
				isEmpty={interventions.length === 0}
				amount={getQuery("search") ? 1 : 3}
				skeleton={<InterventionCard isLoading />}
			>
				<InterventionsList interventions={interventions} showDates={!!getQuery("search")} />
			</SkeletonLoader>
			<InterventionsFooter>
				<Button style={{ backgroundColor: "white", color: "#4A9FF8" }} onClick={() => event.emit("endDay")}>
					<LocalShippingOutlinedIcon />
					<Typography style={{ marginLeft: "10px" }}>{i18next.t("arriveWarehouse")}</Typography>
				</Button>
			</InterventionsFooter>
		</Stack>
	);
};
