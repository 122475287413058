import { IOMap, ToInternal } from "@aptus/frontend-core";
import { LoginMutationFnDTO } from "./models/login";
import { RefreshTokenMutationFnDTO } from "./models/refreshToken";
import { LoginMutation, RefreshTokenMutation } from "./useAuthenticationUseCases";

interface Mapper {
	toLoginMutation: ToInternal<LoginMutationFnDTO, LoginMutation>;
	toRefreshTokenMutation: ToInternal<RefreshTokenMutationFnDTO, RefreshTokenMutation>;
}

export class AuthenticationMapper implements Mapper {
	public toLoginMutation: Mapper["toLoginMutation"] = (mutation) => {
		const map: IOMap<LoginMutationFnDTO, LoginMutation> = {
			toInput: (input) => ({ variables: input }),
			toOutput: (output) => output.data?.login,
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toRefreshTokenMutation: Mapper["toRefreshTokenMutation"] = (mutation) => {
		const map: IOMap<RefreshTokenMutationFnDTO, RefreshTokenMutation> = {
			toInput: (input) => ({ variables: input }),
			toOutput: (output) => output.data?.refreshToken,
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
