import { PageComponent } from "@aptus/frontend-core";
import { Button, IconButton } from "@mui/material";
import { Link } from "components/link/link";
import { useAuthentication, useRoutes } from "domains";
import i18next from "i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "components/typography";
import { Footer } from "components/footer";
import { Scanner } from "./components/scanner";
import { LicensePlateScannerPageStyle } from "./licensePlateScannerPageStyle";

export const LicensePlateScannerPage: PageComponent = () => {
	const { setLicensePlate, logout } = useAuthentication();
	const { routes } = useRoutes();

	return (
		<LicensePlateScannerPageStyle>
			<IconButton
				onClick={logout}
				aria-label={i18next.t("logout")}
				sx={{
					zIndex: 10, position: "absolute", left: "1rem", top: "1rem",
				}}
			>
				<ArrowBackIcon />
			</IconButton>

			<main>
				<Typography
					variant="body1"
					sx={{
						width: "100%", textAlign: "center", marginTop: "0.5rem", zIndex: 10,
					}}
				>
					{i18next.t("scanTheBarcode")}
				</Typography>

				<Scanner
					onScan={setLicensePlate}
				/>
			</main>

			<Footer>
				<Button
					component={Link}
					to={routes.licensePlate.form}
					fullWidth
				>
					{i18next.t("fillInManually")}
				</Button>
			</Footer>
		</LicensePlateScannerPageStyle>
	);
};
