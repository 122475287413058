import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Box, Button, Typography } from "@mui/material";
import i18next from "i18next";
import { ChangeEvent } from "react";
import SignatureCanvas from "react-signature-canvas";
import { fileToDataURL } from "utils/fileUtils";
import { SignatureInputLayoutProps, SignatureInputProps, useSignatureInput } from "./useSignatureInput";

const Layout: UILayout<SignatureInputLayoutProps> = ({
	innerRef, canvasRef, parentRef, setValue, value, width, error, helperText, label, isTestEnvironment,
}) => {
	const onEnd = (): void => {
		if (canvasRef.current) setValue(canvasRef.current.toDataURL());
	};

	const onUpload = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
		const file = e.target.files?.[0];
		if (file) setValue(await fileToDataURL(file));
	};

	const clearSignature = () => {
		canvasRef.current?.clear();
		setValue("");
	};

	return (
		<Box style={{ position: "relative" }} ref={parentRef} role="textbox">
			<label htmlFor="signature">{label}</label>
			<Typography variant="body1">{error}</Typography>
			{isTestEnvironment
				? <input type="file" accept="image/*" onChange={onUpload} name="signature" id="signature" />
				: <SignatureCanvas ref={canvasRef} onEnd={onEnd} canvasProps={{ width, height: "300px" }} />}
			<input hidden ref={innerRef} value={value} onChange={() => { }} />
			<Typography variant="body1">{helperText}</Typography>
			<Button style={{ position: "absolute", top: 0, right: 0 }} onClick={clearSignature}>{i18next.t("signature.clear")}</Button>
		</Box>
	);
};

export const SignatureInput: UIComponent<SignatureInputProps> = (props) => (
	<Layout {...props} {...useSignatureInput(props)} />
);
