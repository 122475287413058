import { isNotEmpty, Mutation, UseCases } from "@aptus/frontend-core";
import { event } from "event";
import { Attachment } from "./models/attachment";

export type UploadAttachmentMutation = Mutation<File, Attachment>;

interface Props {
	uploadMutation: UploadAttachmentMutation;
}

interface Result {
	upload: (file: File) => Promise<Attachment | undefined>;
	uploadMany: (files: File[] | FileList) => Promise<Attachment[] | undefined>;
}

export const useAttachmentsUseCases: UseCases<Props, Result> = ({ uploadMutation }) => {
	const upload: Result["upload"] = async (file) => {
		try {
			return await uploadMutation(file);
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		}

		return undefined;
	};

	const uploadMany: Result["uploadMany"] = async (files) => {
		const attachments = await Promise.all(Array.from(files).map(upload));

		return attachments.filter(isNotEmpty);
	};

	return {
		upload,
		uploadMany,
	};
};
