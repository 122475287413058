import { UIComponent } from "@aptus/frontend-core";
import { Box } from "@mui/material";

export const Footer: UIComponent = ({ children }) => (
	<footer style={{ gridArea: "footer" }}>
		<Box sx={{ mb: 2 }}>
			{children}
		</Box>
	</footer>
);
