import { UILogic } from "@aptus/frontend-core";
import {
	MutableRefObject, useEffect, useRef, useState,
} from "react";

interface Common {
	isVisible?: boolean;
}

interface Result {
	parent: MutableRefObject<HTMLDivElement | undefined>;
	parentHeight: number;
}

export type InterventionsPageHeaderProps = Common;
export type InterventionsPageHeaderLayoutProps = Result & Common;

export const useInterventionsPageHeader: UILogic<{}, Result> = () => {
	const [parentHeight, setParentHeight] = useState<Result["parentHeight"]>(0);
	const parent = useRef<HTMLDivElement>();

	useEffect(() => {
		if (parent.current) {
			setParentHeight(parent.current.clientHeight);
		}
	}, [parent.current?.clientHeight]);

	return {
		parent,
		parentHeight,
	};
};
