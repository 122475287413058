import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { theme } from "theme";
import { InterventionCard } from "../interventionCard/interventionCard";
import { InterventionsListLayoutProps, InterventionsListProps, useInterventionsList } from "./useInterventionsList";

const Layout: UILayout<InterventionsListLayoutProps> = ({ interventions, showCompletionStatus }) => (
	<div style={{ marginBottom: "85px" }}>
		{interventions.map((intervention) => (
			<div style={{ margin: "16px 0" }}>
				{intervention.title && (
					<Typography color={theme.palette.primary.main}>
						{dayjs(intervention.title).format("DD MMMM YYYY")}
					</Typography>
				)}
				<InterventionCard
					key={intervention.id}
					href={intervention.url}
					comment={intervention.comment}
					showCompletionStatus={showCompletionStatus && intervention.isClosed}
					{...intervention.customer}
				/>
			</div>
		))}
	</div>
);

export const InterventionsList: UIComponent<InterventionsListProps> = (props) => (
	<Layout {...props} {...useInterventionsList(props)} />
);
