/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterventionsArticlesVariablesDTO = Types.Exact<{
  startOfDay: Types.Scalars['DateTime'];
  endOfDay: Types.Scalars['DateTime'];
  isCompleted?: Types.InputMaybe<Types.Scalars['Boolean']>;
  installer: Types.InputMaybe<Types.Scalars["String"]>;
}>;


export type GetInterventionsArticlesDTO = { __typename?: 'Query', interventions: Array<{ __typename?: 'Intervention', id: string, customerAddress: { __typename?: 'InterventionCustomerAddress', name: string }, articles: Array<{ __typename?: 'InterventionArticle', reference: string, isAvailable?: boolean | null }> }> };


export const GetInterventionsArticlesDocumentDTO = gql`
    query getInterventionsArticles($startOfDay: DateTime!, $endOfDay: DateTime!, $isCompleted: Boolean, $installer: String) {
  interventions(
    filter: {completed: {equals: $isCompleted}, travelRoute: {startingHour: {gte: $startOfDay, lte: $endOfDay}, employee: { containsSome: [$installer] }}}
    sort: {field: "executionNumber", order: ASC}
  ) {
    id
    customerAddress {
      name
    }
    articles {
      reference
      isAvailable @client
    }
  }
}
    `;

/**
 * __useGetInterventionsArticles__
 *
 * To run a query within a React component, call `useGetInterventionsArticles` and pass it any options that fit your needs.
 * When your component renders, `useGetInterventionsArticles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterventionsArticles({
 *   variables: {
 *      startOfDay: // value for 'startOfDay'
 *      endOfDay: // value for 'endOfDay'
 *      isCompleted: // value for 'isCompleted'
 *   },
 * });
 */
export function useGetInterventionsArticles(baseOptions: Apollo.QueryHookOptions<GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO>(GetInterventionsArticlesDocumentDTO, options);
      }
export function useGetInterventionsArticlesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO>(GetInterventionsArticlesDocumentDTO, options);
        }
export type GetInterventionsArticlesHookResult = ReturnType<typeof useGetInterventionsArticles>;
export type GetInterventionsArticlesLazyQueryDTOHookResult = ReturnType<typeof useGetInterventionsArticlesLazyQueryDTO>;
export type GetInterventionsArticlesQueryResultDTO = Apollo.QueryResult<GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    