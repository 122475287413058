import dayjs from "dayjs";
import { UploadAttachmentDTO, UploadAttachmentVariablesDTO } from "hooks/attachments/models/uploadAttachment";
import { LoginDTO, LoginVariablesDTO } from "hooks/authentication/models/login";
import { RefreshTokenDTO, RefreshTokenVariablesDTO } from "hooks/authentication/models/refreshToken";
import { CloseServiceReportDTO, CloseServiceReportVariablesDTO } from "hooks/serviceReports/models/closeServiceReport";
import { CreateServiceReportDTO, CreateServiceReportVariablesDTO } from "hooks/serviceReports/models/createServiceReport";
import { SignServiceReportDTO, SignServiceReportVariablesDTO } from "hooks/serviceReports/models/signServiceReport";
import { UpdateServiceReportDTO, UpdateServiceReportVariablesDTO } from "hooks/serviceReports/models/updateServiceReport";
import { graphql, RequestHandler } from "msw";
import { GetInterventionDTO, GetInterventionVariablesDTO } from "pages/interventionPage/models/getIntervention";
import { GetInterventionsDTO, GetInterventionsVariablesDTO } from "pages/interventionsPage/models/getInterventions";
import { GetNextInterventionDTO, GetNextInterventionVariablesDTO } from "pages/interventionsPage/models/getNextIntervention";
import { SearchInterventionsDTO, SearchInterventionsVariablesDTO } from "pages/interventionsPage/models/searchInterventions";
import { GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO } from "pages/materialListDetailPage/models/getInterventionArticles";
import { GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO } from "pages/materialListPage/models/getInterventionsArticles";
import { db } from "./db";
import { empty } from "./dictionary";

/**
 * 'ctx.delay()' without a specific value imitates a realistic network delay.
 * Useful for manual testing, not so much for integration tests.
 */
const delay = process.env.NODE_ENV === "test" ? 0 : undefined;

/**
 * Here we define what API request returns in our mock.
 */
export const handlers: RequestHandler[] = [
	graphql.mutation<LoginDTO, LoginVariablesDTO>("login", (req, res, ctx) => {
		if (req.variables.password === "invalid password") {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Login failed" }]),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({
				login: {
					accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJOaWNvbGFzIiwicm9sZXMiOlsiUk9MRV9JTlNUQUxMRVIiLCJST0xFX1dFQlVTRVJTIl0sImlzcyI6Imh0dHBzOi8vYjJiLmRldHJlbW1lcmllLmJlL3VzZXJzZXJ2aWNlL2xvZ2luIiwiZXhwIjo5ODUzOTM3MzYyLCJ0b2tlblR5cGUiOiJhY2Nlc3MifQ.o8N7NOzao5Hh0jZFHQKTVvVAcUaw4oeAeIWVu-LOW8g",
					refreshToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJOaWNvbGFzIiwicm9sZXMiOlsiUk9MRV9JTlNUQUxMRVIiLCJST0xFX1dFQlVTRVJTIl0sImlzcyI6Imh0dHBzOi8vYjJiLmRldHJlbW1lcmllLmJlL3VzZXJzZXJ2aWNlL2xvZ2luIiwiZXhwIjo5ODUzOTM3MzYyLCJ0b2tlblR5cGUiOiJyZWZyZXNoIn0.8FHSqIed_QVfrOM9bHEDRGJJgUgpp-MbGfg0VEITxpI",
				},
			}),
		);
	}),

	graphql.mutation<RefreshTokenDTO, RefreshTokenVariablesDTO>("refreshToken", (req, res, ctx) => {
		if (req.variables.refreshToken === "expiredRefreshToken") {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Refresh token expired" }]),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({
				refreshToken: {
					accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJOaWNvbGFzIiwicm9sZXMiOlsiUk9MRV9JTlNUQUxMRVIiLCJST0xFX1dFQlVTRVJTIl0sImlzcyI6Imh0dHBzOi8vYjJiLmRldHJlbW1lcmllLmJlL3VzZXJzZXJ2aWNlL2xvZ2luIiwiZXhwIjo5ODUzOTM3MzYyLCJ0b2tlblR5cGUiOiJhY2Nlc3MifQ.o8N7NOzao5Hh0jZFHQKTVvVAcUaw4oeAeIWVu-LOW8g",
					refreshToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJOaWNvbGFzIiwicm9sZXMiOlsiUk9MRV9JTlNUQUxMRVIiLCJST0xFX1dFQlVTRVJTIl0sImlzcyI6Imh0dHBzOi8vYjJiLmRldHJlbW1lcmllLmJlL3VzZXJzZXJ2aWNlL2xvZ2luIiwiZXhwIjo5ODUzOTM3MzYyLCJ0b2tlblR5cGUiOiJyZWZyZXNoIn0.8FHSqIed_QVfrOM9bHEDRGJJgUgpp-MbGfg0VEITxpI",
				},
			}),
		);
	}),

	graphql.query<GetInterventionsDTO, GetInterventionsVariablesDTO>("getInterventions", (req, res, ctx) => {
		const interventions: any = db.intervention.findMany({
			where: {
				...req.variables.isCompleted !== undefined ? { completed: { equals: req.variables.isCompleted } } : {},
			},
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				interventions: interventions
					.sort((a: any, b: any) => new Date(a.interventionDate).getTime() - new Date(b.interventionDate).getTime())
					.filter(({ travelRoute }: any) => (
						dayjs(travelRoute.startingHour).isAfter(req.variables.startOfDay)
							&& dayjs(travelRoute.startingHour).isBefore(req.variables.endOfDay)
					)),
			}),
		);
	}),

	graphql.query<GetInterventionsArticlesDTO, GetInterventionsArticlesVariablesDTO>("getInterventionsArticles", (req, res, ctx) => {
		const interventions: any = db.intervention.findMany({
			where: {
				...req.variables.isCompleted !== undefined ? { completed: { equals: req.variables.isCompleted } } : {},
			},
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				interventions: interventions
					.sort((a: any, b: any) => new Date(a.interventionDate).getTime() - new Date(b.interventionDate).getTime())
					.filter(({ travelRoute }: any) => (
						dayjs(travelRoute.startingHour).isAfter(req.variables.startOfDay)
							&& dayjs(travelRoute.startingHour).isBefore(req.variables.endOfDay)
					)),
			}),
		);
	}),

	graphql.query<SearchInterventionsDTO, SearchInterventionsVariablesDTO>("searchInterventions", (req, res, ctx) => {
		const interventions: any = db.intervention.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				interventions: interventions
					.sort((a: any, b: any) => new Date(a.interventionDate).getTime() - new Date(b.interventionDate).getTime()),
				/* TODO: fix this search */
				// .filter(({ customerAddress }: any) => (
				// 	customerAddress.name.toString().toLowerCase().includes(req.variables.search?.toLowerCase())
				// 		|| customerAddress.street.toString().toLowerCase().includes(req.variables.search?.toLowerCase())
				// 		|| customerAddress.city.toString().toLowerCase().includes(req.variables.search?.toLowerCase())
				// 		|| customerAddress.postalcode.toString().toLowerCase().includes(req.variables.search?.toLowerCase())
				// )),
			}),
		);
	}),

	graphql.query<GetNextInterventionDTO, GetNextInterventionVariablesDTO>("getNextIntervention", (req, res, ctx) => {
		const interventions: any = db.intervention.findMany({
			where: { completed: { equals: false } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				interventions: interventions
					.filter(({ travelRoute }: any) => (
						dayjs(travelRoute.startingHour).isAfter(req.variables.startOfDay)
							&& dayjs(travelRoute.startingHour).isBefore(req.variables.endOfDay)
					))
					.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()),
			}),
		);
	}),

	graphql.query<GetInterventionDTO, GetInterventionVariablesDTO>("getIntervention", (req, res, ctx) => {
		const intervention: any = db.intervention.findFirst({ where: { id: { equals: req.variables.id } } });

		if (!intervention) {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Intervention not found" }]),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({ intervention }),
		);
	}),

	graphql.query<GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO>("getInterventionArticles", (req, res, ctx) => {
		const intervention: any = db.intervention.findFirst({ where: { id: { equals: req.variables.id } } });

		if (!intervention) {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Intervention not found" }]),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({ intervention }),
		);
	}),

	graphql.mutation<CreateServiceReportDTO, CreateServiceReportVariablesDTO>("createServiceReport", (req, res, ctx) => {
		const intervention = db.intervention.findFirst({ where: { id: { equals: req.variables.interventionId } } });

		if (!intervention) {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Intervention not found" }]),
			);
		}

		const serviceReport: any = db.serviceReport.create({
			...empty.serviceReport,
			intervention: undefined,
			vehicleIdentifier: req.variables.licensePlate,
			workingHours: req.variables.workingHours,
			startKm: req.variables.startMileage,
		});

		db.intervention.update({
			where: { id: { equals: req.variables.interventionId } },
			data: { completed: true, serviceReport },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				createOneServiceReport: serviceReport,
			}),
		);
	}),

	graphql.mutation<UpdateServiceReportDTO, UpdateServiceReportVariablesDTO>("updateServiceReport", (req, res, ctx) => {
		const remarkForCustomer = db.remark.create({
			message: req.variables.commentForCustomer?.message,
		});

		const serviceReport: any = db.serviceReport.update({
			where: { id: { equals: req.variables.id } },
			data: {
				workingHours: req.variables.workingHours,
				articlesRetoured: req.variables.articlesRetoured,
				articlesToOrder: req.variables.articlesToOrder,
				remainingWork: req.variables.remainingWork,
				remainingWorkManPower: req.variables.remainingWorkManPower,
				remarkForCustomer,
				executedWork: req.variables.executedWork,
				endKm: req.variables.endMileage,
				updatedOn: new Date().toISOString(),
				pictures: (Array.isArray(req.variables.pictures) ? req.variables.pictures : []).map((id) => db.file.findFirst({ where: { id: { equals: id } } })),
			},
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				updateOneServiceReport: serviceReport,
			}),
		);
	}),

	graphql.mutation<SignServiceReportDTO, SignServiceReportVariablesDTO>("signServiceReport", (req, res, ctx) => {
		// const remarksForInternal = db.remark.create({
		// 	message: req.variables.remarks?.message,
		// });

		const signature = db.file.findFirst({
			where: { id: { equals: req.variables.signature } },
		});

		if (!signature) {
			res(ctx.errors([{ message: "Signature not found" }]));
		}

		const serviceReport: any = db.serviceReport.update({
			where: { id: { equals: req.variables.id } },
			data: {
				// remarksForInternal: [remarksForInternal],
				signature,
			},
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				updateOneServiceReport: serviceReport,
			}),
		);
	}),

	graphql.mutation<CloseServiceReportDTO, CloseServiceReportVariablesDTO>("closeServiceReport", (req, res, ctx) => {
		const remarksForInternal = Array.isArray(req.variables.comment) && req.variables.comment.map((comment: any) => (
			db.remark.create({
				message: comment.message,
			})
		));

		const serviceReport: any = db.serviceReport.update({
			where: { id: { equals: req.variables.id } },
			data: {
				...remarksForInternal ? { remarksForInternal } : {},
				completed: true,
			},
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				updateOneServiceReport: serviceReport,
			}),
		);
	}),

	graphql.mutation<UploadAttachmentDTO, UploadAttachmentVariablesDTO>("uploadAttachment", (req, res, ctx) => {
		const file: any = db.file.create();

		return res(
			ctx.delay(delay),
			ctx.data({
				createOneFile: file,
			}),
		);
	}),
];
