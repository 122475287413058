import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { ProfileDTO, QueryDTO } from "models/schema";
import { Profile } from "./models/profile";

export type ProfileAPIDTO = ApolloAPI<QueryDTO, "profile">;

interface Mapper {
	toAPI: ToAPI<ProfileAPIDTO, any>;
	toProfile: ToInternal<DeepPartial<ProfileDTO>, Profile>;
}

export class ProfileMapper implements Mapper {
	public toProfile: Mapper["toProfile"] = (profile) => ({
		user: { id: profile.user?.id ?? "" },
	});

	public toAPI: Mapper["toAPI"] = (api) => ({
		...api,
		isLoading: api?.loading ?? false,
		data: api?.data?.profile ?? { user: { id: "" } },
	});
}
