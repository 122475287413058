export const dataURLToBlob = async (dataURL: string): Promise<Blob> => (
	fetch(dataURL).then((response) => response.blob())
);

export const blobToFile = (blob: Blob, fileName: string, type: string = "image/png"): File => (
	new File([blob], fileName, { type, lastModified: new Date().getTime() })
);

export const dataURLToFile = async (dataURL: string, fileName: string, type: string = "image/png"): Promise<File> => {
	const blob = await dataURLToBlob(dataURL);
	return blobToFile(blob, fileName, type);
};

export const fileToDataURL = (file: File): Promise<string> => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result as string);
	reader.onerror = (error) => reject(error);
});
