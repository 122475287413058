/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchInterventionsVariablesDTO = Types.Exact<{
  filter?: Types.InputMaybe<Types.InterventionFilterDTO>;
}>;


export type SearchInterventionsDTO = { __typename?: 'Query', interventions: Array<{ __typename?: 'Intervention', id: string, interventionDate: any, remarks: string, customerAddress: { __typename?: 'InterventionCustomerAddress', name: string, phoneNumber?: string | null, city?: string | null, postalcode?: string | null, street?: string | null }, serviceReport?: { __typename?: 'ServiceReport', id: string, completed: boolean } | null }> };


export const SearchInterventionsDocumentDTO = gql`
    query searchInterventions($filter: InterventionFilter) {
  interventions(filter: $filter, sort: {field: "interventionDate", order: DESC}) {
    id
    interventionDate
    remarks
    customerAddress {
      name
      phoneNumber
      city
      postalcode
      street
    }
    serviceReport {
      id
      completed
    }
  }
}
    `;

/**
 * __useSearchInterventions__
 *
 * To run a query within a React component, call `useSearchInterventions` and pass it any options that fit your needs.
 * When your component renders, `useSearchInterventions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInterventions({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchInterventions(baseOptions?: Apollo.QueryHookOptions<SearchInterventionsDTO, SearchInterventionsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchInterventionsDTO, SearchInterventionsVariablesDTO>(SearchInterventionsDocumentDTO, options);
      }
export function useSearchInterventionsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<SearchInterventionsDTO, SearchInterventionsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchInterventionsDTO, SearchInterventionsVariablesDTO>(SearchInterventionsDocumentDTO, options);
        }
export type SearchInterventionsHookResult = ReturnType<typeof useSearchInterventions>;
export type SearchInterventionsLazyQueryDTOHookResult = ReturnType<typeof useSearchInterventionsLazyQueryDTO>;
export type SearchInterventionsQueryResultDTO = Apollo.QueryResult<SearchInterventionsDTO, SearchInterventionsVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    