import {
	styled, Stack, createTheme, StackProps,
} from "@mui/material";
import { theme } from "theme";
import type { } from "@mui/lab/themeAugmentation";
import { UIComponent } from "@aptus/frontend-core";
import { ThemeProvider } from "@emotion/react";
import { forwardRef } from "react";

export const DEFAULT_PAGE_HEADER_MARGIN_TOP = "-1rem";

const pageHeaderTheme = createTheme({
	typography: {
		h1: {
			color: theme.palette.primary.light,
		},
		h2: {
			color: theme.palette.primary.light,
		},
		h3: {
			color: theme.palette.primary.light,
		},
		body1: {
			color: theme.palette.primary.light,
		},
		body2: {
			color: theme.palette.primary.light,
		},
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					color: theme.palette.primary.light,
					textDecoration: "none",
					transition: "opacity .2s ease-in-out",
					opacity: 0.5,
					"&:hover": {
						opacity: 1,
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					color: theme.palette.primary.light,
				},
			},
		},
	},
}, theme);

interface Props {
	marginTop?: StackProps["marginTop"];
}

const PageHeaderStyle = styled(Stack, {
	shouldForwardProp: (prop) => prop !== "marginTop",
})<Props>(({ marginTop = DEFAULT_PAGE_HEADER_MARGIN_TOP }) => ({
	backgroundColor: theme.palette.primary.main,
	marginLeft: "-1rem",
	marginRight: "-1rem",
	paddingLeft: "1rem",
	paddingRight: "1rem",
	transition: "margin-top .2s ease-in-out",
	marginTop: marginTop as string,
}));

export const PageHeader: UIComponent<StackProps> = forwardRef(({ children, ...stackProps }, ref) => (
	<ThemeProvider theme={pageHeaderTheme}>
		<PageHeaderStyle {...stackProps} ref={ref}>
			{children}
		</PageHeaderStyle>
	</ThemeProvider>
));
