import { PageComponent } from "@aptus/frontend-core";
import { IconButton } from "@mui/material";
import { useInterventions, useRoutes, useServiceReports } from "domains";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import i18next from "i18next";
import { useEffect } from "react";
import { ServiceReportStep } from "hooks/serviceReports/models/serviceReport";
import { event } from "event";
import { PageFooter } from "components/pageFooter";
import { SaveServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import { useGetIntervention } from "./models/getIntervention";
import { InterventionDetails } from "./components/interventionDetails";
import { toArticleRow, ArticlesTable } from "./components/articlesTable";
import { TimeTracker } from "./components/timeTracker/timeTracker";

export const InterventionPage: PageComponent = () => {
	const { routes, params } = useRoutes();
	const {
		save, isLoading: isServiceReportLoading,
	} = useServiceReports();
	const {
		intervention, isLoading: isInterventionLoading, start, pause, stop,
	} = useInterventions(useGetIntervention({ variables: { id: params.id || "" } }));
	useEffect(() => {
		if (intervention.serviceReport?.step === ServiceReportStep.Close) {
			event.emit("completeIntervention", params.id ?? "");
		}
	}, [intervention]);

	const startTimer = () => {
		const workingHours = intervention.serviceReport?.workingHours ?? [];
		const createNew = !workingHours.find((workingHour) => !workingHour.to);
		if (createNew) {
			save({
				id: intervention.serviceReport?.id,
				interventionId: intervention.id,
				workingHours: [...workingHours, { from: new Date() }],
				images: intervention.serviceReport?.images,
			} as SaveServiceReportInput);
		}
		start(intervention.id);
	};

	const pauzeTimer = () => {
		const updateWorkingHours = (intervention.serviceReport?.workingHours ?? []).map((workingHour) => {
			if (!workingHour.to) {
				return { from: workingHour.from, to: new Date() };
			}
			return workingHour;
		});
		save({
			id: intervention.serviceReport?.id,
			interventionId: intervention.id,
			workingHours: updateWorkingHours,
			images: intervention.serviceReport?.images,
		} as SaveServiceReportInput);
		pause(intervention.id);
	};

	const stopTimer = () => {
		const updateWorkingHours = (intervention.serviceReport?.workingHours ?? []).map((workingHour) => {
			if (!workingHour.to) {
				return { from: workingHour.from, to: new Date() };
			}
			return workingHour;
		});
		save({
			id: intervention.serviceReport?.id,
			interventionId: intervention.id,
			workingHours: updateWorkingHours,
			images: intervention.serviceReport?.images,
		} as SaveServiceReportInput);
		stop(intervention.id);
	};

	return (
		<>
			<IconButton to={routes.interventions.index} component={Link} aria-label={i18next.t("goBack")}>
				<ArrowBackIcon />
			</IconButton>

			<InterventionDetails {...intervention} isLoading={isInterventionLoading} />
			<br />
			<ArticlesTable articles={intervention.articles.map(toArticleRow)} />
			<br />
			<br />

			<PageFooter>
				<TimeTracker
					workingHours={intervention.workingHours}
					isDisabled={isInterventionLoading || isServiceReportLoading}
					start={startTimer}
					pause={pauzeTimer}
					stop={stopTimer}
				/>
			</PageFooter>
		</>
	);
};
