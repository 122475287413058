import { UILogic } from "@aptus/frontend-core";
import { event } from "event";
import { SetFinishWorkDayInput } from "hooks/serviceReports/models/serviceReportInput";
import { TravelRoute } from "hooks/travelRoutes/models/TravelRoute";
import i18next from "i18next";
import { FormEvent } from "react";
import {
	FieldErrors,
	FieldPath,
	UseFormRegisterReturn,
	useForm,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

interface Props {
	schema: HTMLValidationSchema<SetFinishWorkDayInput>;
	travelRoute: TravelRoute | undefined;
	saveTravelRoute: any;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<SetFinishWorkDayInput>) => UseFormRegisterReturn;
	errors: FieldErrors<SetFinishWorkDayInput>;
	isValid: boolean;
}

export type FinishDayFormProps = Props;
export type FinishDayFormLayoutProps = Result;

export const useFinishDayForm: UILogic<Props, Result> = ({
	schema,
	travelRoute,
	saveTravelRoute,
}) => {
	const {
		handleSubmit,
		register,
		formState,
		setError,
	} = useForm<any>({ mode: "onChange" });

	const submitForm = async ({ mileage }: SetFinishWorkDayInput) => {
		try {
			if (travelRoute && travelRoute.workingHour) {
				const startKm = travelRoute.workingHour.startKm ?? 0;
				const endKm = parseInt(mileage.toString(), 10);
				if (startKm > endKm) {
					setError("mileage", { type: "custom", message: i18next.t("endOfDayForm.errorMessage", { startKm }) });
					return;
				}
				await saveTravelRoute({
					id: travelRoute.id,
					data: {
						workingHour: {
							start: travelRoute.workingHour.start,
							startKm: travelRoute.workingHour.startKm,
							end: new Date(),
							endKm: parseInt(mileage.toString(), 10),
						},
					},
				});
			}
			event.emit("interventions");
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		submit: handleSubmit(submitForm),
		register: (field) => register(field, toFormHookSchema(schema)[field]),
		errors: formState.errors,
		isValid: formState.isValid,
	};
};
