import { UIComponent } from "@aptus/frontend-core";
import { TimePicker } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { TimeBlock } from "hooks/interventions/models/intervention";
import { SaveServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import i18next from "i18next";
import { Control, Controller, FieldErrors } from "react-hook-form";

interface Props {
	index: number;
	/** TODO: Find a way to make this prop specific to `TimeBlock` instead of `SaveServiceReportInput`. */
	control: Control<Pick<SaveServiceReportInput, "workingHours">>;
	errors: FieldErrors<TimeBlock>;
}

export const TimeBlockFormFields: UIComponent<Props> = ({ index, control, errors }) => (
	<Grid container spacing={2}>
		<Grid item xs={6}>
			<Controller
				control={control}
				name={`workingHours.${index}.from`}
				render={(props) => (
					<TimePicker
						{...props.field}
						label={i18next.t("timeBlockFormFields.from")}
						inputFormat="HH:mm"
						renderInput={(textFieldProps) => (
							<TextField
								{...textFieldProps}
								error={!!errors.from}
								helperText={errors.from?.message}
								sx={{ width: 1 }}
							/>
						)}
					/>
				)}
			/>
		</Grid>
		<Grid item xs={6}>
			<Controller
				control={control}
				name={`workingHours.${index}.to`}
				render={(props) => (
					<TimePicker
						{...props.field}
						label={i18next.t("timeBlockFormFields.to")}
						inputFormat="HH:mm"
						renderInput={(textFieldProps) => (
							<TextField
								{...textFieldProps}
								error={!!errors.to}
								helperText={errors.to?.message}
								sx={{ width: 1 }}
							/>
						)}
					/>
				)}
			/>
		</Grid>
	</Grid>
);
