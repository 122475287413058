import { styled } from "@mui/material";

export const LicensePlateScannerPageStyle = styled("div")({
	display: "grid",
	width: "100%",
	height: "100%",
	gridTemplateRows: "auto min-content",
	gridTemplateAreas: "'main' 'footer'",

	main: {
		gridArea: "main",
	},
});
