import { UIComponent, UILayout } from "@aptus/frontend-core";
import { TextField } from "@mui/material";
import i18next from "i18next";
import { CloseServiceReportFormLayoutProps, CloseServiceReportFormProps, useCloseServiceReportForm } from "./useCloseServiceReportForm";

const Layout: UILayout<CloseServiceReportFormLayoutProps> = ({ submit, register, errors }) => (
	<form onSubmit={submit} id="service-report-form">
		<TextField
			{...register("comment")}
			label={i18next.t("closeServiceReportForm.comment")}
			multiline
			rows={4}
			type="text"
			error={!!errors.comment}
			helperText={errors.comment}
			sx={{ width: 1 }}
		/>
	</form>
);

export const CloseServiceReportForm: UIComponent<CloseServiceReportFormProps> = (props) => (
	<Layout {...props} {...useCloseServiceReportForm(props)} />
);
