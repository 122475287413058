import { UILogic } from "@aptus/frontend-core";
import { SignServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import { FormEvent } from "react";
import {
	Control, FieldErrors, FieldPath, useForm, UseFormRegisterReturn,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

type Input = Omit<SignServiceReportInput, "id">;

interface Props {
	initialValues: Input;
	schema: HTMLValidationSchema<Input>;
	submit: (input: Input, isAutoSave: boolean) => void;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>, isAutoSave?: boolean) => void;
	autoSaveSubmit: (e: FormEvent<HTMLFormElement>, isAutoSave?: boolean) => void;
	register: (field: FieldPath<Input>) => UseFormRegisterReturn;
	control: Control<Input>;
	errors: FieldErrors<Input>;
}

export type SignServiceReportFormProps = Props;
export type SignServiceReportFormLayoutProps = Result;

export const useSignServiceReportForm: UILogic<Props, Result> = ({ initialValues, schema, submit }) => {
	const {
		handleSubmit, register, formState, control,
	} = useForm<Input>({ mode: "onChange", defaultValues: initialValues });

	return {
		submit: handleSubmit((input) => submit(input, false)),
		autoSaveSubmit: handleSubmit((input) => submit(input, true)),
		register: (field) => register(field, (toFormHookSchema(schema) as any)[field]),
		control,
		errors: formState.errors,
	};
};
