import { UIComponent } from "@aptus/frontend-core";
import { CardActionArea } from "@mui/material";
import { Typography } from "components/typography";
import { Customer, Intervention } from "hooks/interventions/models/intervention";
import { WithLoadingState } from "models/withLoadingState";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { theme } from "theme";
import i18next from "i18next";
import { InterventionCardStyle } from "./interventionCardStyle";

interface Props extends Customer {
	href: string;
	comment?: Intervention["comment"];
	showCompletionStatus?: boolean;
}

export const InterventionCard: UIComponent<WithLoadingState<Props>> = ({
	name, address, phoneNumber, isLoading, href, comment, showCompletionStatus,
}) => (
	<InterventionCardStyle data-testid={!isLoading && "intervention"}>
		<CardActionArea to={href || ""} component={Link}>
			{showCompletionStatus && (
				<CheckCircleIcon
					titleAccess={i18next.t("interventionCard.isCompleted")}
					sx={{
						color: theme.palette.success.main,
						position: "absolute",
						top: "0.5rem",
						right: "0.5rem",
					}}
				/>
			)}
			<Typography variant="h3" isLoading={isLoading} className={showCompletionStatus ? "faded" : ""}>{name}</Typography>
			<Typography variant="body2" isLoading={isLoading} className={showCompletionStatus ? "faded" : ""}>{address?.addressLine}</Typography>
			<Typography variant="body2" isLoading={isLoading} className={showCompletionStatus ? "faded" : ""}>{`${address?.postalCode} - ${address?.city}`}</Typography>
			<br />
			<Typography variant="body1" isLoading={isLoading} className={showCompletionStatus ? "faded" : ""}>{phoneNumber}</Typography>
			{comment && (
				<>
					<br />
					<Typography variant="body2" sx={{ fontStyle: "italic" }} className={showCompletionStatus ? "faded" : ""}>{comment}</Typography>
				</>
			)}
		</CardActionArea>
	</InterventionCardStyle>
);
