import { UILogic } from "@aptus/frontend-core";
import { TimeBlock } from "hooks/interventions/models/intervention";
import { useInterval } from "hooks/useInterval";
import { useEffect, useState } from "react";

interface TotalTime {
	hours: number;
	minutes: number;
	seconds: number;
}

interface CommonProps {
	isDisabled?: boolean;
	start: () => void;
	pause: () => void;
	stop: () => void;
}

interface Props {
	workingHours: TimeBlock[];
}

interface Result {
	totalTime: TotalTime;
	/** Check wether the timer has ever been started before (totalTime > 0) */
	isStarted: boolean;
	/** Check if the timer is currently running */
	isActive: boolean;
}

export type TimeTrackerProps = CommonProps & Props;
export type TimeTrackerLayoutProps = CommonProps & Result;

const calculateTotalSeconds = (workingHours: TimeBlock[]): number => workingHours
	.map(({ from, to = new Date() }) => to.getTime() - from.getTime())
	.reduce((acc, time) => acc + time, 0) / 1000;

const calculateTotalTime = (_seconds: number): Result["totalTime"] => {
	const hours = Math.floor(_seconds / 3600);
	const minutes = Math.floor((_seconds % 3600) / 60);
	const seconds = Math.floor(_seconds % 60);

	return { hours, minutes, seconds };
};

export const useTimeTracker: UILogic<Props, Result> = ({ workingHours }) => {
	const [totalSeconds, setTotalSeconds] = useState<number>(calculateTotalSeconds(workingHours || []));
	const isStarted: Result["isStarted"] = !!workingHours[0];
	const sortedWorkingHours = workingHours.sort((a, b) => a.from.getTime() - b.from.getTime());
	const isActive: Result["isActive"] = sortedWorkingHours.length !== 0 && !sortedWorkingHours.reverse()[0].to;

	useInterval(() => {
		setTotalSeconds(calculateTotalSeconds(workingHours || []));
	}, 1000);

	useEffect(() => {
		setTotalSeconds(calculateTotalSeconds(workingHours || []));
	}, [workingHours]);

	return {
		totalTime: calculateTotalTime(totalSeconds),
		isStarted,
		isActive,
	};
};
