import { DeepPartial } from "@aptus/frontend-core";

/**
 * Add an 'isLoading' property to the model. All other properties become optional when the loading state is 'true'.
 * @example
 * interface Person {
 *   name: string;
 *   age: number;
 * }
 *
 * const loading: WithLoadingState<Person> = { isLoading: true };
 * const loaded: WithLoadingState<Person> = { name: 'John', age: 42 };
 */
export type WithLoadingState<Type> = DeepPartial<Type & { isLoading: true }> | Type & { isLoading?: false };

/**
 * This is a type guard that checks if a model that's wrapped around 'WithLoadingState' is loaded.
 * Without this TypeScript is not able to infer the type correctly when 'isLoading' is 'false'.
 */
export const isLoaded = <Props>(props: Props | WithLoadingState<Props>): props is Props => ("isLoading" in props
	? !props.isLoading
	: true);
