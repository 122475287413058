import { DeepRecord } from "models/deepRecord";
import { InputHTMLAttributes } from "react";
import { RegisterOptions } from "react-hook-form";
import i18next from "i18next";
import { DeepPartial, isNotEmpty } from "@aptus/frontend-core";
import { flattenObject } from "./flattenObject";

type HTMLValidationRules = "max" | "maxLength" | "min" | "minLength" | "pattern" | "required";
type HTMLValidationAttributes = Pick<InputHTMLAttributes<any>, HTMLValidationRules>;

type ErrorMessage = { [Key in keyof Omit<ValidityState, "valid">]?: string; };
type Rule = HTMLValidationAttributes & ErrorMessage;
export type HTMLValidationSchema<Input> = DeepPartial<DeepRecord<Input, Rule>>;
export type Errors<Input> = DeepRecord<Input, string | undefined>;

export const validateRule = <Value extends string | number | undefined | null>(value: Value, rule: Rule): string | undefined => {
	if (rule.required && !value) {
		return rule.valueMissing || i18next.t("error.valueMissing");
	}

	if (typeof value === "string" && value) {
		if (rule.minLength && value.length < rule.minLength) {
			return rule.tooShort || i18next.t("error.tooShort", { minLength: rule.minLength });
		}

		if (rule.maxLength && value.length > rule.maxLength) {
			return rule.tooLong || i18next.t("error.tooLong", { maxLength: rule.maxLength });
		}

		if (rule.pattern && !value.match(rule.pattern)) {
			return rule.patternMismatch || i18next.t("error.patternMismatch");
		}
	}

	if (rule.min && value && value < rule.min) {
		return rule.rangeUnderflow || i18next.t("error.rangeUnderflow", { min: rule.min });
	}

	if (rule.max && value && value > rule.max) {
		return rule.rangeOverflow || i18next.t("error.rangeOverflow", { max: rule.max });
	}

	return undefined;
};

export const validateSchema = <Input extends Object>(input: Input | undefined, schema: HTMLValidationSchema<Input>): Errors<Input> => {
	const entries: [keyof Input, Rule][] = Object.entries(schema) as [keyof Input, Rule][];
	const isRule = (schemaOrRule: Rule | HTMLValidationSchema<any>): schemaOrRule is Rule => {
		const properties = ["required", "minLength", "maxLength", "pattern", "min", "max"];

		return properties.some((property) => property in schemaOrRule);
	};

	const newEntries = entries.map(([key, rule]) => {
		const newRule = isRule((schema as any)[key])
			? validateRule(input ? input[key] as any : undefined, rule)
			: validateSchema(input ? input[key] : undefined, (schema as any)[key]);

		return [key, newRule];
	});

	return Object.fromEntries(newEntries);
};

export const isValid = <Input extends Object>(input: Input, schema: HTMLValidationSchema<Input>): boolean => {
	const errors = validateSchema(input, schema);
	const flatErrorsObject = flattenObject(errors);
	const allErrors = Object.values(flatErrorsObject).filter(isNotEmpty);

	return allErrors.length === 0;
};

//-----------------------------------------------------------------------------------------------

export type FormHookValidationSchema<Input> = DeepRecord<Input, RegisterOptions<Input>>;

export const toFormHookSchema = <Input>(schema: HTMLValidationSchema<Input>): FormHookValidationSchema<Input> => {
	const entries: [keyof Input, Rule][] = Object.entries(schema) as [keyof Input, Rule][];

	const newEntries = entries.map(([key, rule]) => {
		const newRule: RegisterOptions<Input> = {};

		if (rule.required) {
			newRule.required = { value: true, message: rule.valueMissing || i18next.t("error.valueMissing") };
		}

		if (rule.min) {
			newRule.min = { value: rule.min, message: rule.rangeUnderflow || i18next.t("error.rangeUnderflow", { min: rule.min }) };
		}

		if (rule.max) {
			newRule.max = { value: rule.max, message: rule.rangeOverflow || i18next.t("error.rangeOverflow", { max: rule.max }) };
		}

		if (rule.minLength) {
			newRule.minLength = { value: rule.minLength, message: rule.tooShort || i18next.t("error.tooShort", { minLength: rule.minLength }) };
		}

		if (rule.maxLength) {
			newRule.maxLength = { value: rule.maxLength, message: rule.tooLong || i18next.t("error.tooLong", { maxLength: rule.maxLength }) };
		}

		if (rule.pattern) {
			newRule.pattern = { value: new RegExp(rule.pattern), message: rule.patternMismatch || i18next.t("error.patternMismatch") };
		}

		return [key, newRule];
	});

	return Object.fromEntries(newEntries);
};
