import { UseCases } from "@aptus/frontend-core";
import { Events } from "event";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useEvent } from "utils/useEvent";
import { Notification } from "./models/notification";

interface Result {
	notification?: Notification;
	closeNotification: () => void;
}

export const useNotificationsUseCases: UseCases<undefined, Result> = () => {
	const [notification, setNotification] = useState<Result["notification"]>(undefined);
	const [autocloseDelay, setAutocloseDelay] = useState<number | undefined>(undefined);

	const showRequestSucceeded: Events["mutationSucceeded"] = (message) => {
		setNotification({ title: "", message, type: "success" });
		setAutocloseDelay(5000);
	};

	const showRequestFailed: Events["mutationFailed"] = (error) => {
		setNotification({ title: i18next.t("domain.notification.somethingWentWrong"), error, type: "error" });
		setAutocloseDelay(undefined);
	};

	const closeNotification: Result["closeNotification"] = (): void => {
		setNotification(undefined);
		setAutocloseDelay(undefined);
	};

	const showLicensePlateSaved: Events["licensePlateSaved"] = (licensePlate) => {
		setNotification({ title: "", message: i18next.t("domain.notification.licensePlateSaved", { licensePlate }), type: "success" });
		setAutocloseDelay(5000);
	};

	const showLicensePlateInvalid: Events["licensePlateInvalid"] = (licensePlate) => {
		setNotification({ title: i18next.t("domain.notification.licensePlateInvalid", { licensePlate }), error: new Error(), type: "error" });
		setAutocloseDelay(undefined);
	};

	const onAttachmentFailed: Events["attachmentFailed"] = (reason) => {
		setNotification({
			title: i18next.t("domain.notification.attachmentFailed"), error: new Error(i18next.t(`domain.notification.${reason}`)), type: "error",
		});
		setAutocloseDelay(undefined);
	};

	useEffect(() => {
		if (autocloseDelay) {
			setTimeout(() => {
				closeNotification();
			}, autocloseDelay);
		}
	}, [autocloseDelay]);

	useEvent("mutationSucceeded", showRequestSucceeded);
	useEvent("mutationFailed", showRequestFailed);
	useEvent("licensePlateSaved", showLicensePlateSaved);
	useEvent("licensePlateInvalid", showLicensePlateInvalid);
	useEvent("attachmentFailed", onAttachmentFailed);

	return {
		notification,
		closeNotification,
	};
};
