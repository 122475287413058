import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication } from "domains";
import logo from "assets/images/logo.png";
import { LoginForm } from "./components/loginForm/loginForm";
import { LoginPageStyle } from "./loginPageStyle";

export const LoginPage: PageComponent = () => {
	const {
		login, loginSchema, error, isLoading,
	} = useAuthentication();

	return (
		<LoginPageStyle>
			<div className="logo-container">
				<img src={logo} alt="Detremmerie" />
			</div>
			<LoginForm
				login={login}
				schema={loginSchema}
				loginError={error}
				isLoading={isLoading}
			/>
		</LoginPageStyle>
	);
};
