import { PageComponent } from "@aptus/frontend-core";
import { IconButton } from "@mui/material";
import { useAuthentication, useRoutes } from "domains";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "components/link/link";
import i18next from "i18next";
import { LicensePlateForm } from "./components/licensePlateForm/licensePlateForm";

export const LicensePlateFormPage: PageComponent = () => {
	const { setLicensePlate, setLicensePlateSchema } = useAuthentication();
	const { routes } = useRoutes();

	return (
		<>
			<IconButton
				to={routes.licensePlate.scanner}
				component={Link}
				aria-label={i18next.t("goBack")}
				sx={{
					zIndex: 10, position: "absolute", left: "1rem", top: "1rem",
				}}
			>
				<ArrowBackIcon />
			</IconButton>

			<LicensePlateForm
				setLicensePlate={setLicensePlate}
				schema={setLicensePlateSchema}
			/>
		</>
	);
};
