/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterventionArticlesVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetInterventionArticlesDTO = { __typename?: 'Query', intervention: { __typename?: 'Intervention', id: string, interventionCode: string, warehouseLocation: string, reference: string, articles: Array<{ __typename?: 'InterventionArticle', colliNumber: number, reference: string, quantity: number, description: string, isAvailable?: boolean | null }>, customerAddress: { __typename?: 'InterventionCustomerAddress', name: string } } };


export const GetInterventionArticlesDocumentDTO = gql`
    query getInterventionArticles($id: String!) {
  intervention(id: $id) {
    id
    articles {
      colliNumber
      reference
      quantity
      description
      isAvailable @client
    }
    customerAddress {
      name
    }
    interventionCode
    warehouseLocation
    reference
  }
}
    `;

/**
 * __useGetInterventionArticles__
 *
 * To run a query within a React component, call `useGetInterventionArticles` and pass it any options that fit your needs.
 * When your component renders, `useGetInterventionArticles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterventionArticles({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInterventionArticles(baseOptions: Apollo.QueryHookOptions<GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO>(GetInterventionArticlesDocumentDTO, options);
      }
export function useGetInterventionArticlesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO>(GetInterventionArticlesDocumentDTO, options);
        }
export type GetInterventionArticlesHookResult = ReturnType<typeof useGetInterventionArticles>;
export type GetInterventionArticlesLazyQueryDTOHookResult = ReturnType<typeof useGetInterventionArticlesLazyQueryDTO>;
export type GetInterventionArticlesQueryResultDTO = Apollo.QueryResult<GetInterventionArticlesDTO, GetInterventionArticlesVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    