import { UIComponent } from "@aptus/frontend-core";
import {
	Skeleton, SkeletonProps, Typography as MuiTypography, TypographyProps,
} from "@mui/material";

interface Props extends TypographyProps {
	isLoading?: boolean;
	skeleton?: SkeletonProps;
}

export const Typography: UIComponent<Props> = ({
	isLoading, children, skeleton, ...props
}) => (
	<MuiTypography {...props}>
		{isLoading
			? <Skeleton variant="text" {...skeleton} />
			: children}
	</MuiTypography>
);
