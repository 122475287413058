import { UILogic } from "@aptus/frontend-core";
import { CloseServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import { FormEvent } from "react";
import {
	FieldErrors, FieldPath, useForm, UseFormRegisterReturn,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

type Input = Omit<CloseServiceReportInput, "id">;

interface Props {
	initialValues: Input;
	schema: HTMLValidationSchema<Input>;
	submit: (input: Input) => void;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<Input>) => UseFormRegisterReturn;
	errors: FieldErrors<Input>;
}

export type CloseServiceReportFormProps = Props;
export type CloseServiceReportFormLayoutProps = Result;

export const useCloseServiceReportForm: UILogic<Props, Result> = ({ initialValues, schema, submit }) => {
	const { handleSubmit, register, formState } = useForm<Input>({ mode: "onChange", defaultValues: initialValues });

	return {
		submit: handleSubmit(submit),
		register: (field) => register(field, (toFormHookSchema(schema) as any)[field]),
		errors: formState.errors,
	};
};
