import { UILogic } from "@aptus/frontend-core";
import { SetLicensePlateInput } from "hooks/authentication/models/authenticationInput";
import { FormEvent } from "react";
import {
	FieldErrors, FieldPath, useForm, UseFormRegisterReturn,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

interface Props {
	setLicensePlate: (licensePlate: string) => void;
	schema: HTMLValidationSchema<SetLicensePlateInput>;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<SetLicensePlateInput>) => UseFormRegisterReturn;
	errors: FieldErrors<SetLicensePlateInput>;
	isValid?: boolean;
}

export type LicensePlateFormProps = Props;
export type LicensePlateFormLayoutProps = Result;

export const useLicensePlateForm: UILogic<Props, Result> = ({ setLicensePlate, schema }) => {
	const { handleSubmit, register, formState } = useForm<SetLicensePlateInput>({ mode: "onChange" });

	return {
		submit: handleSubmit(({ licensePlate }) => setLicensePlate(licensePlate)),
		register: (field) => register(field, toFormHookSchema(schema)[field]),
		errors: formState.errors,
		isValid: formState.isValid,
	};
};
