import { UIComponent } from "@aptus/frontend-core";
import { Alert, AlertTitle } from "@mui/material";
import type { Notification as NotificationType } from "hooks/notifications/models/notification";

type Props = NotificationType & {
	close: () => void;
};

export const Notification: UIComponent<Props> = ({
	title, type, error, message, close,
}) => (
	<Alert
		severity={type}
		onClose={close}
		sx={{
			position: "fixed", width: "calc(100% - 32px)", top: 16, right: 16, zIndex: 1300,
		}}
	>
		{title && <AlertTitle>{title}</AlertTitle>}
		{error ? error.message : message}
	</Alert>
);
