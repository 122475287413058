import { UIComponent } from "@aptus/frontend-core";
import { Private } from "components/private";
import { useAuthentication, useRoutes } from "domains";
import { Role } from "hooks/authentication/models/profile";
import { useAuthenticationEvents } from "hooks/authentication/useAuthenticationUseCases";
import { InterventionPage } from "pages/interventionPage/interventionPage";
import { InterventionsPage } from "pages/interventionsPage/interventionsPage";
import { InterventionCompletePage } from "pages/interventionCompletePage/interventionCompletePage";
import { useRoutesEvents } from "hooks/routes/useRoutesUseCases";
import { LicensePlateFormPage } from "pages/licensePlateFormPage/licensePlateFormPage";
import { LoginPage } from "pages/loginPage/loginPage";
import { Page } from "pages/page";
import { Routes, Route, Navigate } from "react-router-dom";
import { LicensePlateScannerPage } from "pages/licensePlateScannerPage/licensePlateScannerPage";
import { MaterialListPage } from "pages/materialListPage/materialListPage";
import { MaterialListDetailPage } from "pages/materialListDetailPage/materialListDetailPage";
import { MileagePage } from "pages/mileagePage/mileagePage";
import { InterventionInfoPage } from "pages/interventionInfoPage/interventionInfoPage";
import { FinishWorkDayPage } from "pages/finishWorkDay/finishWorkDayPage";

/**
 * This is the router of the application.
 * It handles everything that is based on the "Router state" (URL).
 * This enforces consistency on how URLs are handled throughout the application.
 */

export const Content: UIComponent = () => {
	const { baseRoutes } = useRoutes();
	const { profile } = useAuthentication();
	useAuthenticationEvents(useAuthentication());
	useRoutesEvents(useRoutes());

	return (
		<Routes>
			<Route path={baseRoutes.index} element={<Navigate to={baseRoutes.interventions.index} />} />

			<Route element={<Page backgroundColor="white" />}>
				<Route
					path={baseRoutes.login}
					element={(
						<Private isAuthorized={profile.hasRole(Role.Guest)} redirectTo={baseRoutes.index}>
							<LoginPage />
						</Private>
					)}
				/>

				<Route
					path={baseRoutes.licensePlate.form}
					element={(
						<Private isAuthorized={!!localStorage.getItem("accessToken")} redirectTo={baseRoutes.login}>
							<LicensePlateFormPage />
						</Private>
					)}
				/>

				<Route
					path={baseRoutes.mileage}
					element={(
						<Private isAuthorized={!!localStorage.getItem("licensePlate")} redirectTo={baseRoutes.login}>
							<MileagePage />
						</Private>
					)}
				/>

				<Route
					path={baseRoutes.finishDay}
					element={(
						<Private isAuthorized={!!localStorage.getItem("licensePlate")} redirectTo={baseRoutes.login}>
							<FinishWorkDayPage />
						</Private>
					)}
				/>

				<Route path={baseRoutes.interventions.index}>
					<Route
						path={baseRoutes.interventions.detail}
						element={(
							<Private isAuthorized={!profile.hasRole(Role.Guest)} redirectTo={baseRoutes.login}>
								<InterventionPage />
							</Private>
						)}
					/>
					<Route
						path={baseRoutes.interventions.info}
						element={(
							<Private isAuthorized={!profile.hasRole(Role.Guest)} redirectTo={baseRoutes.login}>
								<InterventionInfoPage />
							</Private>
						)}
					/>
					<Route
						path={baseRoutes.interventions.completed}
						element={(
							<Private isAuthorized={!profile.hasRole(Role.Guest)} redirectTo={baseRoutes.login}>
								<InterventionCompletePage />
							</Private>
						)}
					/>
				</Route>

				<Route
					path={baseRoutes.materials.index}
					element={(
						<Private isAuthorized={!profile.hasRole(Role.Guest)} redirectTo={baseRoutes.login}>
							<MaterialListPage />
						</Private>
					)}
				/>

				<Route
					path={baseRoutes.materials.intervention}
					element={(
						<Private isAuthorized={!profile.hasRole(Role.Guest)} redirectTo={baseRoutes.login}>
							<MaterialListDetailPage />
						</Private>
					)}
				/>
			</Route>

			<Route element={<Page backgroundColor="primary" />}>
				<Route
					path={baseRoutes.licensePlate.scanner}
					element={(
						<Private isAuthorized={!!localStorage.getItem("accessToken")} redirectTo={baseRoutes.login}>
							<LicensePlateScannerPage />
						</Private>
					)}
				/>

				<Route path={baseRoutes.interventions.index}>
					<Route
						path={baseRoutes.interventions.open}
						element={(
							<Private isAuthorized={profile.hasRole(Role.Installer)} redirectTo={baseRoutes.login}>
								<InterventionsPage />
							</Private>
						)}
					/>

					<Route
						path={baseRoutes.interventions.closed}
						element={(
							<Private isAuthorized={profile.hasRole(Role.Installer)} redirectTo={baseRoutes.login}>
								<InterventionsPage isCompleted />
							</Private>
						)}
					/>
				</Route>
			</Route>
		</Routes>
	);
};
