import { UILogic } from "@aptus/frontend-core";
import { SxProps } from "@mui/material";
import dayjs from "dayjs";
import i18next from "i18next";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);

interface Common {
	sx?: SxProps;
	goToPreviousDay: () => void;
	goToNextDay: () => void;
}

interface Props {
	currentDay: Date;
}

interface Result {
	currentDay: string;
}

export type DayPickerProps = Props & Common;
export type DayPickerLayoutProps = Result & Common;

export const useDayPicker: UILogic<Props, Result> = ({ currentDay }) => ({
	currentDay: dayjs(currentDay).isToday()
		? i18next.t("dayPicker.today")
		: dayjs(currentDay).format("DD MMMM YYYY"),
});
