import { styled } from "@mui/material";

export const LoginPageStyle = styled("div")({
	height: "calc(100vh - 2rem)",
	display: "grid",
	gridAutoFlow: "row",
	gridTemplateRows: "1fr min-content 1rem",

	".logo-container": {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
});
