import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Box, IconButton, Stack } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Typography } from "components/typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import i18next from "i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ReactNode } from "react";
import { Link } from "components/link/link";
import { isLoaded, WithLoadingState } from "models/withLoadingState";
import { TravelRouteLayoutProps, TravelRouteProps, useTravelRoute } from "./useTravelRoute";

interface IconProps {
	isOpen?: boolean;
	icon: ReactNode;
	children: ReactNode;
}

interface IconsProps {
	isOpen?: boolean;
	installers: string[];
	address: string;
	time: string;
	licensePlate: string;
	changeVehicleUrl: string;
	isEmpty?: boolean;
}

const Icon: UIComponent<IconProps> = ({ isOpen, icon, children }) => (
	<Stack
		direction={isOpen ? "row-reverse" : "row"}
		justifyContent={isOpen ? "space-between" : "flex-start"}
		spacing={1}
	>
		{icon}
		{children}
	</Stack>
);

const Icons: UIComponent<WithLoadingState<IconsProps>> = ({
	isOpen, installers = [], address, time, licensePlate, changeVehicleUrl = "", isLoading, isEmpty,
}) => (
	<Stack direction={isOpen ? "column" : "row"} spacing={2}>
		{(isLoading || !isEmpty) && (
			<Icon isOpen={isOpen} icon={<PersonOutlineIcon />}>
				<Typography isLoading={isLoading} skeleton={{ width: isOpen ? "50vw" : "15vw" }}>{isOpen ? installers.join(", ") : installers.length}</Typography>
			</Icon>
		)}
		{(isLoading || !isEmpty) && (
			<Icon isOpen={isOpen} icon={<AccessTimeIcon />}>
				<Typography isLoading={isLoading} skeleton={{ width: isOpen ? "50vw" : "15vw" }}>{isOpen ? `${time} - ${address}` : time}</Typography>
			</Icon>
		)}
		<Icon isOpen={isOpen} icon={<LocalShippingIcon />}>
			<Stack direction="row" spacing={1}>
				<Typography isLoading={isLoading} skeleton={{ width: isOpen ? "30vw" : "15vw" }}>{licensePlate}</Typography>
				{isOpen && <Link to={changeVehicleUrl}>{i18next.t("travelRoute.changeVehicle")}</Link>}
			</Stack>
		</Icon>
	</Stack>
);

const Layout: UILayout<WithLoadingState<TravelRouteLayoutProps>> = ({
	travelRouteId, isOpen, toggle, sx, ...props
}) => (isOpen
	? (
		<Box width="100%" sx={sx as any} alignItems="center">
			<Box width="100%" display="flex" justifyContent="space-between">
				<Typography fontWeight="bold" isLoading={props.isLoading} skeleton={{ width: "50vw" }}>{`${i18next.t("travelRoute.travelRoute")}: ${travelRouteId}`}</Typography>
				<IconButton onClick={toggle} size="small" aria-label={i18next.t("travelRoute.toggle")} edge="end">
					<ExpandLessIcon />
				</IconButton>
			</Box>
			<Icons isOpen={isOpen} {...props} />
		</Box>
	)
	: (
		<Box width="100%" display="flex" justifyContent="space-between" sx={sx as any} alignItems="center">
			<Icons isOpen={isOpen} {...props} />

			<IconButton onClick={toggle} size="small" aria-label={i18next.t("travelRoute.toggle")} edge="end">
				<KeyboardArrowDownIcon />
			</IconButton>
		</Box>
	));

export const TravelRoute: UIComponent<WithLoadingState<TravelRouteProps>> = (props) => (isLoaded(props)
	? <Layout {...props} {...useTravelRoute(props)} />
	: (
		<Layout
			{...props}
			{...useTravelRoute({
				address: { addressLine: "", city: "", postalCode: "" },
				interventionTime: new Date(),
			})}
		/>
	));
