import { Domain } from "@aptus/frontend-core";
import { AuthenticationMapper } from "hooks/authentication/AuthenticationMapper";
import { useLogin } from "hooks/authentication/models/login";
import { useRefreshToken } from "hooks/authentication/models/refreshToken";
import { useAuthenticationUseCases } from "hooks/authentication/useAuthenticationUseCases";
import { InterventionMapper, InterventionsAPIDTO } from "hooks/interventions/InterventionMapper";
import { useInterventionsUseCases } from "hooks/interventions/useInterventionsUseCases";
import { useRoutesUseCases } from "hooks/routes/useRoutesUseCases";
import { useServiceReportsUseCases } from "hooks/serviceReports/useServiceReportsUseCases";
import { ServiceReportMapper, ServiceReportsAPIDTO } from "hooks/serviceReports/ServiceReportMapper";
import { useCreateServiceReport } from "hooks/serviceReports/models/createServiceReport";
import { useUpdateServiceReport } from "hooks/serviceReports/models/updateServiceReport";
import { useNotificationsUseCases } from "hooks/notifications/useNotificationsUseCases";
import { useSignServiceReport } from "hooks/serviceReports/models/signServiceReport";
import { useAttachmentsUseCases } from "hooks/attachments/useAttachmentsUseCases";
import { AttachmentMapper } from "hooks/attachments/AttachmentMapper";
import { useUploadAttachment } from "hooks/attachments/models/uploadAttachment";
import { useCloseServiceReport } from "hooks/serviceReports/models/closeServiceReport";
import { TravelRouteAPIDTO, TravelRouteMapper } from "hooks/travelRoutes/TravelRouteMapper";
import { useUpdateTravelRoute } from "hooks/travelRoutes/models/updateTravelRoute";
import { useTravelRoutesUseCases } from "hooks/travelRoutes/useTravelRouteUseCases";
import { useProfileUseCases } from "hooks/profile/useProfileUseCases";
import { ProfileMapper } from "hooks/profile/profileMapper";

export const useRoutes: Domain<undefined, typeof useRoutesUseCases> = useRoutesUseCases;
export const useNotifications: Domain<undefined, typeof useNotificationsUseCases> = useNotificationsUseCases;

export const useAttachments: Domain<undefined, typeof useAttachmentsUseCases> = () => {
	const map = new AttachmentMapper();
	const [uploadMutation] = useUploadAttachment();

	return useAttachmentsUseCases({
		uploadMutation: map.toUploadMutation(uploadMutation),
	});
};

export const useAuthentication: Domain<undefined, typeof useAuthenticationUseCases> = () => {
	const map = new AuthenticationMapper();
	const [loginMutation] = useLogin();
	const [refreshTokenMutation] = useRefreshToken();

	return useAuthenticationUseCases({
		loginMutation: map.toLoginMutation(loginMutation),
		refreshTokenMutation: map.toRefreshTokenMutation(refreshTokenMutation),
		startMileage: Number(localStorage.getItem("mileage")),
	});
};

export const useInterventions: Domain<InterventionsAPIDTO, typeof useInterventionsUseCases> = (api) => {
	const { routes } = useRoutes();
	const map = new InterventionMapper({
		createURL: (id) => routes.interventions.detail.replace(":id", id),
		createMaterialListURL: (id) => routes.materials.intervention.replace(":id", id),
		toServiceReport: new ServiceReportMapper().toServiceReport,
	});

	return useInterventionsUseCases({
		api: map.toAPI(api),
		defaultIntervention: map.toIntervention({}),
		startMutation: map.toStartMutation,
		stopMutation: map.toStopMutation,
		toggleArticleAvailabilityMutation: map.toToggleArticleAvailabilityMutation,
		toggleAllArticlesAvailabilityMutation: map.toToggleAllArticlesAvailabilityMutation,
	});
};

export const useServiceReports: Domain<ServiceReportsAPIDTO, typeof useServiceReportsUseCases> = (api) => {
	const { upload, uploadMany } = useAttachments();
	const map = new ServiceReportMapper();
	const [createMutation] = useCreateServiceReport({ refetchQueries: ["getIntervention", "getInterventions"] });
	const [updateMutation] = useUpdateServiceReport({ refetchQueries: ["getIntervention", "getInterventions"] });
	const [signMutation] = useSignServiceReport({ refetchQueries: ["getIntervention", "getInterventions"] });
	const [closeMutation] = useCloseServiceReport({ refetchQueries: ["getIntervention", "getInterventions"] });

	return useServiceReportsUseCases({
		api: map.toAPI(api),
		defaultServiceReport: map.toServiceReport({}),
		createMutation: map.toCreateMutation(createMutation),
		updateMutation: map.toUpdateMutation(updateMutation),
		signMutation: map.toSignMutation(signMutation),
		closeMutation: map.toCloseMutation(closeMutation),
		uploadAttachment: upload,
		uploadManyAttachments: uploadMany,
	});
};

export const useTravelRoute: Domain<TravelRouteAPIDTO, typeof useTravelRoutesUseCases> = (api) => {
	const map = new TravelRouteMapper();
	const [updateMutation] = useUpdateTravelRoute({ refetchQueries: ["getTravelRoutes"] });

	return useTravelRoutesUseCases({
		api: map.toAPI(api),
		updateMutation: map.toUpdateMutation(updateMutation),
	});
};

export const useProfile: Domain<any, typeof useProfileUseCases> = (api) => {
	const map = new ProfileMapper();

	return useProfileUseCases({
		api: map.toAPI(api),
	});
};
