import { API, UseCases } from "@aptus/frontend-core";
import { Profile } from "./models/profile";

export type ProfileAPI = API<Profile>;

interface Props {
	api: ProfileAPI;
}

interface Result {
	profile: Profile;
	isLoading: boolean;
	error?: Error;
}

export const useProfileUseCases: UseCases<Props, Result> = ({ api }) => ({
	profile: api.data,
	isLoading: api.isLoading,
	error: api.error,
});
