/* eslint-disable */
// import * as Types from '../../models.schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const GetProfile = gql`
query getProfile {
    profile {
        user {
        id
        userName
        }
    }
}
`;

export function useGetProfile(baseOptions?: Apollo.QueryHookOptions) {
	const options = {...baseOptions};

	return Apollo.useQuery(GetProfile, options);
}

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[]
	}
}
const result: PossibleTypesResultData = {
	"possibleTypes": {}
};

export default result;