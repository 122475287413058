import { UIComponent } from "@aptus/frontend-core";
import { Checkbox } from "@mui/material";
import { Table } from "components/table/table";
import { TableCell, TableCellProps } from "components/table/tableCell";
import { TableHead } from "components/table/tableHead";
import { TableBody } from "components/table/tableBody";
import { Typography } from "components/typography";
import { Article } from "hooks/interventions/models/intervention";
import i18next from "i18next";

interface StockRow {
	isAvailable: boolean;
	description: {
		barcode: string;
		colli: number;
		body: string;
	}
	amount: number;
}

interface Props {
	stock: StockRow[];
	isEverythingAvailable: boolean;
	isEverythingUnavailable: boolean;
	toggle: (index: number) => void;
	toggleAll: () => void;
}

type CheckboxTableCellProps = TableCellProps & {
	isChecked?: boolean;
	isIndeterminate?: boolean;
	toggle: (index: number) => void;
};

export const toStockRow = (article: Article): StockRow => ({
	isAvailable: article.isAvailable,
	description: {
		barcode: article.reference,
		colli: article.colli,
		body: article.description,
	},
	amount: article.amount,
});

const CheckboxTableCell: UIComponent<CheckboxTableCellProps> = ({
	row, property, index = 0, toggle, isChecked, isIndeterminate,
}) => (
	<TableCell property={property}>
		<Checkbox
			checked={row ? row[property] : isChecked}
			indeterminate={isIndeterminate}
			onClick={() => toggle(index)}
			sx={{ width: 2, height: 2 }}
		/>
	</TableCell>
);

const ArticleTableCell: UIComponent<TableCellProps> = ({ row, property }) => (
	<TableCell property={property}>
		<Typography variant="body2">{`${i18next.t("stockTable.barcode")}: ${row[property].barcode}`}</Typography>
		<Typography variant="body2">{`${i18next.t("stockTable.colli")}: ${row[property].colli}`}</Typography>
		<Typography variant="body1">{row[property].body}</Typography>
	</TableCell>
);

export const StockTable: UIComponent<Props> = ({
	stock, isEverythingAvailable, isEverythingUnavailable, toggle, toggleAll,
}) => (stock.length > 0
	? (
		<Table rows={stock}>
			<TableHead>
				<CheckboxTableCell property="isAvailable" toggle={toggleAll} isChecked={isEverythingAvailable} isIndeterminate={!isEverythingAvailable && !isEverythingUnavailable} />
				<TableCell property="description">{i18next.t("stockTable.description")}</TableCell>
				<TableCell property="amount">{i18next.t("stockTable.amount")}</TableCell>
			</TableHead>
			<TableBody>
				<CheckboxTableCell property="isAvailable" toggle={toggle} />
				<ArticleTableCell property="description" />
				<TableCell property="amount" />
			</TableBody>
		</Table>
	)
	: null);
