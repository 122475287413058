/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterventionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetInterventionDTO = { __typename?: 'Query', intervention: { __typename?: 'Intervention', id: string, interventionDate: any, interventionCode: string, orderNumber: string, warehouseLocation: string, model: string, completed: boolean, reference: string, remarks: string, articles: Array<{ __typename?: 'InterventionArticle', reference: string, quantity: number, remarks: string, description: string }>, customerAddress: { __typename?: 'InterventionCustomerAddress', street?: string | null, postalcode?: string | null, city?: string | null, phoneNumber?: string | null, name: string }, workingHours: Array<{ __typename?: 'TimeBlock', from: string, to?: string | null }>, travelRoute: { __typename?: 'TravelRoute', startingHour: any }, serviceReport?: { __typename?: 'ServiceReport', id: string, createdOn: any, updatedOn: any, articlesRetoured?: string | null, articlesToOrder?: string | null, endKm?: number | null, executedWork?: string | null, remainingWork?: string | null, remainingWorkManPower?: number | null, completed: boolean, startKm: number, pictures?: Array<{ __typename?: 'File', id: string, url: string }> | null, signature?: { __typename?: 'File', id: string } | null, remarkForCustomer?: { __typename?: 'Remark', id: string, message: string } | null, remarksForInternal?: Array<{ __typename?: 'Remark', id: string, message: string }> | null, workingHours?: Array<{ __typename?: 'TimeBlock', start: any, end?: any | null }> | null } | null } };


export const GetInterventionDocumentDTO = gql`
    query getIntervention($id: String!) {
  intervention(id: $id) {
    id
    interventionDate
    articles {
      reference
      quantity
      remarks
      description
    }
    customerAddress {
      street
      postalcode
      city
      phoneNumber
      name
    }
    interventionCode
    orderNumber
    warehouseLocation
    model
    completed
    reference
    remarks
    workingHours @client {
      from
      to
    }
    travelRoute {
      startingHour
    }
    serviceReport {
      id
      pictures {
        id
        url
      }
	  customerEmail
      createdOn
      updatedOn
      articlesRetoured
      articlesToOrder
      endKm
      executedWork
      remainingWork
      remainingWorkManPower
      completed
      signature {
        id
		url
      }
      remarkForCustomer {
        id
        message
      }
	  remarkFromCustomer {
		id
		message
	  }
      remarksForInternal {
        id
        message
      }
      startKm
      workingHours {
        start
        end
      }
    }
  }
}
    `;

/**
 * __useGetIntervention__
 *
 * To run a query within a React component, call `useGetIntervention` and pass it any options that fit your needs.
 * When your component renders, `useGetIntervention` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntervention({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIntervention(baseOptions: Apollo.QueryHookOptions<GetInterventionDTO, GetInterventionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterventionDTO, GetInterventionVariablesDTO>(GetInterventionDocumentDTO, options);
      }
export function useGetInterventionLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetInterventionDTO, GetInterventionVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterventionDTO, GetInterventionVariablesDTO>(GetInterventionDocumentDTO, options);
        }
export type GetInterventionHookResult = ReturnType<typeof useGetIntervention>;
export type GetInterventionLazyQueryDTOHookResult = ReturnType<typeof useGetInterventionLazyQueryDTO>;
export type GetInterventionQueryResultDTO = Apollo.QueryResult<GetInterventionDTO, GetInterventionVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    