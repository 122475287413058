import { UIComponent } from "@aptus/frontend-core";
import { ReactNode } from "react";

interface Props {
	children: ReactNode;
}

export const InterventionsFooter: UIComponent<Props> = ({ children }) => (
	<div style={{
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "grey",
		position: "fixed",
		bottom: "10px",
		right: "10px",
		left: "10px",
		padding: "10px",
		borderRadius: "10px",
	}}
	>
		{children}
	</div>
);
