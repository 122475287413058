import { Mutation, UseCases } from "@aptus/frontend-core";
import { client, persistor } from "client";
import { event } from "event";
import i18next from "i18next";
import jwtDecode from "jwt-decode";
import { useCallback, useState } from "react";
import { useEvent } from "utils/useEvent";
import { HTMLValidationSchema, isValid } from "utils/validate";
import { LoginInput, RefreshTokenInput, SetLicensePlateInput } from "./models/authenticationInput";
import { Profile, Role } from "./models/profile";

interface Tokens {
	accessToken: string;
	refreshToken: string;
}

interface JWTToken {
	roles: string[];
}

export type LoginMutation = Mutation<LoginInput, Tokens>;
export type RefreshTokenMutation = Mutation<RefreshTokenInput, Tokens>;

interface Props {
	loginMutation: LoginMutation;
	refreshTokenMutation: RefreshTokenMutation;
	startMileage: number;
}

interface Result {
	profile: Profile;
	error: string;
	isLoading: boolean;
	loginSchema: HTMLValidationSchema<LoginInput>;
	setLicensePlateSchema: HTMLValidationSchema<SetLicensePlateInput>;
	login: (input: LoginInput | RefreshTokenInput) => Promise<void>;
	logout: () => void;
	setLicensePlate: (licensePlate: SetLicensePlateInput["licensePlate"]) => void;
}

export interface AuthenticationEvents {
	loggedIn: () => void;
	loggedOut: () => void;
	tokenExpired: () => void;
	licensePlateSaved: (licensePlate: string) => void;
	licensePlateInvalid: (licensePlate: string) => void;
}

export const useAuthenticationUseCases: UseCases<Props, Result> = ({ loginMutation, refreshTokenMutation, startMileage }) => {
	const [error, setError] = useState<Result["error"]>("");
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const hasRole = (role: Role, ...roles: Role[]): boolean => roles.some((_role) => _role === role);
	const token = localStorage.getItem("accessToken");
	const licensePlate = localStorage.getItem("licensePlate") || "";

	const getRole = (roles: string[] = []): Role => {
		const isFullyAuthenticated: boolean = !!(licensePlate && startMileage);

		if (isFullyAuthenticated && roles.includes("ROLE_INSTALLER")) return Role.Installer;
		return Role.Guest;
	};

	const formatLicensePlate = (_licensePlate: string): Result["profile"]["licensePlate"] => (_licensePlate
		.match(/[a-zA-Z]+|(?=\d{4})\d{2}|\d{3}|\d{2}|\d/g) || [])
		.join("-")
		.toUpperCase();

	const profile: Result["profile"] = {
		role: getRole(token ? jwtDecode<JWTToken>(token).roles : []),
		licensePlate: formatLicensePlate(licensePlate),
		hasRole: (roles) => hasRole(getRole(token ? jwtDecode<JWTToken>(token).roles : []), roles),
	};

	const loginSchema: Result["loginSchema"] = {
		username: { required: true },
		password: { required: true },
	};

	const setLicensePlateSchema: Result["setLicensePlateSchema"] = {
		licensePlate: { required: true, pattern: /^((\w{2}?-?\d{2}-?\d{2})|(\d{2}?-?\d{2}-?\w{2})|(\d{2}?-?\w{2}-?\d{2})|(\w{2}?-?\d{2}-?\w{2})|(\w{2}?-?\w{2}-?\d{2})|(\d{2}?-?\w{2}-?\w{2})|(\d{2}?-?\w{3}-?\d{1})|(\d{1}?-?\w{3}-?\d{2})|(\w{2}?-?\d{3}-?\w{1})|(\w{1}?-?\d{3}-?\w{2})|(\w{3}?-?\d{2}-?\w{1})|(\d{1}?-?\w{2}-?\d{3})|((\d{1})?-?[a-zA-Z]{3}-?\d{3}))$/.source, patternMismatch: i18next.t("domain.authentication.invalidLicensePlate") },
	};

	const clearLocalStorage = (): void => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("licensePlate");
		/*
			Purges all Apollo cache.
			Just removing the localStorage "apollo-cache-persist" key would cause issues as the query was still in memory and the client would not refetch.
		*/
		persistor.purge();
	};

	const login: Result["login"] = useCallback(async (input) => {
		setError("");
		setIsLoading(true);
		clearLocalStorage();

		try {
			const tokens = "refreshToken" in input
				? await refreshTokenMutation(input)
				: await loginMutation(input);

			if (tokens) {
				localStorage.setItem("accessToken", tokens.accessToken);
				localStorage.setItem("refreshToken", tokens.refreshToken);
				event.emit("loggedIn");
			} else {
				throw new Error(i18next.t("domain.authentication.loginFailed"));
			}
		} catch (_error) {
			setError(i18next.t("domain.authentication.loginFailed"));
		} finally {
			setIsLoading(false);
		}
	}, []);

	const logout: Result["logout"] = () => {
		clearLocalStorage();
		client.clearStore();
		event.emit("loggedOut");
	};

	const setLicensePlate: Result["setLicensePlate"] = (_licensePlate) => {
		if (isValid({ licensePlate: _licensePlate }, setLicensePlateSchema)) {
			localStorage.setItem("licensePlate", _licensePlate.replace(/-/g, "").toUpperCase());
			event.emit("licensePlateSaved", formatLicensePlate(_licensePlate));
		} else {
			event.emit("licensePlateInvalid", formatLicensePlate(_licensePlate));
		}
	};

	return {
		profile,
		error,
		isLoading,
		loginSchema,
		setLicensePlateSchema,
		login,
		logout,
		setLicensePlate,
	};
};

export const useAuthenticationEvents = (authentication: ReturnType<UseCases<Props, Result>>): void => {
	const refreshAccessToken = async () => {
		const refreshToken = localStorage.getItem("refreshToken");
		if (refreshToken) await authentication.login({ refreshToken });
	};

	useEvent("tokenExpired", refreshAccessToken);
};
