import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Button, Stack, Typography } from "@mui/material";
import i18next from "i18next";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TimeTrackerLayoutProps, TimeTrackerProps, useTimeTracker } from "./useTimeTracker";

const Layout: UILayout<TimeTrackerLayoutProps> = ({
	isActive, isStarted, pause, start, stop, totalTime, isDisabled,
}) => {
	if (!isStarted) {
		return (
			<Button onClick={start} disabled={isDisabled}>{i18next.t("timeTracker.start")}</Button>
		);
	}

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Stack direction="row" spacing={2}>
				<Typography color="white"><AccessTimeIcon /></Typography>
				<Typography color="white" variant="body1">{i18next.t("timeTracker.time", { ...totalTime })}</Typography>
			</Stack>

			<Stack direction="row" spacing={2}>
				{isActive && <Button onClick={pause} disabled={isDisabled} aria-label={i18next.t("timeTracker.pause")}><PauseIcon /></Button>}
				{!isActive && <Button onClick={start} disabled={isDisabled} aria-label={i18next.t("timeTracker.resume")}><PlayArrowIcon /></Button>}
				<Button onClick={stop} disabled={isDisabled} aria-label={i18next.t("timeTracker.stop")}><StopIcon /></Button>
			</Stack>
		</Stack>
	);
};

export const TimeTracker: UIComponent<TimeTrackerProps> = (props) => (
	<Layout {...props} {...useTimeTracker(props)} />
);
