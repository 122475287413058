import { UIComponent, UILayout } from "@aptus/frontend-core";
import { TextField, debounce } from "@mui/material";
import { SignatureInput } from "components/signatureInput/signatureInput";
import i18next from "i18next";
import { Controller } from "react-hook-form";
import { SignServiceReportFormLayoutProps, SignServiceReportFormProps, useSignServiceReportForm } from "./useSignServiceReportForm";

const Layout: UILayout<SignServiceReportFormLayoutProps> = ({
	submit, autoSaveSubmit, register, errors, control,
}) => {
	const onInputChange = debounce(autoSaveSubmit, 1000);

	return (
		<form onInput={onInputChange} onSubmit={submit} id="service-report-form">
			<TextField
				{...register("email")}
				label={i18next.t("signServiceReportForm.email")}
				type="email"
				error={!!errors.email}
				helperText={errors.email?.message}
				sx={{ width: 1 }}
			/>
			<TextField
				{...register("remarks")}
				multiline
				rows={4}
				label={i18next.t("signServiceReportForm.remarks")}
				type="text"
				error={!!errors.remarks}
				helperText={errors.remarks?.message}
				sx={{ width: 1 }}
			/>
			<Controller
				control={control}
				name="signature"
				// rules={{ required: true }}
				render={({ field }) => (
					<SignatureInput
						innerRef={field.ref}
						onChange={field.onChange}
						label={i18next.t("signServiceReportForm.signature")}
						error={!!errors.signature}
						helperText={errors.signature?.message}
					/>
				)}
			/>
		</form>
	);
};

export const SignServiceReportForm: UIComponent<SignServiceReportFormProps> = (props) => (
	<Layout {...props} {...useSignServiceReportForm(props)} />
);
