import { UIComponent } from "@aptus/frontend-core";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import "./scanner.module.css";

interface Props {
	onScan: (result: string) => void;
}

export const Scanner: UIComponent<Props> = ({ onScan }) => (
	<BarcodeScannerComponent
		width="100vw"
		height="100vh"
		facingMode="environment"
		onUpdate={(error, result) => {
			if (result) onScan(result.getText());
		}}
	/>
);
