import { styled } from "@mui/material";

export const FullScreenForm = styled("div")({
	display: "grid",
	width: "100%",
	height: "100%",
	gridTemplateRows: "auto min-content",
	gridTemplateAreas: "'main' 'footer'",

	main: {
		gridArea: "main",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flex: "100%",

		".MuiTextField-root": {
			width: "100%",
		},
	},
});
