import { FunctionComponent, StrictMode } from "react";
import ReactDOM from "react-dom";
import { worker } from "mocks/worker";
import { theme } from "theme";
import "i18n";
import { ApolloProvider } from "@apollo/client";
import { client } from "client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { HelmetProvider } from "react-helmet-async";
import { useNotifications } from "domains";
import { Notification } from "components/notification";
import dayjs from "dayjs";
import { reportWebVitals } from "./reportWebVitals";
import { Content } from "./Routes";
import "dayjs/locale/nl-be";

dayjs.locale("nl-be");

// Set `onUnhandledRequest` to `warn` to log unhandled requests to the console.
if (process.env.REACT_APP_ENVIRONMENT === "local") {
	worker.start({
		onUnhandledRequest: "bypass",
	});
}

const App: FunctionComponent = () => {
	const { notification, closeNotification } = useNotifications();

	return (
		<StrictMode>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<ThemeProvider theme={theme}>
					<ApolloProvider client={client}>
						<HelmetProvider>
							<BrowserRouter>
								<CssBaseline />
								<Content />
								{notification && <Notification {...notification} close={closeNotification} />}
							</BrowserRouter>
						</HelmetProvider>
					</ApolloProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</StrictMode>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
