import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Button, TextField } from "@mui/material";
import { Footer } from "components/footer";
import i18next from "i18next";
import { FullScreenForm } from "style/fullScreenForm";
import { FinishDayFormLayoutProps, FinishDayFormProps, useFinishDayForm } from "./useFinishDayForm";

const Layout: UILayout<FinishDayFormLayoutProps> = ({
	submit, register, errors, isValid,
}) => (
	<form onSubmit={submit} style={{ width: "100%", height: "95%" }}>
		<FullScreenForm>
			<main>
				<TextField
					{...register("mileage")}
					type="number"
					label={i18next.t("endOfDayForm.mileage")}
					error={!!errors.mileage}
					helperText={errors.mileage?.message}
					autoFocus
				/>
			</main>
		</FullScreenForm>
		<Footer>
			<Button
				type="submit"
				disabled={!isValid}
				fullWidth
			>
				{i18next.t("endOfDayForm.submit")}
			</Button>
		</Footer>
	</form>
);

export const FinishDayForm: UIComponent<FinishDayFormProps> = (props) => (
	<Layout {...props} {...useFinishDayForm(props)} />
);
