import { Stack, Checkbox } from "@mui/material";
import { Link } from "components/link/link";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import i18next from "i18next";
import { Typography } from "components/typography";
import { UIComponent } from "@aptus/frontend-core";
import { Intervention } from "hooks/interventions/models/intervention";
import { WithLoadingState } from "models/withLoadingState";

interface Props {
	url: string;
	customerName: string;
	articleCount?: number;
	isEverythingAvailable: boolean;
	toggleAvailability: () => void;
}

export const toInterventionListItemProps = (intervention: Intervention): Omit<Props, "toggleAvailability"> => ({
	url: intervention.materialListUrl,
	customerName: intervention.customer.name,
	articleCount: intervention.articles.length,
	isEverythingAvailable: intervention.isEverythingAvailable,
});

export const InterventionListItem: UIComponent<WithLoadingState<Props>> = ({
	isLoading, isEverythingAvailable, url, customerName, toggleAvailability, articleCount = 0,
}) => (
	<Stack direction="row" spacing={2}>
		<Checkbox checked={isEverythingAvailable} disabled={isLoading || !articleCount} onClick={toggleAvailability} />
		<Link to={url || ""} color="inherit" underline="none" width="100%">
			<Stack direction="row" spacing={2} justifyContent="space-between" py={2}>
				<Stack>
					<Typography variant="h2" sx={{ marginTop: 0, fontSize: "100%" }} isLoading={isLoading} skeleton={{ width: "60vw" }}>{customerName}</Typography>
					<Typography variant="body1">{i18next.t("interventionListItem.articles", { count: articleCount })}</Typography>
				</Stack>
				<ChevronRightIcon />
			</Stack>
		</Link>
	</Stack>
);
