/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignServiceReportVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  email: Types.Scalars['String'];
  remarks?: Types.InputMaybe<Types.RemarkInputDTO>;
  signature?: Types.Scalars['String'];
}>;


export type SignServiceReportDTO = { __typename?: 'Mutation', updateOneServiceReport: { __typename?: 'ServiceReport', id: string } };


export const SignServiceReportDocumentDTO = gql`
    mutation signServiceReport($id: String!, $email: String!, $remarks: RemarkInput, $signature: String) {
  updateOneServiceReport(
    id: $id
    data: {customerEmail: $email, remarkFromCustomer: $remarks, signature: $signature}
  ) {
    id
  }
}
    `;
export type SignServiceReportMutationFnDTO = Apollo.MutationFunction<SignServiceReportDTO, SignServiceReportVariablesDTO>;

/**
 * __useSignServiceReport__
 *
 * To run a mutation, you first call `useSignServiceReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignServiceReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signServiceReport, { data, loading, error }] = useSignServiceReport({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      remarks: // value for 'remarks'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useSignServiceReport(baseOptions?: Apollo.MutationHookOptions<SignServiceReportDTO, SignServiceReportVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignServiceReportDTO, SignServiceReportVariablesDTO>(SignServiceReportDocumentDTO, options);
      }
export type SignServiceReportHookResult = ReturnType<typeof useSignServiceReport>;
export type SignServiceReportMutationResultDTO = Apollo.MutationResult<SignServiceReportDTO>;
export type SignServiceReportMutationOptionsDTO = Apollo.BaseMutationOptions<SignServiceReportDTO, SignServiceReportVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    