import { PageComponent } from "@aptus/frontend-core";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useInterventions, useRoutes, useServiceReports } from "domains";
import { InterventionDetails } from "pages/interventionPage/components/interventionDetails";
import { useGetIntervention } from "pages/interventionPage/models/getIntervention";
import { ExecutedWork } from "pages/interventionPage/components/executedWork";
import { ServiceReportForm } from "pages/interventionPage/components/serviceReportForm";
import { SaveServiceReport } from "pages/interventionPage/components/saveServiceReport";
import { PageFooter } from "components/pageFooter";
import { SaveServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import { Picture, ServiceReportStep } from "hooks/serviceReports/models/serviceReport";

export const InterventionCompletePage: PageComponent = () => {
	const { routes, params } = useRoutes();
	const {
		saveSchema, save, signSchema, sign, closeSchema, close, isLoading: isServiceReportLoading,
	} = useServiceReports();
	const { intervention, isLoading: isInterventionLoading } = useInterventions(useGetIntervention({ variables: { id: params.id || "" } }));

	return (
		<>
			<IconButton to={intervention.serviceReport?.step !== ServiceReportStep.Close ? `${intervention.url}/info` : routes.interventions.index} component={Link} aria-label={i18next.t("goBack")}>
				<ArrowBackIcon />
			</IconButton>

			<InterventionDetails {...intervention} isLoading={isInterventionLoading} />
			{intervention.serviceReport && (
				<ExecutedWork
					isLoading={isInterventionLoading || isServiceReportLoading}
					date={intervention.date}
					executedWork={intervention.serviceReport.executedWork}
					images={intervention.serviceReport.images.map((p: Picture) => p.url)}
				/>
			)}
			<br />
			<br />

			{intervention.isCompleted && intervention.serviceReport && (
				<ServiceReportForm
					step={intervention.serviceReport.step === ServiceReportStep.Close ? intervention.serviceReport.step : ServiceReportStep.Signature}
					serviceReport={intervention.serviceReport}
					saveSchema={saveSchema}
					signSchema={signSchema}
					closeSchema={closeSchema}
					date={intervention.date}
					save={(input) => save({ ...input, id: intervention.serviceReport?.id || "", interventionId: intervention.id } as SaveServiceReportInput)}
					sign={(input, isAutoSave) => sign({ ...input, id: intervention.serviceReport?.id || "" }, isAutoSave)}
					close={(input) => close({ ...input, id: intervention.serviceReport?.id || "" })}
				/>
			)}
			<PageFooter backgroundColor="success">
				<SaveServiceReport step={ServiceReportStep.Signature} isDisabled={isInterventionLoading} />
			</PageFooter>
		</>
	);
};
