import { UILogic } from "@aptus/frontend-core";
import { SxProps } from "@mui/material";
import dayjs from "dayjs";
import { Address } from "hooks/interventions/models/intervention";
import i18next from "i18next";
import { useState } from "react";

interface CommonProps {
	orderNumber: string;
	travelRouteId: string;
	installers: string[];
	licensePlate: string;
	changeVehicleUrl: string;
	isEmpty?: boolean;
	sx?: SxProps;
}

interface Props {
	interventionTime: Date;
	address: Address;
}

interface Result {
	isOpen: boolean;
	time: string;
	address: string;
	toggle: () => void;
}

export type TravelRouteProps = CommonProps & Props;
export type TravelRouteLayoutProps = CommonProps & Result;

export const useTravelRoute: UILogic<Props, Result> = ({ interventionTime, address: _address }) => {
	const [isOpen, setIsOpen] = useState<Result["isOpen"]>(false);
	const toggle: Result["toggle"] = () => setIsOpen(!isOpen);

	const hours: string = dayjs(interventionTime).format("HH");
	const minutes: string = dayjs(interventionTime).format("mm");

	const time: Result["time"] = `${hours}${i18next.t("travelRoute.hours")}${minutes}`;
	const address: Result["address"] = `${_address?.addressLine}, ${_address?.postalCode} ${_address?.city}`;

	return {
		isOpen,
		toggle,
		time,
		address,
	};
};
