import { UILogic } from "@aptus/frontend-core";
import { LinkTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { LinkProps as RRLinkProps, To } from "react-router-dom";

type CommonProps = DefaultComponentProps<LinkTypeMap> & RRLinkProps;

export interface LinkLayoutProps extends CommonProps {
	isInternal: boolean;
	url: string;
}

export type LinkProps = CommonProps;

type Props = Pick<LinkProps, "to">;
type Result = Omit<LinkLayoutProps, keyof CommonProps>;

export const useLink: UILogic<Props, Result> = ({ to }) => {
	const parseUrl = (url: string): HTMLAnchorElement => {
		const parser = document.createElement("a");
		parser.href = url;
		return parser;
	};

	const toUrl = (_to: To): string => (typeof _to === "string"
		? _to
		: `${_to.pathname || ""}${_to.search || ""}` || ""
	);

	const toException = to === "#" ? to : undefined;
	const toLocation = parseUrl(toUrl(to));
	const isPdf = /.+.pdf$/.test(toUrl(to));
	const isInternal: Result["isInternal"] = (window.location.host === toLocation.host) && !isPdf;

	return {
		isInternal,
		url: toException || (isInternal ? toUrl(toLocation) : toLocation.href),
	};
};
