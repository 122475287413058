import { UIComponent } from "@aptus/frontend-core";
import { Box, Skeleton, Stack } from "@mui/material";
import { Intervention } from "hooks/interventions/models/intervention";
import i18next from "i18next";
import { WithLoadingState } from "models/withLoadingState";
import { Typography } from "components/typography";

type Props = Pick<Intervention, "customer" | "interventionNumber" | "warehouse" | "orderNumber" | "reference" | "model" | "articles" | "comment">;

export const InterventionDetails: UIComponent<WithLoadingState<Props>> = ({
	isLoading, customer, interventionNumber, warehouse, orderNumber, reference, model, articles, comment,
}) => (
	<Box mx={2}>
		<Typography variant="h2" isLoading={isLoading}>{customer?.name}</Typography>
		<Typography variant="body2" isLoading={isLoading}>{customer?.address?.addressLine}</Typography>
		<Typography variant="body2" isLoading={isLoading}>{`${customer?.address?.postalCode} ${customer?.address?.city}`}</Typography>
		<br />
		<Typography variant="body1" isLoading={isLoading}>{customer?.phoneNumber}</Typography>

		<Typography variant="h2">{i18next.t("interventionDetails.title")}</Typography>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{interventionNumber}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.interventionNumber")}</Typography>
		</Stack>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{warehouse}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.warehouse")}</Typography>
		</Stack>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{reference}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.reference")}</Typography>
		</Stack>
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{orderNumber}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.orderNumber")}</Typography>
		</Stack>
		{comment && (
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{comment}</Typography>
				<Typography variant="body2">{i18next.t("interventionDetails.comment")}</Typography>
			</Stack>
		)}

		<Typography variant="h2">{i18next.t("interventionDetails.articles")}</Typography>
		<Stack flexDirection="row" justifyContent="space-between">
			<Typography variant="body1" isLoading={isLoading} skeleton={{ width: "50vw" }}>{model}</Typography>
			<Typography variant="body2">{i18next.t("interventionDetails.model")}</Typography>
		</Stack>
		<Stack flexDirection="row" justifyContent="space-between">
			{isLoading
				? <Skeleton variant="text" width="50vw" />
				: (
					<Box>
						{articles?.map(({ comment: _comment, reference: _reference }) => <Typography key={`${_reference}_${_comment}`} variant="body1">{_comment}</Typography>)}
					</Box>
				)}
			<Typography variant="body2">{i18next.t("interventionDetails.colors")}</Typography>
		</Stack>
	</Box>
);
