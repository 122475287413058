import {
	manyOf, nullable, oneOf, primaryKey,
} from "@mswjs/data";
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary";
import faker from "faker";
import {
	CustomerTypeDTO, FileDTO, InterventionArticleDTO, InterventionDTO, RemarkDTO, ServiceReportDTO, TravelRouteDTO, UserDTO,
} from "models/schema.d";

const defineEntity = <Entity>(
	generator: () => { [Key in keyof Entity]: ModelDefinitionValue },
) => generator();

const intervention = defineEntity<InterventionDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Intervention",
	travelRoute: oneOf("travelRoute"),
	mainUser: oneOf("user"),
	executionNumber: faker.datatype.number,
	interventionCode: faker.datatype.uuid,
	orderType: faker.datatype.string,
	dealerId: faker.datatype.uuid,
	dealerName: faker.company.companyName,
	articles: manyOf("article"),
	completed: faker.datatype.boolean,
	customerAddress: {
		city: faker.address.city,
		email: faker.internet.email,
		phoneNumber: faker.phone.phoneNumber,
		name: faker.name.findName,
		postalcode: faker.address.zipCode,
		street: faker.address.streetAddress,
	},
	customerType: () => faker.random.arrayElement(Object.keys(CustomerTypeDTO)),
	interventionDate: () => faker.datatype.datetime().toISOString(),
	locked: faker.datatype.boolean,
	model: faker.datatype.string,
	orderNumber: faker.datatype.number,
	reference: faker.datatype.string,
	orderText: faker.lorem.paragraph,
	executionText: faker.lorem.paragraph,
	interventionType: faker.datatype.string,
	serviceReport: oneOf("serviceReport", { nullable: true }),
	warehouseLocation: faker.address.streetAddress,
	remarks: () => faker.random.arrayElement([faker.lorem.paragraph(), "", ""]),
	workingHours: () => [],
	referenceInterventionId: faker.datatype.number,
	status: () => "planned",
}));

const file = defineEntity<FileDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "File",
	fileName: faker.system.fileName,
	url: faker.random.image,
}));

const remark = defineEntity<RemarkDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Remark",
	createdOn: faker.date.past,
	message: faker.random.words,
	user: oneOf("user"),
}));

const serviceReport = defineEntity<ServiceReportDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "ServiceReport",
	completed: faker.datatype.boolean,
	createdOn: faker.date.past,
	updatedOn: faker.date.past,
	intervention: oneOf("intervention"),
	startKm: faker.datatype.number,
	endKm: nullable(faker.datatype.number),
	vehicleIdentifier: faker.vehicle.vin,
	workingHours: nullable(() => []),
	articlesRetoured: nullable(faker.random.words),
	articlesToOrder: nullable(faker.random.words),
	executedWork: nullable(faker.random.words),
	pdfUrl: nullable(faker.internet.url),
	pictures: manyOf("file", { nullable: true }),
	remainingWork: nullable(faker.random.words),
	remainingWorkManPower: nullable(faker.datatype.number),
	remarkForCustomer: oneOf("remark", { nullable: true }),
	remarksForInternal: manyOf("remark", { nullable: true }),
	signature: oneOf("file", { nullable: true }),
}));

const user = defineEntity<UserDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "User",
	userName: faker.internet.userName,
	email: faker.internet.email,
}));

const travelRoute = defineEntity<TravelRouteDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "TravelRoute",
	startingHour: faker.date.soon,
	interventions: manyOf("intervention"),
	employees: manyOf("user"),
	truckId: faker.vehicle.vin,
	referenceTravelRouteId: faker.datatype.number,
}));

const article = defineEntity<InterventionArticleDTO>(() => ({
	reference: primaryKey(faker.datatype.uuid),
	__typename: () => "InterventionArticle",
	colliNumber: faker.datatype.number,
	description: faker.lorem.paragraph,
	remarks: faker.lorem.words,
	quantity: faker.datatype.number,
	color: () => `rgb(${faker.datatype.number(255)}, ${faker.datatype.number(255)}, ${faker.datatype.number(255)})`,
}));

export const dictionary = {
	intervention,
	file,
	remark,
	serviceReport,
	user,
	travelRoute,
	article,
};

/**
 * Use this object to define empty entities in request handlers.
 */
export const empty = {
	intervention: {},
	file: {},
	remark: {},
	user: {},
	travelRoute: {},
	article: {},
	serviceReport: {
		completed: false,
		createdOn: new Date().toISOString(),
		updatedOn: new Date().toISOString(),
		intervention: null,
		startKm: null,
		endKm: null,
		vehicleIdentifier: null,
		workingHours: null,
		articlesRetoured: null,
		articlesToOrder: null,
		executedWork: null,
		pdfUrl: null,
		pictures: null,
		remainingWork: null,
		remainingWorkManPower: null,
		remarkForCustomer: null,
		remarksForInternal: null,
		signature: null,
	},
};
