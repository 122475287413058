import { UIComponent } from "@aptus/frontend-core";
import { Typography } from "@mui/material";
import { Table } from "components/table/table";
import { TableBody } from "components/table/tableBody";
import { TableCell, TableCellProps } from "components/table/tableCell";
import { TableHead } from "components/table/tableHead";
import { Article } from "hooks/interventions/models/intervention";
import i18next from "i18next";

interface ArticleRow {
	description: {
		reference: Article["reference"],
		description: Article["description"],
	},
	amount: Article["amount"]
}

interface Props {
	articles: ArticleRow[];
}

export const toArticleRow = (article: Article): ArticleRow => ({
	description: {
		reference: article.reference,
		description: article.description,
	},
	amount: article.amount,
});

const ArticleTableCell: UIComponent<TableCellProps> = ({ row, property }) => (
	<TableCell property={property}>
		<Typography variant="body2">{row[property].reference}</Typography>
		<Typography variant="body1">{row[property].description}</Typography>
	</TableCell>
);

export const ArticlesTable: UIComponent<Props> = ({ articles }) => (articles.length > 0
	? (
		<Table rows={articles}>
			<TableHead>
				<TableCell property="description">{i18next.t("articlesTable.description")}</TableCell>
				<TableCell property="amount">{i18next.t("articlesTable.amount")}</TableCell>
			</TableHead>
			<TableBody>
				<ArticleTableCell property="description" />
				<TableCell property="amount" />
			</TableBody>
		</Table>
	)
	: null);
