import { gql } from "@apollo/client";
import {
	DeepPartial, isNotEmpty, ToAPI, ToInternal,
} from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { client } from "client";
import { ServiceReport } from "hooks/serviceReports/models/serviceReport";
import i18next from "i18next";
import {
	InterventionCustomerAddressDTO, InterventionArticleDTO, InterventionDTO, QueryDTO, ServiceReportDTO, TimeBlockDTO, UserDTO,
} from "models/schema";
import {
	Address, Customer, Article, Intervention, TimeBlock,
} from "./models/intervention";
import {
	InterventionsAPI, StartInterventionMutation, StopInterventionMutation, ToggleAllArticlesAvailabilityMutation, ToggleArticleAvailabilityMutation,
} from "./useInterventionsUseCases";

export type InterventionsAPIDTO = ApolloAPI<QueryDTO, "interventions" | "intervention">;

interface Props {
	createURL: (id: Intervention["id"]) => string;
	createMaterialListURL: (id: Intervention["id"]) => string;
	toServiceReport: (serviceReport: DeepPartial<ServiceReportDTO>) => ServiceReport;
}

interface Mapper {
	toAPI: ToAPI<InterventionsAPIDTO, InterventionsAPI>;
	toIntervention: ToInternal<DeepPartial<InterventionDTO>, Intervention>;
	toStartMutation: StartInterventionMutation;
	toStopMutation: StopInterventionMutation;
	toToggleArticleAvailabilityMutation: ToggleArticleAvailabilityMutation;
	toToggleAllArticlesAvailabilityMutation: ToggleAllArticlesAvailabilityMutation;
}

const WORKINGHOURS_INTERVENTION = gql`
	fragment Intervention on Intervention {
		__typename
		id
		workingHours {
			from
			to
		}
	}
`;

const ARTICLES_INTERVENTION = gql`
	fragment Intervention on Intervention {
		__typename
		id
		articles {
			reference
			isAvailable
		}
	}
`;

export class InterventionMapper implements Mapper {
	private createURL: Props["createURL"];

	private createMaterialListURL: Props["createMaterialListURL"];

	private toServiceReport: Props["toServiceReport"];

	constructor(props: Props) {
		this.createURL = props.createURL;
		this.createMaterialListURL = props.createMaterialListURL;
		this.toServiceReport = props.toServiceReport;
	}

	private toAddress: ToInternal<DeepPartial<InterventionCustomerAddressDTO>, Address> = (address) => ({
		city: address.city || i18next.t("domain.intervention.noCity"),
		postalCode: address.postalcode || "",
		addressLine: address.street || i18next.t("domain.intervention.noAddress"),
	});

	private toCustomer: ToInternal<DeepPartial<InterventionDTO>, Customer> = (intervention) => ({
		name: intervention.customerAddress?.name || i18next.t("domain.intervention.noCustomerName"),
		address: this.toAddress(intervention.customerAddress || {}),
		phoneNumber: intervention.customerAddress?.phoneNumber || i18next.t("domain.intervention.noCustomerPhoneNumber"),
	});

	private toArticle: ToInternal<DeepPartial<InterventionArticleDTO>, Article> = (article) => ({
		reference: article.reference || i18next.t("domain.intervention.noArticleReference"),
		colli: article.colliNumber || 0,
		comment: article.remarks || "",
		description: article.description || i18next.t("domain.intervention.noArticleDescription"),
		amount: article.quantity || 0,
		isAvailable: !!article.isAvailable,
	});

	private toTimeBlock: ToInternal<DeepPartial<TimeBlockDTO>, TimeBlock | undefined> = (workingHours) => (workingHours.from ? {
		from: new Date(workingHours.from),
		to: workingHours.to ? new Date(workingHours.to) : undefined,
	} : undefined);

	private toWorkingHours: ToInternal<DeepPartial<InterventionDTO["workingHours"]>, TimeBlock[]> = (workingHours) => workingHours
		.map(this.toTimeBlock)
		.filter(isNotEmpty);

	private toInstaller: ToInternal<DeepPartial<UserDTO>, Intervention["installers"][number]> = (employee) => (
		employee.userName || ""
	);

	public toIntervention: Mapper["toIntervention"] = (intervention) => ({
		id: intervention.id || "",
		url: this.createURL(intervention.id || ""),
		materialListUrl: this.createMaterialListURL(intervention.id || ""),
		isCompleted: !!intervention.serviceReport,
		isClosed: !!intervention.serviceReport?.completed,
		date: new Date(intervention.interventionDate),
		startingHour: new Date(intervention.travelRoute?.startingHour),
		customer: this.toCustomer(intervention),
		interventionNumber: intervention.interventionCode || i18next.t("domain.intervention.noInterventionNumber"),
		comment: intervention.remarks || "",
		reference: intervention.reference || i18next.t("domain.intervention.noReference"),
		orderNumber: intervention.orderNumber || i18next.t("domain.intervention.noOrderNumber"),
		travelRouteId: intervention.travelRoute?.referenceTravelRouteId?.toString() || i18next.t("domain.intervention.noTravelRouteId"),
		warehouse: intervention.warehouseLocation || i18next.t("domain.intervention.noWarehouse"),
		articles: (intervention.articles || []).map(this.toArticle),
		isEverythingAvailable: (intervention.articles || []).every(({ isAvailable }) => isAvailable),
		isEverythingUnavailable: (intervention.articles || []).every(({ isAvailable }) => !isAvailable),
		model: intervention.model || i18next.t("domain.intervention.noModel"),
		workingHours: intervention.serviceReport ? this.toServiceReport(intervention.serviceReport).workingHours : [],
		serviceReport: intervention.serviceReport ? this.toServiceReport(intervention.serviceReport) : undefined,
		installers: (intervention.travelRoute?.employees || []).map(this.toInstaller),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: InterventionsAPIDTO["data"]): DeepPartial<InterventionDTO>[] => {
			if (data?.interventions) return data.interventions || [];
			if (data?.intervention) return [data.intervention];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toIntervention),
		};
	};

	public toStartMutation: Mapper["toStartMutation"] = async (id) => {
		const intervention = client.cache.readFragment<InterventionDTO>({
			id: `Intervention:${id}`,
			fragment: WORKINGHOURS_INTERVENTION,
		});
		const workingHours = [
			...(intervention?.workingHours || []),
			{ from: new Date().toISOString(), to: null },
		];

		client.cache.writeFragment({
			id: `Intervention:${id}`,
			fragment: WORKINGHOURS_INTERVENTION,
			data: { id, workingHours },
		});

		return Promise.resolve(this.toWorkingHours(workingHours));
	};

	public toStopMutation: Mapper["toStopMutation"] = async (id) => {
		const intervention = client.cache.readFragment<InterventionDTO>({
			id: `Intervention:${id}`,
			fragment: WORKINGHOURS_INTERVENTION,
		});
		const workingHours = (intervention?.workingHours || []).map((hours) => {
			if (hours.to) return hours;
			return { ...hours, to: new Date().toISOString() };
		});

		client.cache.writeFragment({
			id: `Intervention:${id}`,
			fragment: WORKINGHOURS_INTERVENTION,
			data: { id, workingHours },
		});

		return Promise.resolve(this.toWorkingHours(workingHours));
	};

	public toToggleArticleAvailabilityMutation: Mapper["toToggleArticleAvailabilityMutation"] = async (input) => {
		const intervention = client.cache.readFragment<InterventionDTO>({
			id: `Intervention:${input.id}`,
			fragment: ARTICLES_INTERVENTION,
		});

		const toggleIsAvailable = (article: DeepPartial<InterventionArticleDTO>, index: number): DeepPartial<InterventionArticleDTO> => (index === input.index
			? { ...article, __typename: "InterventionArticle", isAvailable: !article.isAvailable }
			: { ...article, __typename: "InterventionArticle" });

		const articles = (intervention?.articles || []).map(toggleIsAvailable);

		client.cache.writeFragment({
			id: `Intervention:${input.id}`,
			fragment: ARTICLES_INTERVENTION,
			data: { __typename: "Intervention", id: input.id, articles },
		});

		return Promise.resolve(this.toArticle(articles[input.index]));
	};

	public toToggleAllArticlesAvailabilityMutation: Mapper["toToggleAllArticlesAvailabilityMutation"] = async (id) => {
		const intervention = client.cache.readFragment<InterventionDTO>({
			id: `Intervention:${id}`,
			fragment: ARTICLES_INTERVENTION,
		});

		const isAvailable = (article: InterventionArticleDTO) => article.isAvailable;
		const isEverythingAvailable = (intervention?.articles || []).every(isAvailable);

		const toggleIsAvailable = (article: DeepPartial<InterventionArticleDTO>): DeepPartial<InterventionArticleDTO> => ({
			...article,
			__typename: "InterventionArticle",
			isAvailable: !isEverythingAvailable,
		});

		const articles = (intervention?.articles || []).map(toggleIsAvailable);

		client.cache.writeFragment({
			id: `Intervention:${id}`,
			fragment: ARTICLES_INTERVENTION,
			data: { __typename: "Intervention", id, articles },
		});
	};
}
