import { UIComponent } from "@aptus/frontend-core";
import { Box } from "@mui/material";
import { Typography } from "components/typography";
import dayjs from "dayjs";
import i18next from "i18next";
import { WithLoadingState } from "models/withLoadingState";
import { Gallery } from "./gallery";

interface Props {
	date?: Date;
	executedWork: string;
	images: string[];
}

export const ExecutedWork: UIComponent<WithLoadingState<Props>> = ({
	isLoading, date, executedWork, images,
}) => (
	<Box mx={2}>
		<Typography variant="h2">{i18next.t("executedWork.title")}</Typography>
		<Typography variant="body1" isLoading={isLoading}>{dayjs(date).format("DD/MM/YYYY")}</Typography>
		<br />
		<Typography
			variant={executedWork ? "body1" : "body2"}
			isLoading={isLoading}
		>
			{executedWork || i18next.t("executedWork.noExecutedWork")}
		</Typography>
		<Gallery images={images || []} />
	</Box>
);
