import { PageComponent } from "@aptus/frontend-core";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { theme } from "theme";

interface Props {
	backgroundColor?: "primary" | "white";
}

export const Page: PageComponent<Props> = ({ backgroundColor = "white" }) => (
	<Box sx={{ p: 2, height: "100vh" }}>
		<Helmet>
			<meta name="theme-color" content={backgroundColor === "primary" ? theme.palette.primary.main : "white"} />
		</Helmet>
		<Outlet />
	</Box>
);
