export enum Role {
	Guest = "guest",
	Installer = "installer",
}

export interface Profile {
	role: Role;
	licensePlate: string;
	hasRole: (...roles: Role[]) => boolean;
}
