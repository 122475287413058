import { UILogic } from "@aptus/frontend-core";
import { event } from "event";
import { SetMileageInput } from "hooks/serviceReports/models/serviceReportInput";
import { TravelRoute } from "hooks/travelRoutes/models/TravelRoute";
import { FormEvent } from "react";
import {
	FieldErrors, FieldPath, useForm, UseFormRegisterReturn,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

interface Props {
	setMileageAndStartDate: (mileage: number, travelRouteId: string) => void;
	schema: HTMLValidationSchema<SetMileageInput>;
	saveTravelRoute: any,
	travelRoute: TravelRoute | undefined;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<SetMileageInput>) => UseFormRegisterReturn;
	errors: FieldErrors<SetMileageInput>;
	isValid?: boolean;
}

export type MileageFormProps = Props;
export type MileageFormLayoutProps = Result;

export const useMileageForm: UILogic<Props, Result> = ({
	setMileageAndStartDate,
	saveTravelRoute,
	schema,
	travelRoute,
}) => {
	const { handleSubmit, register, formState } = useForm<SetMileageInput>({ mode: "onChange" });

	const submitForm = async ({ mileage }: SetMileageInput) => {
		try {
			if (travelRoute && !travelRoute?.workingHour) {
				await saveTravelRoute({
					id: travelRoute.id,
					data: {
						workingHour: {
							start: new Date(),
							startKm: parseInt(mileage.toString(), 10),
						},
					},
				});
			}
			setMileageAndStartDate(mileage, travelRoute?.id ?? "");
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		submit: handleSubmit(submitForm),
		register: (field) => register(field, toFormHookSchema(schema)[field]),
		errors: formState.errors,
		isValid: formState.isValid,
	};
};
