import { UILogic } from "@aptus/frontend-core";
import {
	ChangeEvent, RefCallback, RefObject, useEffect, useRef, useState,
} from "react";
import ReactSignatureCanvas from "react-signature-canvas";

interface Common {
	innerRef: RefCallback<HTMLInputElement>;
	label?: string;
	error?: boolean;
	helperText?: string;
}

interface Props {
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface Result {
	canvasRef: RefObject<ReactSignatureCanvas>;
	parentRef: RefObject<HTMLDivElement>;
	value: string;
	width: number;
	isTestEnvironment: boolean;
	setValue: (value: string) => void;
}

export type SignatureInputProps = Common & Props;
export type SignatureInputLayoutProps = Common & Result;

export const useSignatureInput: UILogic<Props, Result> = ({ onChange }) => {
	const [value, setValue] = useState<Result["value"]>("");
	const [width, setWidth] = useState<Result["width"]>(0);
	const canvasRef = useRef<ReactSignatureCanvas>(null);
	const parentRef = useRef<HTMLDivElement>(null);
	const isTestEnvironment: Result["isTestEnvironment"] = process.env.NODE_ENV === "test";

	const onResize = (): void => {
		setWidth(parentRef.current?.clientWidth || 0);
	};

	useEffect(() => {
		window.addEventListener("resize", onResize);
		onResize();

		return () => window.removeEventListener("resize", onResize);
	}, []);

	useEffect(() => {
		if (value) {
			const event: ChangeEvent<HTMLInputElement> = new Event("change") as any;
			onChange?.({ ...event, target: { ...event.target, value } });
		}
	}, [value]);

	return {
		canvasRef,
		parentRef,
		value,
		width,
		isTestEnvironment,
		setValue,
		setWidth,
	};
};
