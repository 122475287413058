import { PageComponent } from "@aptus/frontend-core";
import {
	useProfile,
	useRoutes,
	useServiceReports,
	useTravelRoute,
} from "domains";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import i18next from "i18next";
import { event } from "event";
import { Link } from "components/link/link";
import { IconButton } from "@mui/material";
import { useGetProfile } from "hooks/profile/useGetProfile";
import { MileageForm } from "./components/mileageForm/mileageForm";
import { useGetTravelRoutes } from "./models/getTravelRoutes";

export const MileagePage: PageComponent = () => {
	const { setMileageAndStartDate, setMileageSchema } = useServiceReports();
	const { routes } = useRoutes();

	const day = new Date();

	const profileResult = useProfile(useGetProfile());

	const { travelRoute } = useTravelRoute(useGetTravelRoutes({
		variables: {
			filter: {
				startingHour: {
					gte: dayjs(day).startOf("day"),
					lte: dayjs(day).endOf("day"),
				},
				employee: {
					containsSome: [profileResult.profile?.user?.id ?? ""],
				},
			},
			paging: {
				limit: 1,
				offset: 0,
			},
		},
	}));

	const { save } = useTravelRoute();

	if (travelRoute?.workingHour?.startKm) {
		localStorage.setItem("mileage", travelRoute.workingHour.startKm.toString());
		event.emit("mileageAndStartDateSet");
		return <div />;
	}

	return (
		<>
			<IconButton
				to={routes.licensePlate.scanner}
				component={Link}
				aria-label={i18next.t("goBack")}
				sx={{
					zIndex: 10, position: "absolute", left: "1rem", top: "1rem",
				}}
			>
				<ArrowBackIcon />
			</IconButton>

			<MileageForm
				setMileageAndStartDate={setMileageAndStartDate}
				schema={setMileageSchema}
				saveTravelRoute={save}
				travelRoute={travelRoute}
			/>
		</>
	);
};
