import { Link as RRLink } from "react-router-dom";
import { Link as MUILink } from "@mui/material";
import { UIComponent, UILayout } from "@aptus/frontend-core";
import { useLink, LinkLayoutProps, LinkProps } from "./useLink";

const Layout: UILayout<LinkLayoutProps> = ({
	isInternal, url, children, ...rest
}) => (isInternal
	? <MUILink component={RRLink} {...rest} to={url}>{children}</MUILink>
	: <MUILink component="a" {...rest} href={url}>{children}</MUILink>);

export const Link: UIComponent<LinkProps> = (props) => (
	<Layout {...props} {...useLink(props)} />
);
