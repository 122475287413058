import { UILogic } from "@aptus/frontend-core";
import { Intervention } from "hooks/interventions/models/intervention";

type WithTitle<Type> = Type & { title?: Date };

interface Props {
	interventions: Intervention[];
	/** Render dates between interventions */
	showDates?: boolean;
}

interface Result {
	interventions: WithTitle<Intervention>[];
	showCompletionStatus?: boolean;
}

export type InterventionsListProps = Props;
export type InterventionsListLayoutProps = Result;

export const useInterventionsList: UILogic<Props, Result> = ({ interventions, showDates }) => {
	const toInterventionWithTitle = (intervention: Intervention, index: number): WithTitle<Intervention> => ({
		...intervention,
		title: (index === 0 || interventions[index - 1].date.getDate() !== intervention.date.getDate()) && showDates ? intervention.date : undefined,
	});

	return {
		interventions: interventions.map(toInterventionWithTitle),
		showCompletionStatus: showDates,
	};
};
