import {
	DeepPartial, IOMap, ToAPI, ToInternal,
} from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { TimeBlock } from "hooks/interventions/models/intervention";
import {
	QueryDTO, ServiceReportDTO, TimeBlockDTO, TimeBlockInputDTO,
} from "models/schema";
import { GetInterventionsDocumentDTO } from "pages/interventionsPage/models/getInterventions";
import { CloseServiceReportMutationFnDTO } from "./models/closeServiceReport";
import { CreateServiceReportMutationFnDTO } from "./models/createServiceReport";
import {
	ServiceReport, Mileage, Articles, RemainingWork, ServiceReportStep,
} from "./models/serviceReport";
import { SignServiceReportMutationFnDTO } from "./models/signServiceReport";
import { UpdateServiceReportMutationFnDTO } from "./models/updateServiceReport";
import {
	CloseServiceReportMutation, CreateServiceReportMutation, ServiceReportsAPI, SignServiceReportMutation, UpdateServiceReportMutation,
} from "./useServiceReportsUseCases";

export type ServiceReportsAPIDTO = ApolloAPI<QueryDTO, "serviceReport" | "serviceReports">;

interface Mapper {
	toAPI: ToAPI<ServiceReportsAPIDTO, ServiceReportsAPI>;
	toServiceReport: ToInternal<DeepPartial<ServiceReportDTO>, ServiceReport>;
	toCreateMutation: ToInternal<CreateServiceReportMutationFnDTO, CreateServiceReportMutation>;
	toUpdateMutation: ToInternal<UpdateServiceReportMutationFnDTO, UpdateServiceReportMutation>;
	toSignMutation: ToInternal<SignServiceReportMutationFnDTO, SignServiceReportMutation>;
	toCloseMutation: ToInternal<CloseServiceReportMutationFnDTO, CloseServiceReportMutation>;
}

export class ServiceReportMapper implements Mapper {
	private toServiceReportStep = (serviceReport: DeepPartial<ServiceReportDTO>): ServiceReportStep => {
		const isSigned: boolean = !!serviceReport.signature;
		const isCompleted: boolean = !!serviceReport.completed;

		if (isSigned) return ServiceReportStep.Close;
		if (isCompleted) return ServiceReportStep.Closed;
		return ServiceReportStep.Initial;
	};

	private toTimeBlock = (timeBlock: DeepPartial<TimeBlockDTO>): TimeBlock => ({
		from: new Date(timeBlock.start),
		to: timeBlock.end ? new Date(timeBlock.end) : undefined,
	});

	private toTimeBlockInput = (timeBlock: TimeBlock): TimeBlockInputDTO => ({
		start: timeBlock.from.toISOString(),
		end: timeBlock.to ? timeBlock.to.toISOString() : undefined,
	});

	private toMileage = (serviceReport: DeepPartial<ServiceReportDTO>): Mileage => ({
		start: serviceReport.startKm || Number(localStorage.getItem("mileage")) || 0,
		end: serviceReport.endKm || serviceReport.startKm || 0,
	});

	private toArticles = (serviceReport: DeepPartial<ServiceReportDTO>): Articles => ({
		toOrder: serviceReport.articlesToOrder || "",
		retoured: serviceReport.articlesRetoured || "",
	});

	private toRemainingWork = (serviceReport: DeepPartial<ServiceReportDTO>): RemainingWork => ({
		description: serviceReport.remainingWork || "",
		manPower: serviceReport.remainingWorkManPower || 0,
	});

	public toServiceReport: Mapper["toServiceReport"] = (serviceReport) => ({
		id: serviceReport.id || "",
		images: (serviceReport.pictures || []).map((p) => ({ url: p.url ?? "", id: p.id ?? "", name: p.fileName ?? "" })) /* .map((p) => p.url || "") */,
		customerEmail: serviceReport.customerEmail ?? "",
		step: this.toServiceReportStep(serviceReport),
		workingHours: (serviceReport.workingHours || []).map(this.toTimeBlock),
		mileage: this.toMileage(serviceReport),
		articles: this.toArticles(serviceReport),
		executedWork: serviceReport.executedWork || "",
		remainingWork: this.toRemainingWork(serviceReport),
		remarksForCustomer: serviceReport.remarkForCustomer?.message || "",
		remarkFromCustomer: serviceReport.remarkFromCustomer?.message ?? "",
		remarkForInternalTeam: serviceReport.remarksForInternal?.[0]?.message || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ServiceReportsAPIDTO["data"]): DeepPartial<ServiceReportDTO>[] => {
			if (data?.serviceReports) return data.serviceReports;
			if (data?.serviceReport) return [data.serviceReport];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toServiceReport),
		};
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateServiceReportMutationFnDTO, CreateServiceReportMutation> = {
			toInput: (input) => ({
				variables: {
					interventionId: input.interventionId,
					licensePlate: localStorage.getItem("licensePlate") || "",
					workingHours: input.workingHours.map(this.toTimeBlockInput),
					startMileage: input.startMileage || 0,
				},
			}),
			toOutput: (output) => (output.data?.createOneServiceReport
				? this.toServiceReport(output.data?.createOneServiceReport)
				: undefined),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateServiceReportMutationFnDTO, UpdateServiceReportMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					articlesRetoured: input.articlesRetoured,
					articlesToOrder: input.articlesToOrder,
					executedWork: input.executedWork,
					remainingWork: input.remainingWork,
					commentForCustomer: {
						message: input.commentForCustomer,
					},
					endMileage: Number(input.endMileage),
					startMileage: Number(input.startMileage),
					remainingWorkManPower: Number(input.remainingWorkManPower),
					workingHours: input.workingHours.map(this.toTimeBlockInput),
					pictures: input.attachments.map(({ id }) => id),
				},
			}),
			toOutput: (output) => (output.data?.updateOneServiceReport
				? this.toServiceReport(output.data?.updateOneServiceReport)
				: undefined),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toSignMutation: Mapper["toSignMutation"] = (mutation) => {
		const map: IOMap<SignServiceReportMutationFnDTO, SignServiceReportMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					email: input.email,
					remarks: {
						message: input.remarks,
					},
					signature: input.signature?.id,
				},
			}),
			toOutput: (output) => (output.data?.updateOneServiceReport
				? this.toServiceReport(output.data?.updateOneServiceReport)
				: undefined),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCloseMutation: Mapper["toCloseMutation"] = (mutation) => {
		const map: IOMap<CloseServiceReportMutationFnDTO, CloseServiceReportMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					...input.comment ? { comment: [{ message: input.comment }] } : {},
				},
				/* Refetches the 2 queries that could/should be affected by this mutation */
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GetInterventionsDocumentDTO,
						variables: {
							startOfDay: input.startOfDay,
							endOfDay: input.endOfDay,
							isCompleted: true,
						},
					},
					{
						query: GetInterventionsDocumentDTO,
						variables: {
							startOfDay: input.startOfDay,
							endOfDay: input.endOfDay,
							isCompleted: false,
						},
					},
				],
			}),
			toOutput: (output) => (output.data?.updateOneServiceReport
				? this.toServiceReport(output.data?.updateOneServiceReport)
				: undefined),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
