import { UseCases } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { nextDay, previousDay } from "utils/daySelection";

interface Result {
	day: Date;
	goToNextDay: () => void;
	goToPreviousDay: () => void;
}

export const useInterventionFilters: UseCases<undefined, Result> = () => {
	const { getQuery, setQuery } = useRoutes();

	const day = getQuery("date") ? new Date(getQuery("date")) : new Date();

	const goToNextDay = () => {
		setQuery({ date: nextDay(day).toISOString() });
	};

	const goToPreviousDay = () => {
		setQuery({ date: previousDay(day).toISOString() });
	};

	return {
		day,
		goToNextDay,
		goToPreviousDay,
	};
};
