/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadAttachmentVariablesDTO = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;


export type UploadAttachmentDTO = { __typename?: 'Mutation', createOneFile: { __typename?: 'File', id: string } };


export const UploadAttachmentDocumentDTO = gql`
    mutation uploadAttachment($file: Upload!) {
  createOneFile(data: $file) {
    id
  }
}
    `;
export type UploadAttachmentMutationFnDTO = Apollo.MutationFunction<UploadAttachmentDTO, UploadAttachmentVariablesDTO>;

/**
 * __useUploadAttachment__
 *
 * To run a mutation, you first call `useUploadAttachment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachment, { data, loading, error }] = useUploadAttachment({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAttachment(baseOptions?: Apollo.MutationHookOptions<UploadAttachmentDTO, UploadAttachmentVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAttachmentDTO, UploadAttachmentVariablesDTO>(UploadAttachmentDocumentDTO, options);
      }
export type UploadAttachmentHookResult = ReturnType<typeof useUploadAttachment>;
export type UploadAttachmentMutationResultDTO = Apollo.MutationResult<UploadAttachmentDTO>;
export type UploadAttachmentMutationOptionsDTO = Apollo.BaseMutationOptions<UploadAttachmentDTO, UploadAttachmentVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    