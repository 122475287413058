import { IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, TravelRouteDTO } from "models/schema";
import { DeepPartial } from "react-hook-form";
import { TravelRoute, WorkingHour } from "./models/TravelRoute";
import { TravelRouteAPI, UpdateTravelRouteMutation } from "./useTravelRouteUseCases";
import { UpdateTravelRouteMutationFnDTO } from "./models/updateTravelRoute";

export type TravelRouteAPIDTO = ApolloAPI<QueryDTO, "travelRoutes" | "travelRoute">;

interface Mapper {
	toAPI: ToAPI<TravelRouteAPIDTO, TravelRouteAPI>;
	toTravelRoute: ToInternal<DeepPartial<TravelRouteDTO>, TravelRoute>;
	toUpdateMutation: ToInternal<UpdateTravelRouteMutationFnDTO, UpdateTravelRouteMutation>;
}

export class TravelRouteMapper implements Mapper {
	private toWorkingHour = (travelRoute: DeepPartial<TravelRouteDTO>): WorkingHour | undefined => {
		if (travelRoute.workingHour) {
			return ({
				start: new Date(travelRoute.workingHour.start),
				startKm: travelRoute.workingHour.startKm,
				end: new Date(travelRoute.workingHour.end),
				endKm: travelRoute.workingHour.endKm ?? 0,
			});
		}
		return undefined;
	};

	public toTravelRoute: Mapper["toTravelRoute"] = (travelRoute) => ({
		id: travelRoute.id ?? "",
		startingHour: travelRoute.startingHour,
		workingHour: this.toWorkingHour(travelRoute),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: TravelRouteAPIDTO["data"]): DeepPartial<TravelRouteDTO>[] => {
			if (data?.travelRoutes) return data.travelRoutes;
			if (data?.travelRoute) return [data.travelRoute];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading ?? false,
			data: extractDTOs(api?.data).map(this.toTravelRoute),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateTravelRouteMutationFnDTO, UpdateTravelRouteMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						workingHour: {
							start: input.data.workingHour.start,
							startKm: input.data.workingHour.startKm ?? 0,
							end: input.data.workingHour.end,
							endKm: input.data.workingHour.endKm ?? 0,
						},
					},
				},
			}),
			toOutput: (output) => (output.data.updateTravelRoute
				? this.toTravelRoute(output.data.updateTravelRoute)
				: undefined),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
