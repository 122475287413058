import { UIComponent, UILayout } from "@aptus/frontend-core";
import {
	Button, debounce, Stack, TextField,
} from "@mui/material";
import i18next from "i18next";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { FormEvent } from "react";
import { event } from "event";
import { Picture } from "hooks/serviceReports/models/serviceReport";
import { useRoutes } from "domains";
import { Gallery } from "../gallery";
import { TimeBlockFormFields } from "./timeBlockFormFields";
import { InitialServiceReportFormLayoutProps, InitialServiceReportFormProps, useInitialServiceReportForm } from "./useInitialServiceReportForm";

const Layout: UILayout<InitialServiceReportFormLayoutProps> = ({
	submit, register, errors, fields, control, images, deleteImage, onAddImage,
}) => {
	const { params } = useRoutes();
	const onInputChange = debounce(submit, 1000);

	const submitForm = (input: FormEvent<HTMLFormElement>) => {
		submit(input);
		event.emit("completeIntervention", params.id ?? "");
	};

	return (
		<form onInput={onInputChange} onSubmit={submitForm} id="service-report-form">
			<Stack>
				{fields.map((field, index) => (
					<TimeBlockFormFields
						key={field.id}
						index={index}
						control={control}
						errors={errors?.workingHours?.[index] || { from: undefined, to: undefined }}
					/>
				))}
				<TextField
					{...register("endMileage")}
					label={i18next.t("initialServiceReportForm.endMileage")}
					type="number"
					error={!!errors.endMileage}
					helperText={errors.endMileage?.message}
					sx={{ width: 1 }}
				/>
				<TextField
					{...register("executedWork")}
					label={i18next.t("initialServiceReportForm.executedWork")}
					multiline
					rows={4}
					type="text"
					error={!!errors.executedWork}
					helperText={errors.executedWork?.message}
				/>
				<TextField
					{...register("remainingWork")}
					label={i18next.t("initialServiceReportForm.remainingWork")}
					multiline
					rows={4}
					type="text"
					error={!!errors.remainingWork}
					helperText={errors.remainingWork?.message}
				/>
				<TextField
					{...register("remainingWorkManPower")}
					label={i18next.t("initialServiceReportForm.remainingWorkManPower")}
					type="number"
					error={!!errors.remainingWorkManPower}
					helperText={errors.remainingWorkManPower?.message}
				/>
				<TextField
					{...register("articlesToOrder")}
					label={i18next.t("initialServiceReportForm.articlesToOrder")}
					multiline
					rows={4}
					type="text"
					error={!!errors.articlesToOrder}
					helperText={errors.articlesToOrder?.message}
				/>
				<TextField
					{...register("articlesRetoured")}
					label={i18next.t("initialServiceReportForm.articlesRetoured")}
					multiline
					rows={4}
					type="text"
					error={!!errors.articlesRetoured}
					helperText={errors.articlesRetoured?.message}
				/>
				<TextField
					{...register("commentForCustomer")}
					label={i18next.t("initialServiceReportForm.commentForCustomer")}
					multiline
					rows={4}
					type="text"
					error={!!errors.commentForCustomer}
					helperText={errors.commentForCustomer?.message}
				/>
				<label htmlFor="images">
					<input
						type="file"
						accept="image/*"
						multiple
						onChange={onAddImage}
						style={{ display: "none" }}
						id="images"
					/>
					<Button component="span" fullWidth startIcon={<CameraAltIcon />}>
						{i18next.t("initialServiceReportForm.openCamera")}
					</Button>
				</label>
			</Stack>
			<Gallery images={images.map((p: Picture) => p.url)} deleteImage={deleteImage} />
		</form>
	);
};

export const InitialServiceReportForm: UIComponent<InitialServiceReportFormProps> = (props) => (
	<Layout {...props} {...useInitialServiceReportForm(props)} />
);
