import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Stack, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import i18next from "i18next";
import { DayPickerLayoutProps, DayPickerProps, useDayPicker } from "./useDayPicker";

const Layout: UILayout<DayPickerLayoutProps> = ({
	currentDay, sx, goToPreviousDay, goToNextDay,
}) => (
	<Stack direction="row" alignItems="center" sx={sx}>
		<IconButton onClick={goToPreviousDay} aria-label={i18next.t("dayPicker.previousDay")}>
			<ChevronLeftIcon />
		</IconButton>
		<Typography variant="body1">{currentDay}</Typography>
		<IconButton onClick={goToNextDay} aria-label={i18next.t("dayPicker.nextDay")}>
			<ChevronRightIcon />
		</IconButton>
	</Stack>
);

export const DayPicker: UIComponent<DayPickerProps> = (props) => (
	<Layout {...props} {...useDayPicker(props)} />
);
