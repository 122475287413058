import { UIComponent } from "@aptus/frontend-core";
import { Button, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography } from "components/typography";
import i18next from "i18next";
import { ServiceReportStep } from "hooks/serviceReports/models/serviceReport";

interface Props {
	isDisabled?: boolean;
	step: ServiceReportStep;
}

export const SaveServiceReport: UIComponent<Props> = ({ isDisabled, step }) => {
	const message = step === ServiceReportStep.Initial ? i18next.t("saveServiceReport.next") : i18next.t("saveServiceReport.save");
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Stack direction="row" spacing={2}>
				<Typography color="white"><CheckCircleOutlineIcon /></Typography>
				<Typography color="white" variant="body1">{i18next.t("saveServiceReport.serviceReport")}</Typography>
			</Stack>
			<Button type="submit" form="service-report-form" disabled={isDisabled}>{message}</Button>
		</Stack>
	);
};
