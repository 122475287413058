import { useEffect, useRef } from "react";

export type UseInterval = (
	callback: () => void,
	delay: number,
) => void;

export const useInterval: UseInterval = (callback, delay) => {
	const savedCallback = useRef<() => void>(callback);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		let interval: NodeJS.Timer;

		const tick = () => {
			savedCallback.current();
		};

		if (delay) {
			interval = setInterval(tick, delay);
		}

		return () => clearInterval(interval);
	}, [delay]);
};
