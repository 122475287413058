import { PageComponent } from "@aptus/frontend-core";
import {
	Divider, IconButton, Stack, Typography,
} from "@mui/material";
import i18next from "i18next";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useInterventions, useProfile, useRoutes } from "domains";
import InventoryIcon from "@mui/icons-material/Inventory";
import dayjs from "dayjs";
import { useGetProfile } from "hooks/profile/useGetProfile";
import { SkeletonLoader } from "components/skeletonLoader";
import { useInterventionFilters } from "hooks/interventions/useInterventionFilters";
import { useGetInterventionsArticles } from "./models/getInterventionsArticles";
import { InterventionListItem, toInterventionListItemProps } from "./components/interventionListItem";

export const MaterialListPage: PageComponent = () => {
	const { routes } = useRoutes();
	const { day } = useInterventionFilters();
	const profileResult = useProfile(useGetProfile());
	const { interventions, isLoading, toggleAllArticlesAvailability } = useInterventions(useGetInterventionsArticles({
		variables: {
			startOfDay: dayjs(day).startOf("day"),
			endOfDay: dayjs(day).endOf("day"),
			installer: profileResult.profile?.user?.id ?? "",
		},
	}));

	return (
		<>
			<IconButton to={routes.interventions.index} component={Link} aria-label={i18next.t("goBack")}>
				<ArrowBackIcon />
			</IconButton>

			<Stack direction="row" spacing={2} padding={1}>
				<InventoryIcon />
				<Typography variant="h1">{i18next.t("materiallist")}</Typography>
			</Stack>

			<SkeletonLoader
				isLoading={isLoading}
				amount={5}
				skeleton={(
					<>
						<InterventionListItem isLoading />
						<Divider />
					</>
				)}
			>
				{interventions.map((intervention) => (
					<>
						<InterventionListItem
							{...toInterventionListItemProps(intervention)}
							toggleAvailability={() => toggleAllArticlesAvailability(intervention.id)}
						/>
						<Divider />
					</>
				))}
			</SkeletonLoader>
		</>
	);
};
