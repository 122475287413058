import { API, Mutation, UseCases } from "@aptus/frontend-core";
import { useState } from "react";
import { event } from "event";
import i18next from "i18next";
import { TravelRoute } from "./models/TravelRoute";

export type TravelRouteAPI = API<TravelRoute[]>;

// TODO: to models
interface UpdateTravelRouteInput {
	id: string;
	data: {
		workingHour: {
			end?: Date;
			endKm?: number;
			start?: Date;
			startKm?: number;
		}
	}
}

export type UpdateTravelRouteMutation = Mutation<UpdateTravelRouteInput, TravelRoute>;

interface Props {
	api: TravelRouteAPI;
	updateMutation: UpdateTravelRouteMutation;
}

interface Result {
	travelRoute: TravelRoute;
	isLoading: boolean;
	error?: Error;
	save: (input: UpdateTravelRouteInput) => Promise<void>;
}

export const useTravelRoutesUseCases: UseCases<Props, Result> = ({ api, updateMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(api.isLoading);

	const save: Result["save"] = async (input) => {
		try {
			setIsLoading(true);
			await updateMutation({ ...input });
			event.emit("mutationSucceeded", i18next.t("mileageForm.success"));
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return {
		travelRoute: api.data[0],
		isLoading,
		error: api.error,
		save,
	};
};
