/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloseServiceReportVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  comment?: Types.InputMaybe<Array<Types.RemarkInputDTO> | Types.RemarkInputDTO>;
}>;


export type CloseServiceReportDTO = { __typename?: 'Mutation', updateOneServiceReport: { __typename?: 'ServiceReport', id: string } };


export const CloseServiceReportDocumentDTO = gql`
    mutation closeServiceReport($id: String!, $comment: [RemarkInput!]) {
  updateOneServiceReport(
    id: $id
    data: {remarksForInternal: $comment, completed: true}
  ) {
    id
  }
}
    `;
export type CloseServiceReportMutationFnDTO = Apollo.MutationFunction<CloseServiceReportDTO, CloseServiceReportVariablesDTO>;

/**
 * __useCloseServiceReport__
 *
 * To run a mutation, you first call `useCloseServiceReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseServiceReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeServiceReport, { data, loading, error }] = useCloseServiceReport({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCloseServiceReport(baseOptions?: Apollo.MutationHookOptions<CloseServiceReportDTO, CloseServiceReportVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseServiceReportDTO, CloseServiceReportVariablesDTO>(CloseServiceReportDocumentDTO, options);
      }
export type CloseServiceReportHookResult = ReturnType<typeof useCloseServiceReport>;
export type CloseServiceReportMutationResultDTO = Apollo.MutationResult<CloseServiceReportDTO>;
export type CloseServiceReportMutationOptionsDTO = Apollo.BaseMutationOptions<CloseServiceReportDTO, CloseServiceReportVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    