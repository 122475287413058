/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** DateTime custom scalar type */
  DateTime: any;
  JSON: any;
  Time: any;
  Upload: any;
};

export type BasicDateTimeFilterDTO = {
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export type BasicStringArrayFilterDTO = {
  containsAll?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  containsSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type BasicStringFilterDTO = {
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type BooleanFilterDTO = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
};

export enum CustomerTypeDTO {
  PrivateDTO = 'private',
  ResellerDTO = 'reseller'
}

export type DateTimeFilterDTO = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type FileDTO = {
  __typename?: 'File';
  fileName: Scalars['String'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type IntFilterDTO = {
  contains?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type InterventionDTO = {
  __typename?: 'Intervention';
  articles: Array<InterventionArticleDTO>;
  /** Indication that service report exists and that the service report is finished. */
  completed: Scalars['Boolean'];
  customerAddress: InterventionCustomerAddressDTO;
  customerType: CustomerTypeDTO;
  dealerId: Scalars['String'];
  dealerName: Scalars['String'];
  /** Order of the interventions for a travel route. */
  executionNumber: Scalars['Int'];
  executionText: Scalars['String'];
  id: Scalars['String'];
  interventionCode: Scalars['String'];
  interventionDate: Scalars['DateTime'];
  interventionType: Scalars['String'];
  /** Indication which technician can edit. */
  mainUser: UserDTO;
  model: Scalars['String'];
  orderNumber: Scalars['String'];
  orderText: Scalars['String'];
  orderType: Scalars['String'];
  reference: Scalars['String'];
  referenceInterventionId: Scalars['Int'];
  remarks: Scalars['String'];
  serviceReport?: Maybe<ServiceReportDTO>;
  status: InterventionStatusDTO;
  travelRoute: TravelRouteDTO;
  warehouseLocation: Scalars['String'];
  workingHours: Array<TimeBlockDTO>;
};

export type InterventionArticleDTO = {
  __typename?: 'InterventionArticle';
  colliNumber: Scalars['Int'];
  color: Scalars['String'];
  description: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Int'];
  reference: Scalars['String'];
  remarks: Scalars['String'];
};

export type InterventionCustomerAddressDTO = {
  __typename?: 'InterventionCustomerAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type InterventionCustomerAddressFilterDTO = {
  AND?: InputMaybe<Array<InterventionCustomerAddressFilterDTO>>;
  OR?: InputMaybe<Array<InterventionCustomerAddressFilterDTO>>;
  city?: InputMaybe<StringFilterDTO>;
  country?: InputMaybe<StringFilterDTO>;
  email?: InputMaybe<StringFilterDTO>;
  language?: InputMaybe<StringFilterDTO>;
  name?: InputMaybe<StringFilterDTO>;
  phoneNumber?: InputMaybe<StringFilterDTO>;
  postalcode?: InputMaybe<StringFilterDTO>;
  street?: InputMaybe<StringFilterDTO>;
};

export type InterventionFilterDTO = {
  AND?: InputMaybe<Array<InterventionFilterDTO>>;
  OR?: InputMaybe<Array<InterventionFilterDTO>>;
  completed?: InputMaybe<BooleanFilterDTO>;
  customerAddress?: InputMaybe<InterventionCustomerAddressFilterDTO>;
  dealerName?: InputMaybe<StringFilterDTO>;
  executionNumber?: InputMaybe<IntFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  interventionCode?: InputMaybe<StringFilterDTO>;
  interventionDate?: InputMaybe<DateTimeFilterDTO>;
  orderNumber?: InputMaybe<StringFilterDTO>;
  referenceInterventionId?: InputMaybe<IntFilterDTO>;
  status?: InputMaybe<InterventionStatusFilterDTO>;
  travelRoute?: InputMaybe<TravelRouteFilterDTO>;
};

export enum InterventionStatusDTO {
  CompletedDTO = 'completed',
  CompletedWithRemainingWorkDTO = 'completedWithRemainingWork',
  InProgressDTO = 'inProgress',
  PlannedDTO = 'planned'
}

export type InterventionStatusFilterDTO = {
  equals?: InputMaybe<InterventionStatusDTO>;
  in?: InputMaybe<Array<InputMaybe<InterventionStatusDTO>>>;
  not?: InputMaybe<InterventionStatusDTO>;
  notIn?: InputMaybe<Array<InputMaybe<InterventionStatusDTO>>>;
};

export type LoginInputDTO = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginResultDTO = {
  __typename?: 'LoginResult';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MutationDTO = {
  __typename?: 'Mutation';
  createOneFile: FileDTO;
  createOneServiceReport: ServiceReportDTO;
  login: LoginResultDTO;
  refreshToken: LoginResultDTO;
  updateOneServiceReport: ServiceReportDTO;
  updateOneTravelRoute: TravelRouteDTO;
};


export type MutationCreateOneFileArgsDTO = {
  data?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateOneServiceReportArgsDTO = {
  data: ServiceReportCreateInputDTO;
};


export type MutationLoginArgsDTO = {
  data: LoginInputDTO;
};


export type MutationRefreshTokenArgsDTO = {
  data: RefreshTokenInputDTO;
};


export type MutationUpdateOneServiceReportArgsDTO = {
  data: ServiceReportUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneTravelRouteArgsDTO = {
  data: TravelRouteUpdateInputDTO;
  id: Scalars['String'];
};

export type PagingDTO = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ProfileDTO = {
  __typename?: 'Profile';
  user: UserDTO;
};

export type QueryDTO = {
  __typename?: 'Query';
  intervention: InterventionDTO;
  interventions: Array<InterventionDTO>;
  interventionsCount?: Maybe<Scalars['Int']>;
  profile: ProfileDTO;
  serviceReport: ServiceReportDTO;
  serviceReports: Array<ServiceReportDTO>;
  serviceReportsCount?: Maybe<Scalars['Int']>;
  travelRoute: TravelRouteDTO;
  travelRoutes: Array<TravelRouteDTO>;
  travelRoutesCount?: Maybe<Scalars['Int']>;
  user: UserDTO;
  users: Array<UserDTO>;
  usersCount?: Maybe<Scalars['Int']>;
};


export type QueryInterventionArgsDTO = {
  id: Scalars['String'];
};


export type QueryInterventionsArgsDTO = {
  filter?: InputMaybe<InterventionFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryInterventionsCountArgsDTO = {
  filter?: InputMaybe<InterventionFilterDTO>;
};


export type QueryServiceReportArgsDTO = {
  id: Scalars['String'];
};


export type QueryServiceReportsArgsDTO = {
  filter?: InputMaybe<ServiceReportFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryServiceReportsCountArgsDTO = {
  filter?: InputMaybe<ServiceReportFilterDTO>;
};


export type QueryTravelRouteArgsDTO = {
  id: Scalars['String'];
};


export type QueryTravelRoutesArgsDTO = {
  filter?: InputMaybe<TravelRouteFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryTravelRoutesCountArgsDTO = {
  filter?: InputMaybe<TravelRouteFilterDTO>;
};


export type QueryUserArgsDTO = {
  id: Scalars['String'];
};


export type QueryUsersArgsDTO = {
  filter?: InputMaybe<UserFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUsersCountArgsDTO = {
  filter?: InputMaybe<UserFilterDTO>;
};

export type RefreshTokenInputDTO = {
  refreshToken: Scalars['String'];
};

export type RemarkDTO = {
  __typename?: 'Remark';
  createdOn: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  user: UserDTO;
};

export type RemarkInputDTO = {
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ServiceReportDTO = {
  __typename?: 'ServiceReport';
  articlesRetoured?: Maybe<Scalars['String']>;
  articlesToOrder?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  customerEmail?: Maybe<Scalars['String']>;
  endKm?: Maybe<Scalars['Int']>;
  executedWork?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  intervention: InterventionDTO;
  pdfUrl?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<FileDTO>>;
  remainingWork?: Maybe<Scalars['String']>;
  remainingWorkManPower?: Maybe<Scalars['Int']>;
  remarkForCustomer?: Maybe<RemarkDTO>;
  remarkFromCustomer?: Maybe<RemarkDTO>;
  remarksForInternal?: Maybe<Array<RemarkDTO>>;
  signature?: Maybe<FileDTO>;
  startKm: Scalars['Int'];
  updatedOn: Scalars['DateTime'];
  vehicleIdentifier: Scalars['String'];
  workingHours?: Maybe<Array<TimeBlockDTO>>;
};

export type ServiceReportCreateInputDTO = {
  intervention: Scalars['String'];
  startKm: Scalars['Int'];
  vehicleIdentifier: Scalars['String'];
  workingHours: Array<TimeBlockInputDTO>;
};

export type ServiceReportFilterDTO = {
  AND?: InputMaybe<Array<ServiceReportFilterDTO>>;
  OR?: InputMaybe<Array<ServiceReportFilterDTO>>;
  completed?: InputMaybe<BooleanFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  intervention?: InputMaybe<InterventionFilterDTO>;
  vehicleIdentifier?: InputMaybe<StringFilterDTO>;
};

export type ServiceReportUpdateInputDTO = {
  articlesRetoured?: InputMaybe<Scalars['String']>;
  articlesToOrder?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endKm?: InputMaybe<Scalars['Int']>;
  executedWork?: InputMaybe<Scalars['String']>;
  /** List of ID's of type "File" */
  pictures?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  remainingWork?: InputMaybe<Scalars['String']>;
  remainingWorkManPower?: InputMaybe<Scalars['Int']>;
  remarkForCustomer?: InputMaybe<RemarkInputDTO>;
  remarkFromCustomer?: InputMaybe<RemarkInputDTO>;
  remarksForInternal?: InputMaybe<Array<RemarkInputDTO>>;
  /** ID of type "File" */
  signature?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Array<TimeBlockInputDTO>>;
};

export type SortDTO = {
  field: Scalars['String'];
  order: SortOrderDTO;
};

export enum SortOrderDTO {
  AscDTO = 'ASC',
  DescDTO = 'DESC'
}

export type StringArrayFilterDTO = {
  allContains?: InputMaybe<Scalars['String']>;
  allEndsWith?: InputMaybe<Scalars['String']>;
  allStartsWith?: InputMaybe<Scalars['String']>;
  containsAll?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  containsSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  someContains?: InputMaybe<Scalars['String']>;
  someEndsWith?: InputMaybe<Scalars['String']>;
  someStartsWith?: InputMaybe<Scalars['String']>;
};

export type StringFilterDTO = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SuccessDTO = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type TimeBlockDTO = {
  __typename?: 'TimeBlock';
  end?: Maybe<Scalars['DateTime']>;
  from: Scalars['String'];
  start: Scalars['DateTime'];
  to?: Maybe<Scalars['String']>;
};

export type TimeBlockInputDTO = {
  end?: InputMaybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type TravelRouteDTO = {
  __typename?: 'TravelRoute';
  employees: Array<UserDTO>;
  id: Scalars['String'];
  interventions: Array<InterventionDTO>;
  referenceTravelRouteId: Scalars['Int'];
  startingHour: Scalars['DateTime'];
  truckId: Scalars['String'];
  workingHour?: Maybe<TravelRouteWorkingHourDTO>;
};


export type TravelRouteInterventionsArgsDTO = {
  filter?: InputMaybe<InterventionFilterDTO>;
};

export type TravelRouteFilterDTO = {
  AND?: InputMaybe<Array<TravelRouteFilterDTO>>;
  OR?: InputMaybe<Array<TravelRouteFilterDTO>>;
  employee?: InputMaybe<BasicStringArrayFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  referenceTravelRouteId?: InputMaybe<IntFilterDTO>;
  startingHour?: InputMaybe<DateTimeFilterDTO>;
  truckId?: InputMaybe<StringFilterDTO>;
};

export type TravelRouteUpdateInputDTO = {
  workingHour?: InputMaybe<TravelRouteWorkingHourInputDTO>;
};

export type TravelRouteWorkingHourDTO = {
  __typename?: 'TravelRouteWorkingHour';
  end?: Maybe<Scalars['DateTime']>;
  endKm?: Maybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  startKm: Scalars['Int'];
};

export type TravelRouteWorkingHourInputDTO = {
  end?: InputMaybe<Scalars['DateTime']>;
  endKm?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  startKm: Scalars['Int'];
};

export type UserDTO = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['String'];
  userName: Scalars['String'];
};

export type UserFilterDTO = {
  AND?: InputMaybe<Array<UserFilterDTO>>;
  OR?: InputMaybe<Array<UserFilterDTO>>;
  email?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  userName?: InputMaybe<StringFilterDTO>;
};
