import { PageComponent } from "@aptus/frontend-core";
import { IconButton } from "@mui/material";
import { Link } from "components/link/link";
import { useRoutes, useInterventions } from "domains";
import i18next from "i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InterventionDetails } from "./components/interventionDetails";
import { useGetInterventionArticles } from "./models/getInterventionArticles";
import { StockTable, toStockRow } from "./components/stockTable";

export const MaterialListDetailPage: PageComponent = () => {
	const { routes, params } = useRoutes();
	const {
		intervention, isLoading, toggleArticleAvailability, toggleAllArticlesAvailability,
	} = useInterventions(useGetInterventionArticles({ variables: { id: params.id || "" } }));

	return (
		<>
			<IconButton to={routes.materials.index} component={Link} aria-label={i18next.t("goBack")}>
				<ArrowBackIcon />
			</IconButton>

			<InterventionDetails isLoading={isLoading} {...intervention} />
			<StockTable
				stock={intervention.articles.map(toStockRow)}
				isEverythingAvailable={intervention.isEverythingAvailable}
				isEverythingUnavailable={intervention.isEverythingUnavailable}
				toggle={(index) => toggleArticleAvailability({ id: intervention.id, index })}
				toggleAll={() => toggleAllArticlesAvailability(intervention.id)}
			/>
		</>
	);
};
