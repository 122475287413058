import { UIComponent } from "@aptus/frontend-core";
import {
	TextField, InputAdornment, debounce, IconButton, TextFieldProps,
} from "@mui/material";
import i18next from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";

interface Props {
	search: string;
	onSearch: (term: string) => void;
}

export const SearchBar: UIComponent<Props> = ({ search, onSearch }) => {
	const textFieldRef = useRef<TextFieldProps>(null);

	const clearField = () => {
		if (textFieldRef?.current?.value) {
			textFieldRef.current.value = "";
		}

		onSearch("");
	};

	return (
		<TextField
			inputRef={textFieldRef}
			fullWidth
			onChange={debounce((e) => onSearch(e.target.value), 400)}
			placeholder={i18next.t("searchBar.placeholder")}
			variant="standard"
			defaultValue={search}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{!search
							? <SearchIcon />
							: (
								<IconButton onClick={clearField}>
									<CloseIcon />
								</IconButton>
							)}
					</InputAdornment>
				),
				disableUnderline: true,
			}}
		/>
	);
};
