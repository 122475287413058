// Based on original implementation from Tofandel’s answer: https://stackoverflow.com/a/59787588/247441

/* Aggregates parts (mapped to slash-separated paths) into a nested object.
   E.g.:
     { /some/path: A, /foo: B, /some/other/path: C }

   gets turned into:
     { foo: B, some: { path: A, other: { path: C } } }
*/

export function unflattenObject<T extends Record<string, any>>(parts: Record<string, any>): T {
	const result: Record<string, any> = {};
	// Ideally should be typed as Partial<T>, but that causes problems down the line

	Object.keys(parts).forEach((partPath) => {
		if (Object.prototype.hasOwnProperty.call(parts, partPath)) {
			const keys = partPath.match(/^\/+[^\\/]*|[^\\/]*\/+$|(?:\/{2,}|[^\\/])+(?:\/+$)?/g);

			if (keys) {
				keys.reduce((acc, key, index) => {
					if (acc[key] !== null) {
						if (Number.isNaN(Number(keys[index + 1]))) {
							acc[key] = (keys.length - 1 === index ? parts[partPath] : {});
						} else {
							acc[key] = [];
						}
					}

					return acc;
				}, result);
			}
		}
	});

	return result as T;
}

/* Recursively decomposes an arbitrarily nested object into a flat record
   of slash-separated part paths mapped to respective structures.
   E.g.:
     { foo: B, some: { path: A, other: { path: C } } }

   gets turned into:
     { /some/path: A, /foo: B, /some/other/path: C }
*/
export function flattenObject(
	obj: Record<string, any>,
	_prefix: false | string = false,
	_result: Record<string, any> | null = null,
): Record<string, any> {
	const result: Record<string, any> = _result ?? {};

	// Preserve empty objects and arrays, they are lost otherwise
	if (_prefix !== false
      && typeof obj === "object"
      && obj !== null
      && Object.keys(obj).length === 0) {
		result[_prefix] = Array.isArray(obj) ? [] : {};
		return result;
	}

	const prefix = _prefix !== false
		? `${_prefix}.`
		: ".";

	Object.keys(obj).forEach((i) => {
		if (Object.prototype.hasOwnProperty.call(obj, i)) {
			if (typeof obj[i] === "object" && obj[i] !== null) {
				// Recursion on deeper objects
				flattenObject(obj[i], prefix + i, result);
			} else {
				result[prefix + i] = obj[i];
			}
		}
	});

	return result;
}
