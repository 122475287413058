import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Divider } from "@mui/material";
import { DEFAULT_PAGE_HEADER_MARGIN_TOP, PageHeader } from "components/pageHeader";
import { InterventionsPageHeaderLayoutProps, InterventionsPageHeaderProps, useInterventionsPageHeader } from "./useInterventionsPageHeader";

const Layout: UILayout<InterventionsPageHeaderLayoutProps> = ({
	isVisible, parent, parentHeight, children,
}) => (
	<PageHeader
		sx={{ pt: 2 }}
		ref={parent}
		marginTop={isVisible ? DEFAULT_PAGE_HEADER_MARGIN_TOP : `calc(-${parentHeight}px - 1rem)}`}
		hidden={!isVisible}
		divider={<Divider />}
	>
		{children}
	</PageHeader>
);

export const InterventionsPageHeader: UIComponent<InterventionsPageHeaderProps> = (props) => (
	<Layout {...props} {...useInterventionsPageHeader(props)} />
);
