import { UIComponent } from "@aptus/frontend-core";
import {
	createTheme, Stack, styled, ThemeProvider,
} from "@mui/material";
import { theme } from "theme";
import type { } from "@mui/lab/themeAugmentation";

interface Props {
	backgroundColor?: "success"
}

const pageFooterTheme = createTheme(theme, {
	components: {
		PageFooter: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.primary.main,
				},
				success: {
					backgroundColor: theme.palette.success.main,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: "white",
					color: theme.palette.primary.main,
					":hover": {
						backgroundColor: "white",
						color: theme.palette.primary.dark,
					},
				},
			},
		},
	},
} as any);

const PageFooterStyle = styled(Stack, {
	name: "PageFooter",
	shouldForwardProp: (prop) => prop !== "backgroundColor",
	overridesResolver: (props, styles) => [
		styles.root,
		props.backgroundColor === "success" && styles.success,
	],
})<Props>(({
	position: "sticky",
	marginLeft: "-1rem",
	marginRight: "-1rem",
	marginBottom: "-1rem",
	padding: "2rem",
	paddingTop: "1rem",
	bottom: 0,
	top: "100vh",
	zIndex: 1,
}));

export const PageFooter: UIComponent<Props> = (props) => (
	<ThemeProvider theme={pageFooterTheme}>
		<PageFooterStyle {...props} />
	</ThemeProvider>
);
