import { UseCases } from "@aptus/frontend-core";
import {
	Params, useParams, useNavigate, useLocation,
} from "react-router-dom";
import { objectHasValues } from "utils/objectHasValues";
import { useEvent } from "utils/useEvent";
import { Routes } from "./models/routes";

interface Result {
	routes: Routes;
	baseRoutes: Routes;
	params: Params;
	setQuery: (query: Record<string, string>) => void;
	getQuery: (parameter: string) => string;
	goToHome: () => void;
	goToMileage: () => void;
	goToLogin: () => void;
	goToLicensePlateScanner: () => void;
	goToInterventionInfo: (id: string) => void;
	goToInterventionCompleted: (id: string) => void;
	goToEndDay: () => void;
	goToInterventions: () => void;
}

export const useRoutesUseCases: UseCases<undefined, Result> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params: Result["params"] = useParams();

	const baseRoutes: Result["baseRoutes"] = {
		index: "/",
		login: "/aanmelden",
		mileage: "/kilometerstand",
		finishDay: "/kilometerstand-einde",
		licensePlate: {
			scanner: "/nummerplaat-scanner",
			form: "/nummerplaat-manueel",
		},
		interventions: {
			index: "/interventies",
			detail: "/interventies/:id",
			info: "/interventies/:id/info",
			completed: "/interventies/:id/complete",
			open: "/interventies",
			closed: "/interventies/afgewerkt",
		},
		materials: {
			index: "/materiaallijst",
			intervention: "/materiaallijst/:id",
		},
	};

	const routes: Result["routes"] = {
		index: baseRoutes.index,
		login: baseRoutes.login,
		mileage: baseRoutes.mileage,
		finishDay: baseRoutes.finishDay,
		licensePlate: baseRoutes.licensePlate,
		interventions: {
			index: `${baseRoutes.interventions.index}${location.search}`,
			detail: `${baseRoutes.interventions.detail}${location.search}`,
			info: `${baseRoutes.interventions.info}${location.search}`,
			completed: `${baseRoutes.interventions.completed}${location.search}`,
			open: `${baseRoutes.interventions.open}${location.search}`,
			closed: `${baseRoutes.interventions.closed}${location.search}`,
		},
		materials: {
			index: `${baseRoutes.materials.index}${location.search}`,
			intervention: `${baseRoutes.materials.intervention}${location.search}`,
		},
	};

	const setQuery: Result["setQuery"] = (_query) => {
		navigate({
			search: objectHasValues(_query)
				? `?${new URLSearchParams(_query).toString()}`
				: "",
		});
	};

	const getQuery: Result["getQuery"] = (parameter) => new URLSearchParams(location.search).get(parameter) || "";

	const goToHome: Result["goToHome"] = () => navigate(routes.index);
	const goToMileage: Result["goToMileage"] = () => navigate(routes.mileage);
	const goToLogin: Result["goToLogin"] = () => navigate(routes.login);
	const goToLicensePlateScanner: Result["goToLicensePlateScanner"] = () => navigate(routes.licensePlate.scanner);
	const goToInterventionInfo: Result["goToInterventionInfo"] = (id: string) => navigate(routes.interventions.info.replace(":id", id));
	const goToInterventionCompleted: Result["goToInterventionCompleted"] = (id: string) => navigate(routes.interventions.completed.replace(":id", id));
	const goToEndDay: Result["goToEndDay"] = () => navigate(routes.finishDay);
	const goToInterventions: Result["goToInterventions"] = () => navigate(routes.interventions.index);

	return {
		routes,
		baseRoutes,
		params,
		setQuery,
		getQuery,
		goToHome,
		goToMileage,
		goToLogin,
		goToLicensePlateScanner,
		goToInterventionInfo,
		goToInterventionCompleted,
		goToEndDay,
		goToInterventions,
	};
};

export const useRoutesEvents = (routes: ReturnType<UseCases<undefined, Result>>): void => {
	useEvent("loggedIn", routes.goToLicensePlateScanner);
	useEvent("loggedOut", routes.goToLogin);
	useEvent("licensePlateSaved", routes.goToMileage);
	useEvent("mileageAndStartDateSet", routes.goToHome);
	useEvent("serviceReportClosed", routes.goToHome);
	useEvent("interventionInfo", routes.goToInterventionInfo);
	useEvent("completeIntervention", routes.goToInterventionCompleted);
	useEvent("endDay", routes.goToEndDay);
	useEvent("interventions", routes.goToInterventions);
};
