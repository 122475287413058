/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNextInterventionVariablesDTO = Types.Exact<{
  startOfDay: Types.Scalars['DateTime'];
  endOfDay: Types.Scalars['DateTime'];
  installer: Types.Scalars['String'];
}>;


export type GetNextInterventionDTO = { __typename?: 'Query', interventions: Array<{ __typename?: 'Intervention', id: string, interventionDate: any, orderNumber: string, customerAddress: { __typename?: 'InterventionCustomerAddress', city?: string | null, postalcode?: string | null, street?: string | null }, travelRoute: { __typename?: 'TravelRoute', referenceTravelRouteId: number, startingHour: any, employees: Array<{ __typename?: 'User', id: string, userName: string }> } }> };


export const GetNextInterventionDocumentDTO = gql`
    query getNextIntervention($startOfDay: DateTime!, $endOfDay: DateTime!, $installer: String) {
  interventions(
    filter: {completed: {equals: false}, travelRoute: {startingHour: {gte: $startOfDay, lte: $endOfDay}, employee: { containsSome: [$installer] }}}
    sort: {field: "executionNumber", order: ASC}
  ) {
    id
    interventionDate
    orderNumber
    customerAddress {
      city
      postalcode
      street
    }
    travelRoute {
      referenceTravelRouteId
      startingHour
      employees {
        id
        userName
      }
    }
  }
}
    `;

/**
 * __useGetNextIntervention__
 *
 * To run a query within a React component, call `useGetNextIntervention` and pass it any options that fit your needs.
 * When your component renders, `useGetNextIntervention` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextIntervention({
 *   variables: {
 *      startOfDay: // value for 'startOfDay'
 *      endOfDay: // value for 'endOfDay'
 *   },
 * });
 */
export function useGetNextIntervention(baseOptions: Apollo.QueryHookOptions<GetNextInterventionDTO, GetNextInterventionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextInterventionDTO, GetNextInterventionVariablesDTO>(GetNextInterventionDocumentDTO, options);
      }
export function useGetNextInterventionLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetNextInterventionDTO, GetNextInterventionVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextInterventionDTO, GetNextInterventionVariablesDTO>(GetNextInterventionDocumentDTO, options);
        }
export type GetNextInterventionHookResult = ReturnType<typeof useGetNextIntervention>;
export type GetNextInterventionLazyQueryDTOHookResult = ReturnType<typeof useGetNextInterventionLazyQueryDTO>;
export type GetNextInterventionQueryResultDTO = Apollo.QueryResult<GetNextInterventionDTO, GetNextInterventionVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    