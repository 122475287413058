import dayjs from "dayjs";
import { Database } from "mocks/db";

export const populateInterventionCollection = (db: Database): void => {
	const travelRoute = db.travelRoute.create({
		employees: [
			db.user.create({ userName: "Jan Peeters" }),
			db.user.create({ userName: "Peter Janssens" }),
		],
		startingHour: dayjs().hour(7).minute(0).toISOString(),
	});

	const yesterDaysTravelRoute = db.travelRoute.create({
		employees: [
			db.user.create({ userName: "Jan Peeters" }),
			db.user.create({ userName: "Peter Janssens" }),
		],
		startingHour: dayjs().subtract(1, "day").hour(7).toISOString(),
	});

	const tomorrowsTravelRoute = db.travelRoute.create({
		employees: [
			db.user.create({ userName: "Jan Peeters" }),
			db.user.create({ userName: "Peter Janssens" }),
		],
		startingHour: dayjs().add(1, "day").toISOString(),
	});

	db.intervention.create({
		completed: false,
		interventionDate: dayjs().hour(7).minute(0).toISOString(),
		orderNumber: "123456",
		interventionCode: "303915 (13/01/21)",
		remarks: "Voorbeeldopmerking, Elisabeth A 0497 52 60 89",
		reference: "1813140 (12/01/21)",
		warehouseLocation: "P40",
		model: "Badkamermeubelen - No Limit",
		travelRoute,
		customerAddress: {
			name: "1 PLA Fam. Heyvaerts-Declerck",
			street: "Burgemeester P. Ceuterickstraat 53 A",
			postalcode: "9890",
			city: "Asper",
		},
		serviceReport: null,
		articles: [
			db.article.create({
				quantity: 1,
				reference: "50K2LP090",
				description: "OB F50/90 (PROFIEL)",
				remarks: "DEUR: Structure Beton Dark - KORPUS: Structure Beton Dark",
				colliNumber: 999100001,
			}),
			db.article.create({
				quantity: 2,
				reference: "50K2LP060",
				description: "OB F50/90 (PROFIEL) (600 BR/580 DP/590 HG)",
				remarks: "Trekkers: Greepprofiel Recht (M3) 30 (zwart mat)",
				colliNumber: 999100002,
			}),
			db.article.create({
				quantity: 3,
				reference: "50KOR30",
				description: "OO 50/30 (OPEN ONDERKAST) (300 BR/480 DP)",
				remarks: "Trekkers: Greepprofiel Recht (M3) 60 (zwart mat)",
				colliNumber: 999100003,
			}),
			db.article.create({
				quantity: 4,
				reference: "50SM180LT19",
				description: "W34 (BULSAN) 180 (ENK WASK. L. 1 KR.) TOP SOLID WIT (1805x515x15) MO6880B500 180,5CM 1L MAT (1805 LG/ 515 DP/ 15 DK)",
				remarks: "Trekkers: Greepprofiel Recht (M3) 90 (zwart mat)",
				colliNumber: 999100004,
			}),
		],
	});
	db.intervention.create({
		interventionDate: dayjs().hour(8).minute(0).toISOString(),
		completed: false,
		travelRoute,
		serviceReport: db.serviceReport.create({
			createdOn: dayjs().subtract(1, "day").toISOString(),
			updatedOn: dayjs().toISOString(),
			signature: db.file.create(),
			completed: false,
		}),
		articles: [
			db.article.create({
				isAvailable: true,
			}),
		],
	});
	db.intervention.create({
		completed: false,
		interventionDate: dayjs().hour(9).minute(0).toISOString(),
		travelRoute,
		serviceReport: db.serviceReport.create({
			createdOn: dayjs().subtract(1, "day").toISOString(),
			updatedOn: dayjs().toISOString(),
			executedWork: "Spiegels verwijderen en nieuwe spiegels op de deuren geplaatst",
			completed: false,
		}),
	});
	db.intervention.create({
		completed: true,
		serviceReport: db.serviceReport.create({
			completed: true,
			pictures: [
				db.file.create(),
				db.file.create(),
			],
		}),
		travelRoute,
		customerAddress: {
			name: "1 PLA Fam. Peeters-Declerck",
		},
	});
	db.intervention.create({ completed: true, serviceReport: null, travelRoute });
	db.intervention.create({ completed: true, serviceReport: null, travelRoute });

	db.intervention.create({
		interventionDate: dayjs().subtract(1, "day").hour(7).toISOString(),
		completed: false,
		serviceReport: null,
		travelRoute: yesterDaysTravelRoute,
	});

	db.intervention.create({
		interventionDate: dayjs().add(1, "day").hour(7).toISOString(),
		completed: false,
		serviceReport: null,
		travelRoute: tomorrowsTravelRoute,
	});

	db.intervention.create({
		interventionDate: new Date("14 april, 2022").toISOString(),
		completed: false,
		serviceReport: null,
		travelRoute: db.travelRoute.create({
			startingHour: new Date("14 april, 2022").toISOString(),
		}),
		customerAddress: {
			name: "2 PLA Peeters NV",
		},
	});
};
